import { Heading } from "cai-fusion";
import { symphonySVGs } from "../../configs/iconConfig.js"
import { NavLink, useNavigate, useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react"
import SymphonyAdminSettings from "../symphony/symphonyAdminSettings.jsx";
import { SettingContextWrapper, SettingsContext } from "../../contexts/SettingsContext.jsx";
import { useAuthentication } from "../../contexts/AuthContext.jsx";
import myCaiApiService from "../../services/mycai-api-v1";
import AdminCustomizationSettings from "./adminCustomizationSettings.jsx";

const SettingsOption = ({ id, name, svg }) => {

    const params = useParams();
    const isActive = params['*'] === id

    const OptionIcon = symphonySVGs[svg];

    return (
        <li className={`m-vertical-tabs__item ${isActive ? "m-vertical-tabs__item--active" : ""}`}>
            <NavLink to={`/settings/admin/${id}`} className="m-vertical-tabs__link">
                <div className="m-vertical-tabs__icon-wrapper">
                    <OptionIcon className="m-vertical-tabs__icon" />
                </div>
                <span className="m-vertical-tabs__label">{name}</span>
            </NavLink>
        </li>
    )
}


const AdminSettingsPage = () => {
    const { impersonating, graphData, apiAccessToken, graphAccessToken } =
        useAuthentication();

    const [ftePct, setFtePct] = useState(0.0);

    useEffect(() => {
        if (apiAccessToken) {
            myCaiApiService
                .GetChangeGroups(apiAccessToken, impersonating?.employeeId)
                .then((data) => {
                    console.log("adminSettingsPage Data");
                    console.log(data);
                })
                .catch((error) => console.error(error));
        }
    }, [apiAccessToken, impersonating]);


    const navigate = useNavigate();
    const params = useParams();
    const pageID = params['*']
    const { isSymphonyAdmin } = useContext(SettingsContext);

    const formatName = (colorId) => {
        // Split the string into an array of words, capitalize the first letter of each, then paste it back together
        return colorId.split('-')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' '); 
    }

    useEffect(() => {
        console.log("test");
        document.title = "System Settings - " + formatName(params['*']);
    }, [params])

    // If a user can view page with id "symphony", then "symphony" key should have true value.
    const [permissionMap, setPermissionMap] = useState({
        "symphony": true,
    })


    useEffect(() => {
        if (isSymphonyAdmin !== undefined) {
            var newPermissionMap = {
                ...permissionMap
            }
            newPermissionMap["symphony"] = isSymphonyAdmin;
            setPermissionMap(newPermissionMap);
        }
    }, [isSymphonyAdmin])

    // If this is just /settings, redirect to customization settings.
    // If user does not have permission to view said page, also redirect to customization settings.
    if (!pageID) {
        navigate("/settings/admin/customization");
    }

    // Creating a flag for showing the "App Settings" header.
    const appFlag = Object.values(permissionMap).some(value => value === true);

    return (
        <>
            <div className="o-page-section o-page-section l-mt-md l-pt-none">
                <div className="o-page-section__content-container o-page-section__content-container--fixed-width l-pt-none l-mt-neg-sm">
                    <div className="l-row l-pt-sm">
                        {/* Show all options as needed. */}
                        <div className="col-md-3">
                            <Heading size={4}>Global Settings</Heading>
                            <nav className="m-vertical-tabs">
                                <ul className="m-vertical-tabs__list">
                                    <SettingsOption id="customization" name="Customization" svg="IconSettings" />
                                    {/* <SettingsOption id="licenses" name="License Management" svg="IconCopy" /> */}
                                    <SettingsOption id="security" name="Privacy & Security Center" svg="IconSuccess" />
                                    {/* <SettingsOption id="variables" name="Variables Display" svg="IconCopy" /> */}
                                </ul>
                            </nav>
                            {appFlag && <Heading size={4}>App Settings</Heading>}
                            <nav className="m-vertical-tabs">
                                <ul className="m-vertical-tabs__list">
                                    {permissionMap["symphony"] && <SettingsOption id="symphony" name={process.env.REACT_APP_BRANDING_SYMPHONY_NAME ?? "Symphony"} svg="IconSettings" />}
                                </ul>
                            </nav>
                        </div>
                        {/* Load the correct settings menu based off of which page we're looking at */}
                        <div className="col-md-9">
                            {
                                // If user has no permission to view the page, show no access message
                                permissionMap[pageID] === false ? <NoAccess /> :
                                // Maps for each individual settings page.
                                pageID === 'customization' ? <AdminCustomizationSettings /> :
                                pageID === 'licenses' ? <LicensesSettings /> :
                                pageID === 'security' ? <SecuritySettings /> :
                                pageID === 'symphony' ? <SymphonyAdminSettings /> :
                                pageID === 'variables' ? <VariablesDisplay /> :
                                // Catch-22 for if there is no valid ID.
                                <>
                                    <Heading size={3}>No Settings Found</Heading>
                                    <p className="a-muted-text">No settings are found for this section.</p>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const NoAccess = () => {
    return (
        <>
            <Heading size={3}>No Access Granted</Heading>
            <p className="a-muted-text">You are not authorized to view these settings.</p>
        </>
    )
}

const LicensesSettings = () => {
    return (
        <>
            <Heading size={3}>Coming Soon!</Heading>
            <p>Settings that can help you manage your employees' licenses are coming soon!</p>
        </>
    )
}

const SecuritySettings = () => {
    return (
        <>
            <Heading size={3}>Coming Soon!</Heading>
            <p>Settings that can help you manage privacy and security are coming soon!</p>
        </>
    )
}

const VariablesDisplay = () => {
        return (
        <>
            <Heading size={3}>Variables</Heading>
            <p>Annualized Hours: 1880</p>
            <p>FTE Percentage: 1.0 (Default Value if there is no FTE Percent)</p>
            <Heading size={3}>Formulas</Heading>
            <p>Hourly to salary conversion:</p>
            <p>     ProposedBaseComp = (Current Base* (Percent Increase / 100)) + Current Base;</p>
        </>
    )
}
export default AdminSettingsPage;