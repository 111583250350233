import { marked } from 'marked';

export const defaultCopyToClipboard = (text) => {
    try {
        navigator.clipboard.writeText(text);
    } catch (err) {
        console.error('Failed to copy text to clipboard:', err);
    }
};

export const copyMarkdownToClipboard = (markdown) => {
    // Create an instance of Marked
    const htmlText = marked(markdown);
    try {
        // Create Blobs for both plain text and HTML
        const plainTextBlob = new Blob([markdown], { type: 'text/plain' });
        const htmlBlob = new Blob([htmlText], { type: 'text/html' });

        // Create a ClipboardItem containing both plain text and HTML
        const clipboardItem = new ClipboardItem({
            'text/plain': plainTextBlob,
            'text/html': htmlBlob,
        });

        navigator.clipboard.write([clipboardItem]);
  
    } catch (err) {
        console.error('Failed to copy: ', err);
    }
};