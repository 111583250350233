import { useState, useRef } from "react";
import { Heading, Button } from "cai-fusion";
import FileDisplay from "../components/symphonyFileDisplay";
import DnDFileUpload from "../../fusion/FileUpload/DragAndDrop";

import { useTemplate } from "../contexts/TemplateContext";

const WageStubCalculation = () => {
    const textWidthElement = useRef(null);
    const [files, setFiles] = useState([]);

    const { isRunning, executeTemplate } = useTemplate();

    // These two functions are what I needed to do to not immediately navigate when uploading a file.
    const deleteFiles = (fileInd) => {
        let filesCopy = [...files];
        filesCopy = [...filesCopy.slice(0, fileInd), ...filesCopy.slice(fileInd + 1)]
        setFiles([...filesCopy]);
    }

    const handleFileUpload = async (newFiles) => {
        setFiles([...files, ...newFiles]);
        console.log("[TEMPLATE] Added files. New file list:", ([...files], [...newFiles]));
    }

    // Function called when the "Send Message" button is hit.
    const handleSubmit = async () => {
        let prompt = `I have attached a wage stub document containing information that I need to extract specific details from. Please detirmine the following information from the document:\r\n
                      Employee Name,
                      Employer Name and Address (if employer name not given, provide message that 'No info found'),
                      Pay frequency,
                      Payment date,
                      Hours (if available, calculate, if not, ignore),
                      Gross income amount,
                      Pre-tax deduction type,
                      Pre-tax deduction amount.
                      \r\n\r\nFormat the extracted information and display these components in list form in order:\r\n
                      Employee name
                      Employer name and address of employer (if employer name not given, provide message that 'No info found')
                      Pay frequency
                      Payment date
                      Hours (if provided, if not, ignore)
                      Gross income amount
                      Pre-tax deduction type - One or Multiple of the following: Medical/Health Insurance (Premiums), Health Savings Accounts (HSA), Flexible Spending Accounts (FSA), Cafeteria Plan (Section 125), Short-term disability,`+
            `Long-term disability, Supplemental Insurance Coverage, Dental Insurance, Vision Insurance, Childcare Expenses, Medical Expenses, Life Insurance Premiums, Group-term Life Insurance, Commuter Benefits/Transportation Program Contribution,` +
            `Transit passes for public transportation, Parking expenses/permits, 401k and 403b Retirement Funds, Deferred Compensation/Contribution – Tax-Deferred Investments, Accidental Health Benefit or Accident Insurance, AFLAC, Meal Reimbursements` +
						`Educational Assistance (Educator Expense), Adoption Assistance, Employee Discounts
						,
                      \r\n\r\nThank you!`;
        executeTemplate(files, [prompt]);
    }

    return (
        <>
            <Heading size={3}>Let's get started!</Heading>
            <p className="a-muted-text">
                To begin the Wage Stub Calculation, please drag and drop or upload an image of the wage stub below. We’ll walk you through the rest!
            </p>
            <form action="" className="o-nl-form l-mt-sm">
                {/* <p className="o-nl-form__text">
                    1. Login to ASSIST WW to access application information.
                </p>
                <p className="o-nl-form__text">
                    2. Login to Outlook.
                </p>
                <p className="o-nl-form__text">
                    3. Login to OnBase.
                </p>
                <p className="o-nl-form__text">
                    4. Upload or drag image here.
                </p> */}
                <FileDisplay filesOverride={files} deleteOverride={deleteFiles} />
                <div style={{ marginBottom: "20px" }}>
                    <DnDFileUpload
                        notAllowedFileTypes={['.exe', '.bat', '.cmd', '.scr', '.com', '.ps1',
                            '.sh', '.vbs', '.msi', '.dll', '.apk', '.appx', '.crx',
                            '.deb', '.rpm', '.doc', '.mp3', '.wav', '.m4a', '.mp4', '.avi', '.mov', '.wmv', '.webm',
                            '.zip', '.gif', '.7z', '.zip', '.rar', 'lnk']}
                        overwriteHandleFiles={handleFileUpload}
                    />
                </div>
                <div className="m-button-row">
                    <Button onClick={handleSubmit} disabled={files.length === 0 || isRunning}>
                        Calculate
                    </Button>
                </div>
            </form>
            <span id="text-width" ref={textWidthElement}>
                Error Message or Unexpected Result And More Length
            </span>
        </>
    );
};

export default WageStubCalculation;