import ChatBubble from "./ChatBubble";
//could be made more dynamic i.e. dynamic component insertion
const LoadingChat = ({ iconElements, userProfileId }) => {
  return (
    <>
      {[...Array(4)].map((_, index) => (
        <ChatBubble
          key={index}
          isLoading
          chatEntryActions={[]}
          IconElement={iconElements["assistant"]}
          content={[{ type: "test" }]}
          role={index % 2 === 0 ? "user" : "assistant"}
          userProfileId={index % 2 === 0 ? userProfileId : ""}
          displayName={"Symphony Assistant"}
        />
      ))}
    </>
  );
};

export default LoadingChat;
