import { Modal } from "cai-fusion";
import { useContext, useEffect, useRef, useState } from "react";
import { supportConfig } from "../../configs/supportConfig";
import { SettingsContext } from "../../contexts/SettingsContext";
import { useFacts } from "./contexts/FactsContext";
import { useModels } from "./contexts/ModelsContext";
import { Progress } from "../fusion";

const searchIcon = require("../../images/search-icon-light.png");

const AddUserModal = ({ isHidden, onClose, availableLicenses }) => {
	const [usersToLicense, setUsersToLicense] = useState([]);
	const [searchValue, setSearchValue] = useState("");
	const searchTimeoutRef = useRef();
	const [users, setUsers] = useState([]);
	const { searchUsers, provisionLicenseToMany, pullUsers } =
		useContext(SettingsContext);

	const addUserToLicense = (user) => {
		if (usersToLicense.includes((x) => x.identifier === user.identifier)) {
			return;
		}

		const newUsersToLicense = [...usersToLicense];

		newUsersToLicense.push(user);
		setUsersToLicense(newUsersToLicense);
	};

	const handleSave = () => {
		provisionLicenseToMany(usersToLicense.map((u) => u.identifier));
	};

	const handleInputChange = (e) => {
		const currentValue = e.target.value;
		setSearchValue(e.target.value);
		if (e.target.value === "" || e.target.value === undefined) {
			setUsers([]);
		} else {
			if (searchTimeoutRef.current) {
				clearTimeout(searchTimeoutRef.current);
			}

			// Set a new timeout
			searchTimeoutRef.current = setTimeout(() => {
				// Use currentValue directly
				searchUsers(currentValue).then((resp) => {
					const newUsers = resp.filter((user) => {
						return (
							user.licenseStatus !== 0 &&
							!usersToLicense.some(
								(u) => u.identifier === user.identifier
							)
						);
					});
					setUsers(newUsers);
				});
			}, 300);
		}
	};

	return (
		<Modal
			isHidden={isHidden}
			onClose={onClose}
			className="o-modal--no-scroll"
			title="Add a New User"
			actions={
				<>
					<div className="o-modal__footer">
						<div className="m-button-row">
							<a
								className="m-button-row__button a-button a-button--outline"
								onClick={(e) => {
									e.preventDefault();
									onClose();
								}}
							>
								Cancel
							</a>
							<a
								className="m-button-row__button a-button"
								onClick={(e) => {
									e.preventDefault();
									handleSave();
									onClose();
								}}
							>
								Add Users
							</a>
						</div>
					</div>
				</>
			}
		>
			<div className="l-row">
				<div className="l-one-col">
					<p>
						Type below to search for users which you can provision a
						license to. You have{" "}
						{availableLicenses - usersToLicense.length} remaining
						licenses.
					</p>
					<form action="" className="o-form">
						<div className="m-autocomplete-input">
							<label
								className="visually-hidden"
								htmlFor="autocompleteInput"
							></label>
							<input
								value={
									availableLicenses - usersToLicense.length <=
									0
										? "You have no more licenses to allocate"
										: searchValue
								}
								class={`a-search-input__text-box ${
									availableLicenses - usersToLicense.length <=
									0
										? "a-search-input__text-box-disabled"
										: ""
								}`}
								type="text"
								placeholder="Search users..."
								aria-label="Search"
								id="search_field"
								name="search_field"
								onChange={(e) => {
									e.preventDefault();
									if (
										availableLicenses -
											usersToLicense.length <=
										0
									)
										return;
									handleInputChange(e);
								}}
							/>
							<div
								class="m-autocomplete-input__dropdown m-autocomplete-input__dropdown--shown"
								id="autocompleteDropdown"
							>
								{searchValue !== "" ? (
									users?.map((user) => {
										return (
											<button
												className="m-autocomplete-input__dropdown-item"
												type="button"
												onClick={(e) => {
													e.preventDefault();
													setSearchValue("");
													setUsers([]);
													addUserToLicense(user);
												}}
											>
												<div className="m-autocomplete-input__dropdown-item-title">
													{user?.givenName ??
														user?.identifier}{" "}
													{user?.familyName ?? ""}{" "}
													{user?.licenseStatus === 0
														? "(Licensed)"
														: ""}
												</div>
												<div className="m-autocomplete-input__dropdown-item-meta">
													{user.jobTitle ??
														user?.identifier}
												</div>
											</button>
										);
									})
								) : (
									<></>
								)}
							</div>
							<div
								className="m-autocomplete-input__dropdown"
								id="autocompleteDropdown"
							></div>
						</div>
					</form>
					<h4 className="a-heading a-heading--heading-5 l-mt-xs">
						Users to add
					</h4>
					<ul className="m-chip-group">
						<li className="m-chip-group__chip">
							{usersToLicense.map((user) => {
								return (
									<div className="a-chip">
										<p className="a-chip__label">
											<span className="a-chip__category">
												{user?.familyName ??
													user?.identifier}
												, {user?.givenName ?? ""}
											</span>
										</p>
										<button
											className="a-chip__remove-btn"
											onClick={(e) => {
												e.preventDefault();
												setUsersToLicense(
													usersToLicense.filter(
														(u) =>
															u.identifier !==
															user.identifier
													)
												);
											}}
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 307.2 307.2"
												data-inject-url="https://synthesisstorage.blob.core.windows.net/dist/assets/toolkit/images/cancel.svg"
												className="a-chip__icon"
											>
												<path d="M307.2 24.136L283.064 0 153.6 129.469 24.136 0 0 24.136 129.464 153.6 0 283.064 24.136 307.2 153.6 177.736 283.064 307.2l24.136-24.136L177.736 153.6z"></path>
											</svg>
										</button>
									</div>
								);
							})}
						</li>
					</ul>
				</div>
			</div>
		</Modal>
	);
};

const SymphonyAdminSettings = (props) => {
	const {
		licensedUsers,
		remainingLicenses,
		deprovisionLicense,
		unusableUsers,
		isLicensedEnvironment,
		tryFixUser,
		isLoadingUnusables,
	} = useContext(SettingsContext);

	const [filterValue, setFilterValue] = useState("");
	const [mode, setMode] = useState("all");
	const [currentPage, setCurrentPage] = useState(1);
	const [pageList, setPageList] = useState([]);
	const [perPage, setPerPage] = useState(10);
	const [displayedUsers, setDisplayedUsers] = useState([]);
	const [addUsersIsHidden, setAddUsersIsHidden] = useState(true);

	const handleFilterChange = (e) => {
		const newVal = e.target.value.toLowerCase();

		setFilterValue(newVal);
		setCurrentPage(pageList[0]);

		if (newVal === "") {
			setDisplayedUsers(licensedUsers);
		} else {
			setDisplayedUsers(
				licensedUsers.filter((u) =>
					u.searchFields.some((f) => f.includes(newVal))
				)
			);
		}
	};

	const getInTouch = (e) => {
		const bugReportLink = `mailto:${supportConfig.licensingContact}?subject=Symphony%20Licensing`;
		window.location.href = bugReportLink;
		e.preventDefault();
	};

	useEffect(() => {
		var newPageList = [];

		const numPages = Math.ceil(displayedUsers.length / perPage);

		for (var i = 1; i < numPages && i <= 3; i++) {
			newPageList.push(i);
			newPageList.push(numPages - i + 1);
		}

		if (currentPage !== "Unusable Users") {
			newPageList.push(currentPage);

			if (currentPage < numPages) {
				newPageList.push(currentPage + 1);
			}

			if (currentPage > 1) {
				newPageList.push(currentPage - 1);
			}
		}
		if (newPageList.length === 0) {
			newPageList.push(1);
		}

		newPageList = newPageList.sort(function (a, b) {
			return a - b;
		});

		var newPageList2 = [];

		for (var i = 0; i < newPageList.length; i++) {
			if (!newPageList2.includes(newPageList[i])) {
				newPageList2.push(newPageList[i]);
			}
		}

		newPageList = newPageList2;

		for (var i = 1; i < newPageList.length; i++) {
			if (newPageList[i - 1] < newPageList[i] - 1) {
				newPageList = [
					...newPageList.slice(0, i),
					"...",
					...newPageList.slice(i),
				];
			}
		}

		setPageList(newPageList);
	}, [displayedUsers?.length, currentPage, perPage]);

	useEffect(() => {
		if (mode === "all") {
			setDisplayedUsers(licensedUsers);
		} else {
			setDisplayedUsers(unusableUsers);
		}
	}, [licensedUsers, unusableUsers]);

	const [activeTab, setActiveTab] = useState(0);

	const handleTabClick = (index) => {
		setActiveTab(index);
	};

	const licenseManagementControl = (
		<>
			<div className="row l-mt-sm">
				<div className="col-md-6">
					<h5 className="a-heading a-heading--heading-3">
						License Management
					</h5>
					<p className="a-muted-text">
						Manage which users have access to Symphony.
					</p>
				</div>
				<div className="col-md-6">
					<div className="m-button-row">
						<button
							className="a-button"
							data-modal-target="addUserModal"
							onClick={(e) => {
								e.preventDefault();
								setAddUsersIsHidden(false);
							}}
						>
							Add User
						</button>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<form className="a-search-input l-mb-xs">
						<input
							value={filterValue}
							className="a-search-input__text-box"
							type="text"
							placeholder="Search users..."
							aria-label="Search"
							id="search_field"
							name="search_field"
							onChange={(e) => {
								e.preventDefault();
								handleFilterChange(e);
							}}
						/>
						<button
							className="a-search-input__button"
							type="submit"
						>
							<img
								className="a-search-input__img"
								src={searchIcon}
								alt="search button icon"
							/>
						</button>
					</form>
					{remainingLicenses === 0 ? (
						<div className="m-callout m-callout--shaded l-mb-sm">
							<svg
								className="m-callout__icon"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 512 358.38"
							>
								<g id="Layer_2">
									<g id="Engine_Warning">
										<path d="M38.4,179.19c-.01-47.52,15.54-93.74,44.29-131.58,4.24-5.33,3.52-13.06-1.64-17.51l-9.73-8.35c-5.39-4.66-13.54-4.06-18.2,1.33-.11,.13-.23,.27-.34,.41-70.38,91.86-70.38,219.52,0,311.38,4.41,5.56,12.49,6.5,18.05,2.1,.15-.12,.29-.23,.43-.36l9.78-8.35c5.12-4.45,5.84-12.14,1.64-17.46-28.74-37.86-44.29-84.09-44.28-131.63ZM255.99,0C157.03,0,76.8,80.23,76.8,179.19s80.23,179.19,179.19,179.19,179.19-80.23,179.19-179.19S354.95,0,255.99,0h0Zm0,281.58c-14.14,0-25.6-11.46-25.6-25.6s11.46-25.6,25.6-25.6,25.6,11.46,25.6,25.6-11.46,25.6-25.6,25.6Zm20.48-88.31c-.66,6.58-6.23,11.58-12.85,11.52h-15.36c-6.58,0-12.09-4.97-12.75-11.52l-10.24-102.39c-.68-7.06,4.5-13.34,11.56-14.02,.43-.04,.86-.06,1.29-.06h35.84c7.1,.02,12.83,5.8,12.81,12.89,0,.4-.02,.79-.06,1.18l-10.24,102.39ZM458.17,22.17c-4.45-5.57-12.57-6.47-18.13-2.02-.14,.11-.27,.22-.4,.33l-9.57,8.5c-5.11,4.5-5.76,12.22-1.48,17.51,60.21,78.24,60.21,187.21,0,265.46-4.28,5.29-3.62,13.01,1.48,17.51l9.57,8.45c5.36,4.7,13.51,4.16,18.21-1.2,.11-.13,.22-.26,.33-.39,71.78-92.39,71.78-221.7,0-314.09v-.05Z"></path>
									</g>
								</g>
							</svg>
							<div className="m-callout__content">
								<h4 className="m-callout__title">
									You've allocated all of your Symphony
									licenses.
								</h4>
								<p className="m-callout__text a-muted-text">
									Please contact your CAI representative to
									add additional users.
								</p>
							</div>
							<div className="m-callout__action">
								<div className="m-button-row">
									<a
										className="a-button"
										onClick={() => getInTouch()}
									>
										Get in Touch
									</a>
								</div>
							</div>
						</div>
					) : (
						<></>
					)}
					<div className="m-tab-row l-mt-sm l-mb-sm">
						<button
							class={`m-tab-row__tab ${
								mode === "all" ? "m-tab-row__tab--active" : ""
							}`}
							onClick={(e) => {
								e.preventDefault();
								setMode("all");
								setDisplayedUsers(licensedUsers);
							}}
						>
							<span className="m-tab-row__tab-title">
								All Users{" "}
								<span className="a-badge">
									{licensedUsers.length}
								</span>
							</span>
							<span className="m-tab-row__help">
								Users that currently have access to Symphony.
							</span>
						</button>
						<button
							class={`m-tab-row__tab ${
								mode === "inactive"
									? "m-tab-row__tab--active"
									: ""
							}`}
							onClick={(e) => {
								e.preventDefault();
								if (unusableUsers.length > 0) {
									setMode("inactive");
									setDisplayedUsers(unusableUsers);
								}
							}}
						>
							<span className="m-tab-row__tab-title">
								Users Needing Review{" "}
								<span className="a-badge">
									{isLoadingUnusables
										? "..."
										: unusableUsers.length}
								</span>
							</span>
							<span className="m-tab-row__help">
								User accounts that need to be reviewed.
							</span>
						</button>
					</div>
					<div className="o-block">
						<table
							id="sharedUsers"
							className="o-treegrid o-treegrid--compact l-mr-sm"
						>
							<colgroup className="o-treegrid__colgroup">
								<col
									className="o-treegrid__col o-treegrid__col--x-small	"
									span="1"
								/>
								<col
									className="o-treegrid__col o-treegrid__col--small"
									span="1"
								/>
								<col
									className="o-treegrid__col o-treegrid__col--48"
									span="1"
								/>
							</colgroup>
							<tbody>
								{displayedUsers
									.slice(
										(currentPage - 1) * perPage,
										currentPage * perPage
									)
									.map((user) => {
										const status = isLoadingUnusables
											? "Loading..."
											: unusableUsers?.find(
													(u) =>
														u.identifier ===
														user.identifier
											  )?.invalidReason ?? `Active`;
										return (
											<tr className="o-treegrid__row o-treegrid__row--shown">
												<td className="o-treegrid__cell">
													<span className="a-subtle-text">
														<strong>
															{user.givenName ??
																user.identifier}{" "}
															{user.familyName}
														</strong>
													</span>
												</td>
												<td className="o-treegrid__cell">
													<span
														className="a-subtle-text"
														onClick={(e) => {
															e.preventDefault();
															if (
																status ===
																"User doesn't have access to the app registration"
															)
																tryFixUser(
																	user
																);
														}}
													>
														{status}
													</span>
												</td>
												<td className="o-treegrid__cell">
													{isLicensedEnvironment ? (
														<button
															className="a-button a-button--outline a-button--small  a-button--full-width"
															onClick={(e) => {
																e.preventDefault();
																deprovisionLicense(
																	user.identifier
																);
															}}
														>
															Remove
														</button>
													) : (
														<></>
													)}
												</td>
											</tr>
										);
									})}
							</tbody>
						</table>
						<div className="m-pagination-row l-mt-xs">
							<span
								class={`m-pagination-row__page m-pagination-row__page--${
									currentPage === pageList[0]
										? "disabled"
										: "unselected"
								}`}
								onClick={(e) => {
									e.preventDefault();
									var newInd =
										pageList
											.filter((x) => x !== "...")
											.indexOf(currentPage) - 1;
									setCurrentPage(
										newInd >= 0
											? pageList.filter(
													(x) => x !== "..."
											  )[newInd]
											: currentPage
									);
								}}
							>
								❮
							</span>
							{pageList.map((name) => {
								return (
									<>
										{name !== "..." ? (
											<a
												class={`m-pagination-row__page m-pagination-row__page--${
													name === currentPage
														? "current"
														: "unselected"
												}`}
												onClick={(e) => {
													e.preventDefault();
													setCurrentPage(name);
												}}
											>
												{name}
											</a>
										) : (
											<a
												class={`m-pagination-row__page m-pagination-row__page--disabled`}
												onClick={(e) => {
													e.preventDefault();
												}}
											>
												{name}
											</a>
										)}
									</>
								);
							})}
							<a
								class={`m-pagination-row__page m-pagination-row__page--${
									currentPage ===
									pageList[pageList.length - 1]
										? "disabled"
										: "unselected"
								}`}
								onClick={(e) => {
									e.preventDefault();
									var newInd =
										pageList
											.filter((x) => x !== "...")
											.indexOf(currentPage) + 1;
									setCurrentPage(
										pageList.filter((x) => x !== "...")
											.length > newInd
											? pageList.filter(
													(x) => x !== "..."
											  )[newInd]
											: currentPage
									);
								}}
							>
								❯
							</a>
						</div>
					</div>
				</div>
			</div>
		</>
	);

	const {
		facts,
		loadingFacts,
		error: factsError,
		createFact,
		updateFact,
		deleteFact,
	} = useFacts();

	const [newFactValue, setNewFactValue] = useState("");
	const [editingFact, setEditingFact] = useState({}); // Tracks editing state

	const handleCreateFact = () => {
		createFact({ value: newFactValue });
		setNewFactValue(""); // Clear input after creation
	};

	const handleEditFact = (factId, value) => {
		setEditingFact({ ...editingFact, [factId]: value });
	};

	const handleUpdateFact = (factId) => {
		if (editingFact[factId]) {
			updateFact(factId, { value: editingFact[factId] });
			setEditingFact({ ...editingFact, [factId]: undefined });
		}
	};

	const handleCancelEdit = (factId) => {
		setEditingFact({ ...editingFact, [factId]: undefined });
	};

	const factsControl = (
		<>
			<div className="row l-mt-sm">
				<div className="col-md-12">
					<h5 className="a-heading a-heading--heading-3">
						Symphony Facts
					</h5>
					<p className="a-muted-text">
						Manage default facts that Symphony has access to.
					</p>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					{loadingFacts && <Progress />}
					{!loadingFacts && (
						<table className="o-treegrid o-treegrid--compact l-mr-sm">
							<colgroup className="o-treegrid__colgroup">
								<col
									className="o-treegrid__col o-treegrid__col--fill"
									span="1"
								/>
								<col
									className="o-treegrid__col o-treegrid__col--x-small"
									span="1"
								/>
								<col
									className="o-treegrid__col o-treegrid__col--x-small"
									span="1"
								/>
							</colgroup>
							<tbody>
								<tr className="o-treegrid__row o-treegrid__row--shown">
									<td className="o-treegrid__cell">
										<textarea
											className="a-textarea-input"
											type="text"
											rows="2"
											value={newFactValue}
											onChange={(e) =>
												setNewFactValue(e.target.value)
											}
										/>
									</td>
									<td className="o-treegrid__cell">
										<button
											className="a-button a-button--outline a-button--small"
											onClick={handleCreateFact}
										>
											Add
										</button>
									</td>
									<td className="o-treegrid__cell"></td>
								</tr>
								{facts.map((fact) => (
									<tr
										key={fact.id}
										className="o-treegrid__row o-treegrid__row--shown"
									>
										<td className="o-treegrid__cell">
											{editingFact[fact.id] !==
											undefined ? (
												<textarea
													className="a-textarea-input"
													rows="3"
													value={editingFact[fact.id]}
													onChange={(e) =>
														handleEditFact(
															fact.id,
															e.target.value
														)
													}
												/>
											) : (
												<span
													className="a-subtle-text"
													onClick={() =>
														handleEditFact(
															fact.id,
															fact.value
														)
													}
												>
													<strong>
														{fact.value}
													</strong>
												</span>
											)}
										</td>
										<td className="o-treegrid__cell">
											{editingFact[fact.id] !==
											undefined ? (
												<>
													<button
														className="a-button a-button--outline a-button--small"
														onClick={() =>
															handleUpdateFact(
																fact.id
															)
														}
													>
														Submit
													</button>
												</>
											) : (
												<button
													className="a-button a-button--outline a-button--small"
													onClick={() =>
														deleteFact(fact.id)
													}
												>
													Remove
												</button>
											)}
										</td>
										<td className="o-treegrid__cell">
											{editingFact[fact.id] !==
												undefined && (
												<button
													className="a-button a-button--outline a-button--small"
													onClick={() =>
														handleCancelEdit(
															fact.id
														)
													}
												>
													Cancel
												</button>
											)}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					)}
				</div>
			</div>
		</>
	);

	const {
		models,
		loadingModels,
		error: modelsError,
		createModel,
		updateModel,
		deleteModel,
	} = useModels();
	const freshModel = {
		// Initialize with all necessary fields for creating a new model
		engine: "",
		deploymentName: "",
		modelName: "",
		displayName: "",
		description: "",
		tokenLimit: 0,
		outputTokenLimit: 0,
		modelApiEndpoint: "",
		modelApiKey: "",
		modelApiVersion: "",
		modelSource: "",
		deploymentLocation: "",
		inputCostPerUnit: 0.0,
		outputCostPerUnit: 0.0,
		isEnabled: false,
		index: 0,
		isTextModel: false,
		isImageModel: false,
		isVisionModel: false,
		isDefaultTextModel: false,
		isDefaultImageModel: false,
		isDefaultVisionModel: false,
		encodingEngine: "",
	};

	const [newModel, setNewModel] = useState(freshModel);
	const [editState, setEditState] = useState({}); // Tracks editing state for existing models

	const handleCreateModel = () => {
		createModel(newModel);
		// setNewModel(freshModel); // Reset input fields after creation
	};

	const startEditing = (model) => {
		setEditState({ ...editState, [model.id]: { ...model } });
	};

	const cancelEditing = (id) => {
		setEditState({ ...editState, [id]: undefined });
	};

	const handleUpdateModel = (id) => {
		if (editState[id]) {
			updateModel(id, editState[id]);
			cancelEditing(id); // Clear edit state after updating
		}
	};

	const handleInputChange = (e) => {
		const { name, value, type, checked } = e.target;
		setNewModel({
			...newModel,
			[name]: type === "checkbox" ? checked : value,
		});
	};

	const handleChangeEditState = (id, e) => {
		const { name, value, type, checked } = e.target;
		setEditState({
			...editState,
			[id]: {
				...editState[id],
				[name]: type === "checkbox" ? checked : value,
			},
		});
	};

	const modelsControl = (
		<>
			<div className="row l-mt-sm">
				<div className="col-md-12">
					<h5 className="a-heading a-heading--heading-3">
						Symphony Models
					</h5>
					<p className="a-muted-text">
						Manage the models that Symphony can use to generate
						content.
					</p>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					{loadingModels && <Progress />}
					{!loadingModels && (
						<>
							<table className="o-treegrid o-treegrid--compact l-mr-sm">
								<colgroup className="o-treegrid__colgroup">
									<col
										className="o-treegrid__col o-treegrid__col--fill"
										span="1"
									/>
									<col
										className="o-treegrid__col o-treegrid__col--x-small"
										span="1"
									/>
									<col
										className="o-treegrid__col o-treegrid__col--x-small"
										span="1"
									/>
								</colgroup>
								<tbody>
									{/* Rows for existing models */}
									{models
										.filter((model) => model.isEnabled)
										.map((model) => (
											<tr
												key={model.id}
												className="o-treegrid__row o-treegrid__row--shown"
											>
												<td className="o-treegrid__cell">
													{/* ... */}
													{/* Input fields for editable properties of existing models */}
													{editState[model.id] ? (
														<>
															<input
																className="a-text-input"
																type="text"
																name="displayName"
																value={
																	editState[
																		model.id
																	]
																		.displayName
																}
																onChange={(e) =>
																	handleChangeEditState(
																		model.id,
																		e
																	)
																}
															/>
															<div className="m-form-group">
																<label
																	htmlFor={`isDefaultTextModel-${model.id}`}
																	className="a-label"
																>
																	Default Text
																	Model
																</label>
																<input
																	id={`isDefaultTextModel-${model.id}`}
																	type="checkbox"
																	name="isDefaultTextModel"
																	checked={
																		editState[
																			model
																				.id
																		]
																			.isDefaultTextModel
																	}
																	onChange={(
																		e
																	) =>
																		handleChangeEditState(
																			model.id,
																			e
																		)
																	}
																	className="a-checkbox"
																/>
															</div>
															<div className="m-form-group">
																<label
																	htmlFor={`isDefaultImageModel-${model.id}`}
																	className="a-label"
																>
																	Default
																	Image Model
																</label>
																<input
																	id={`isDefaultImageModel-${model.id}`}
																	type="checkbox"
																	name="isDefaultImageModel"
																	checked={
																		editState[
																			model
																				.id
																		]
																			.isDefaultImageModel
																	}
																	onChange={(
																		e
																	) =>
																		handleChangeEditState(
																			model.id,
																			e
																		)
																	}
																	className="a-checkbox"
																/>
															</div>
															<div className="m-form-group">
																<label
																	htmlFor={`isDefaultVisionModel-${model.id}`}
																	className="a-label"
																>
																	Default
																	Vision Model
																</label>
																<input
																	id={`isDefaultVisionModel-${model.id}`}
																	type="checkbox"
																	name="isDefaultVisionModel"
																	checked={
																		editState[
																			model
																				.id
																		]
																			.isDefaultVisionModel
																	}
																	onChange={(
																		e
																	) =>
																		handleChangeEditState(
																			model.id,
																			e
																		)
																	}
																	className="a-checkbox"
																/>
															</div>
														</>
													) : (
														<span>
															{model.displayName}
														</span>
													)}
												</td>
												<td className="o-treegrid__cell">
													{editState[model.id] ? (
														<>
															<button
																className="a-button a-button--outline a-button--small"
																onClick={() =>
																	handleUpdateModel(
																		model.id
																	)
																}
															>
																Save
															</button>
														</>
													) : (
														<>
															<button
																className="a-button a-button--outline a-button--small"
																onClick={() =>
																	startEditing(
																		model
																	)
																}
															>
																Edit
															</button>
														</>
													)}
												</td>
												<td className="o-treegrid__cell">
													{editState[model.id] ? (
														<>
															<button
																className="a-button a-button--outline a-button--small"
																onClick={() =>
																	cancelEditing(
																		model.id
																	)
																}
															>
																Cancel
															</button>
														</>
													) : (
														<>
															<button
																className="a-button a-button--outline a-button--small"
																onClick={() =>
																	deleteModel(
																		model.id
																	)
																}
															>
																Remove
															</button>
														</>
													)}
												</td>
											</tr>
										))}
								</tbody>
							</table>
							<div className="o-block">
								<div className="m-form-group">
									<label htmlFor="engine" className="a-label">
										Engine
									</label>
									<input
										id="engine"
										type="text"
										name="engine"
										value={newModel.engine}
										onChange={handleInputChange}
										className="a-text-input"
										placeholder="Unique engine identifier"
									/>
								</div>

								<div className="m-form-group">
									<label
										htmlFor="deploymentName"
										className="a-label"
									>
										Deployment Name
									</label>
									<input
										id="deploymentName"
										type="text"
										name="deploymentName"
										value={newModel.deploymentName}
										onChange={handleInputChange}
										className="a-text-input"
										placeholder="Deployment name for API calls"
									/>
								</div>

								<div className="m-form-group">
									<label
										htmlFor="modelName"
										className="a-label"
									>
										Model Name
									</label>
									<input
										id="modelName"
										type="text"
										name="modelName"
										value={newModel.modelName}
										onChange={handleInputChange}
										className="a-text-input"
										placeholder="Name used for encoding parameters"
									/>
								</div>

								<div className="m-form-group">
									<label
										htmlFor="displayName"
										className="a-label"
									>
										Display Name
									</label>
									<input
										id="displayName"
										type="text"
										name="displayName"
										value={newModel.displayName}
										onChange={handleInputChange}
										className="a-text-input"
										placeholder="User-facing name of the model"
									/>
								</div>

								<div className="m-form-group">
									<label
										htmlFor="description"
										className="a-label"
									>
										Description
									</label>
									<textarea
										id="description"
										name="description"
										value={newModel.description}
										onChange={handleInputChange}
										className="a-textarea-input"
										placeholder="Description of the model"
										rows="3"
									/>
								</div>

								<div className="m-form-group">
									<label
										htmlFor="tokenLimit"
										className="a-label"
									>
										Token Limit
									</label>
									<input
										id="tokenLimit"
										type="number"
										name="tokenLimit"
										value={newModel.tokenLimit}
										onChange={handleInputChange}
										className="a-text-input"
									/>
								</div>

								<div className="m-form-group">
									<label
										htmlFor="outputTokenLimit"
										className="a-label"
									>
										Output Token Limit
									</label>
									<input
										id="outputTokenLimit"
										type="number"
										name="outputTokenLimit"
										value={newModel.outputTokenLimit}
										onChange={handleInputChange}
										className="a-text-input"
									/>
								</div>

								<div className="m-form-group">
									<label
										htmlFor="modelApiEndpoint"
										className="a-label"
									>
										Model API Endpoint
									</label>
									<input
										id="modelApiEndpoint"
										type="text"
										name="modelApiEndpoint"
										value={newModel.modelApiEndpoint}
										onChange={handleInputChange}
										className="a-text-input"
										placeholder="URL endpoint for the model"
									/>
								</div>

								<div className="m-form-group">
									<label
										htmlFor="modelApiKey"
										className="a-label"
									>
										Model API Key
									</label>
									<input
										id="modelApiKey"
										type="text"
										name="modelApiKey"
										value={newModel.modelApiKey}
										onChange={handleInputChange}
										className="a-text-input"
										placeholder="Key used for accessing the model"
									/>
								</div>

								<div className="m-form-group">
									<label
										htmlFor="modelApiVersion"
										className="a-label"
									>
										Model API Version
									</label>
									<input
										id="modelApiVersion"
										type="text"
										name="modelApiVersion"
										value={newModel.modelApiVersion}
										onChange={handleInputChange}
										className="a-text-input"
										placeholder="API version for the model"
									/>
								</div>

								<div className="m-form-group">
									<label
										htmlFor="modelSource"
										className="a-label"
									>
										Model Source
									</label>
									<input
										id="modelSource"
										type="text"
										name="modelSource"
										value={newModel.modelSource}
										onChange={handleInputChange}
										className="a-text-input"
										placeholder="Source of the model"
									/>
								</div>

								<div className="m-form-group">
									<label
										htmlFor="deploymentLocation"
										className="a-label"
									>
										Deployment Location
									</label>
									<input
										id="deploymentLocation"
										type="text"
										name="deploymentLocation"
										value={newModel.deploymentLocation}
										onChange={handleInputChange}
										className="a-text-input"
										placeholder="Deployment location"
									/>
								</div>

								<div className="m-form-group">
									<label
										htmlFor="inputCostPerUnit"
										className="a-label"
									>
										Input Cost Per Unit
									</label>
									<input
										id="inputCostPerUnit"
										type="number"
										name="inputCostPerUnit"
										value={newModel.inputCostPerUnit}
										onChange={handleInputChange}
										className="a-text-input"
										placeholder="0.0"
										step="0.000001"
									/>
								</div>

								<div className="m-form-group">
									<label
										htmlFor="outputCostPerUnit"
										className="a-label"
									>
										Output Cost Per Unit
									</label>
									<input
										id="outputCostPerUnit"
										type="number"
										name="outputCostPerUnit"
										value={newModel.outputCostPerUnit}
										onChange={handleInputChange}
										className="a-text-input"
										placeholder="0.0"
										step="0.00001"
									/>
								</div>

								{/* Checkboxes for boolean fields */}
								<div className="m-form-group">
									<label
										htmlFor="isEnabled"
										className="a-label"
									>
										Is Enabled
									</label>
									<input
										id="isEnabled"
										type="checkbox"
										name="isEnabled"
										checked={newModel.isEnabled}
										onChange={handleInputChange}
										className="a-checkbox"
									/>
								</div>

								<div className="m-form-group">
									<label
										htmlFor="isTextModel"
										className="a-label"
									>
										Is Text Model
									</label>
									<input
										id="isTextModel"
										type="checkbox"
										name="isTextModel"
										checked={newModel.isTextModel}
										onChange={handleInputChange}
										className="a-checkbox"
									/>
								</div>

								<div className="m-form-group">
									<label
										htmlFor="isImageModel"
										className="a-label"
									>
										Is Image Model
									</label>
									<input
										id="isImageModel"
										type="checkbox"
										name="isImageModel"
										checked={newModel.isImageModel}
										onChange={handleInputChange}
										className="a-checkbox"
									/>
								</div>

								<div className="m-form-group">
									<label
										htmlFor="isVisionModel"
										className="a-label"
									>
										Is Vision Model
									</label>
									<input
										id="isVisionModel"
										type="checkbox"
										name="isVisionModel"
										checked={newModel.isVisionModel}
										onChange={handleInputChange}
										className="a-checkbox"
									/>
								</div>

								<div className="m-form-group">
									<label
										htmlFor="isDefaultTextModel"
										className="a-label"
									>
										Is Default Text Model
									</label>
									<input
										id="isDefaultTextModel"
										type="checkbox"
										name="isDefaultTextModel"
										checked={newModel.isDefaultTextModel}
										onChange={handleInputChange}
										className="a-checkbox"
									/>
								</div>

								<div className="m-form-group">
									<label
										htmlFor="isDefaultImageModel"
										className="a-label"
									>
										Is Default Image Model
									</label>
									<input
										id="isDefaultImageModel"
										type="checkbox"
										name="isDefaultImageModel"
										checked={newModel.isDefaultImageModel}
										onChange={handleInputChange}
										className="a-checkbox"
									/>
								</div>

								<div className="m-form-group">
									<label
										htmlFor="isDefaultVisionModel"
										className="a-label"
									>
										Is Default Vision Model
									</label>
									<input
										id="isDefaultVisionModel"
										type="checkbox"
										name="isDefaultVisionModel"
										checked={newModel.isDefaultVisionModel}
										onChange={handleInputChange}
										className="a-checkbox"
									/>
								</div>

								<div className="m-form-group">
									<label
										htmlFor="encodingEngine"
										className="a-label"
									>
										Encoding Engine
									</label>
									<input
										id="encodingEngine"
										type="text"
										name="encodingEngine"
										value={newModel.encodingEngine}
										onChange={handleInputChange}
										className="a-text-input"
										placeholder="Tokenizer encoding name"
									/>
								</div>

								<div className="m-form-group">
									<button
										className="a-button"
										onClick={handleCreateModel}
									>
										Create Model
									</button>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);

	return (
		<div className="l-row l-pt-sm">
			<div className="col-md-12">
				<h1 className="a-heading a-heading--heading-1">
					Symphony Settings
				</h1>
				<div className="m-tab-row l-mt-sm" style={{ display: "flex" }}>
					<a
						href="#"
						className={`m-tab-row__tab ${
							activeTab === 0 ? "m-tab-row__tab--active" : ""
						}`}
						onClick={() => handleTabClick(0)}
					>
						<span className="m-tab-row__tab-title">
							Access Control
						</span>
					</a>
					<a
						href="#"
						className={`m-tab-row__tab ${
							activeTab === 1 ? "m-tab-row__tab--active" : ""
						}`}
						onClick={() => handleTabClick(1)}
					>
						Data Configuration
					</a>
					{/* <a
						href="#"
						className={`m-tab-row__tab ${
							activeTab === 2 ? "m-tab-row__tab--active" : ""
						}`}
						onClick={() => handleTabClick(2)}
					>
						Models
					</a> */}
					{/* <a
						href="#"
						className={`m-tab-row__tab ${
							activeTab === 3 ? "m-tab-row__tab--active" : ""
						}`}
						onClick={() => handleTabClick(3)}
					>
						Tab 4
					</a> */}
				</div>
				{activeTab === 0 && licenseManagementControl}
				{activeTab === 1 && (
					<>
						{factsControl}
						{modelsControl}
					</>
				)}
			</div>

			{/*<div className="col-md-12 l-mt-md">*/}
			{/*    <div className="row">*/}
			{/*        <div className="col-12">*/}
			{/*            <h5 className="a-heading a-heading--heading-3">*/}
			{/*                Analytics*/}
			{/*            </h5>*/}
			{/*            <p className="a-muted-text">Key metrics that help you keep tabs on how Symphony is being used across your organization.</p>*/}
			{/*        </div>*/}
			{/*    </div>*/}
			{/*    <div className="row">*/}
			{/*        <div className="col-12">*/}
			{/*            <div className="o-block">*/}
			{/*                <div className="o-stat-row o-stat-row--left-aligned">*/}
			{/*                    <div className="o-stat-row__stat m-stat">*/}
			{/*                        <span className="m-stat__value">984</span>*/}
			{/*                        <span className="m-stat__label">Average Message/Day</span>*/}
			{/*                    </div>*/}
			{/*                    <div className="o-stat-row__stat m-stat">*/}
			{/*                        <span className="m-stat__value">$213.87</span>*/}
			{/*                        <span className="m-stat__label">Average Cost/Day</span>*/}
			{/*                    </div>*/}
			{/*                    <div className="o-stat-row__stat m-stat">*/}
			{/*                        <span className="m-stat__value">$2.76</span>*/}
			{/*                        <span className="m-stat__label">Average Daily Cost/User</span>*/}
			{/*                    </div>*/}
			{/*                </div>*/}
			{/*                <div className="o-stat-row o-stat-row--left-aligned l-mt-sm">*/}
			{/*                    <div className="o-stat-row__stat m-stat">*/}
			{/*                        <span className="m-stat__value">262</span>*/}
			{/*                        <span className="m-stat__label">Average Chats/Day</span>*/}
			{/*                    </div>*/}
			{/*                    <div className="o-stat-row__stat m-stat">*/}
			{/*                        <span className="m-stat__value">412MB</span>*/}
			{/*                        <span className="m-stat__label">Average Storage/Day</span>*/}
			{/*                    </div>*/}
			{/*                    <div className="o-stat-row__stat m-stat">*/}
			{/*                        <span className="m-stat__value">59</span>*/}
			{/*                        <span className="m-stat__label">Average Attached Files/Day</span>*/}
			{/*                    </div>*/}
			{/*                </div>*/}
			{/*            </div>*/}
			{/*        </div>*/}
			{/*    </div>*/}

			{/*    <div className="row l-mt-md">*/}
			{/*        <div className="col-12">*/}
			{/*            <h5 className="a-heading a-heading--heading-3">*/}
			{/*                Quotas*/}
			{/*            </h5>*/}
			{/*            <p className="a-muted-text">Choose from available CAI data sources to provide more context to the assistant.</p>*/}
			{/*        </div>*/}
			{/*    </div>*/}
			{/*    <div className="row">*/}
			{/*        <div className="col-12">*/}
			{/*            <div className="o-block">*/}
			{/*                <div className="row">*/}
			{/*                    <div className="col-md-6">*/}
			{/*                        <div className="m-form-group">*/}
			{/*                            <label htmlFor="" className="a-label a-label--required">Messages / Min</label>*/}
			{/*                            <input type="number" className="a-text-input" value="200" />*/}
			{/*                        </div>*/}
			{/*                    </div>*/}
			{/*                    <div className="col-md-6">*/}
			{/*                        <div className="m-form-group">*/}
			{/*                            <label htmlFor="" className="a-label a-label--required">Messages / Hr</label>*/}
			{/*                            <input type="number" className="a-text-input" value="10000" />*/}
			{/*                        </div>*/}
			{/*                    </div>*/}
			{/*                </div>*/}
			{/*                <div className="row">*/}
			{/*                    <div className="col-md-6">*/}
			{/*                        <div className="m-form-group">*/}
			{/*                            <label htmlFor="" className="a-label a-label--required">Messages / Day</label>*/}
			{/*                            <input type="number" className="a-text-input" value="100000" />*/}
			{/*                        </div>*/}
			{/*                    </div>*/}
			{/*                    <div className="col-md-6">*/}
			{/*                        <div className="m-form-group">*/}
			{/*                            <label htmlFor="" className="a-label a-label--required">Cost / Day</label>*/}
			{/*                            <input type="text" className="a-text-input" value="100" />*/}
			{/*                        </div>*/}
			{/*                    </div>*/}
			{/*                </div>*/}
			{/*            </div>*/}
			{/*        </div>*/}
			{/*    </div>*/}
			{/*    <div className="row l-mt-sm">*/}
			{/*        <div className="col-12">*/}
			{/*            <div className="m-button-row">*/}
			{/*                <button className="a-button">Save Changes</button>*/}
			{/*            </div>*/}
			{/*        </div>*/}
			{/*    </div>*/}
			{/*</div>*/}
			<AddUserModal
				isHidden={addUsersIsHidden}
				onClose={() => {
					setAddUsersIsHidden(true);
				}}
				availableLicenses={remainingLicenses}
			/>
		</div>
	);
};
export default SymphonyAdminSettings;
