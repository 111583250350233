import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Heading, Eyebrow } from 'cai-fusion';
import {
  HeadingRow,
  HeadingRowActions,
  HeadingRowTitle,
} from "../fusion/HeadingRow";
import { Form } from "../fusion/Input";

const icon_CloseSquare = require("../../images/close-square.svg").default;

const annualizedHours = 1880;
const prorationKey = {
  1: 1.0,
  2: 1.0,
  3: 1.0,
  4: 1.0,
  5: 0.92,
  6: 0.83,
  7: 0.75,
  8: 0.66,
  9: 0.583,
  10: 0.5,
  11: 0.42,
  12: 0.33,
};

// Create our number formatter.
var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
  minimumFractionDigits: 2, // (causes 2500 to be printed as $2,500.00)
});

var usdFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
var inrFormatter = new Intl.NumberFormat("en-IN", {
  style: "currency",
  currency: "INR",
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
var phpFormatter = new Intl.NumberFormat("en-PH", {
  style: "currency",
  currency: "PHP",
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const PayrollMeritChangeDetailsPanel = (props) => {
  // props.clickOutsideToClose = true;

  // Navigation Hooks
  const params = useParams();

  const [focusedData, setFocusedData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [hidden, setHidden] = useState(true);
  const [changesMade, setChangesMade] = useState(false);

  const [currentBaseComp, setCurrentBaseComp] = useState(0.0);
  const [currentBonus, setCurrentBonus] = useState(0.0);
  const [currentCommission, setCurrentCommission] = useState(0.0);
  const [currentTotalComp, setCurrentTotalComp] = useState(0.0);
  const [annualizedCurrentBase, setAnnualizedCurrentBase] = useState(0.0);

  const [proposedBaseComp, setProposedBaseComp] = useState(0.0);
  const [proposedBudgetContribution, setproposedBudgetContribution] = useState(0.0);
  const [proposedBonus, setProposedBonus] = useState(0.0);
  const [proposedCommission, setProposedCommission] = useState(0.0);
  const [proposedTotalComp, setProposedTotalComp] = useState(0.0);

  const [proratedBase, setProratedBase] = useState(0.0);
  const [proratedIncrease, setProratedIncrease] = useState(0.0);
  const [prorationMultiplier, setProrationMultiplier] = useState(1.0);
  const [showProrate, setShowProrate] = useState(false);
  // const [pctCompChange, setPctCompChange] = useState(null);

  // for tracking statefulness on the form
  const [increaseDollars, setIncreaseDollars] = useState(0.0);
  const [increasePercentage, setIncreasePercentage] = useState(0.0);
  // const [newBase, setNewBase] = useState(0.0);

  // propogate from attribute
  useEffect(() => {
    setHidden(props.hidden);

    // if being shown
    if (!props.hidden) {
      if (props.data && params.employeeId) {
        let chosenData = props.data.filter((x) => x.id === params.employeeId);
        if (chosenData.length > 0) {
          setFocusedData(chosenData[0]);
        }
      }
    }
  }, [props.hidden, props.data, params.employeeId]);

  // handle form changes
  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    let value = target.value;

    if (
      name === "increaseDollars" ||
      name === "increasePercentage" ||
      name === "newBase"
    ) {
      const reInt = /^\-?[0-9\b]+$/;
      const reFlt1 = /^\-?[0-9]+(\.[0-9])?$/;
      const reFlt2 = /^\-?[0-9]+(\.[0-9][0-9])?$/;

      // if value is not blank, then test the regex.. int/one decimal/two decimal
      if (
        value === "" ||
        reInt.test(value) ||
        reFlt1.test(value) ||
        reFlt2.test(value)
      ) {
        let floatValue = parseFloat(value);
        if (name === "newBase") {
          setproposedBudgetContribution(floatValue);

          // let newCompNumber = value;
          let pctIncrease =
            ((floatValue - currentBaseComp) / currentBaseComp) * 100;
          let dollarIncrease = floatValue - currentBaseComp;

          setIncreaseDollars(dollarIncrease);
          setIncreasePercentage(pctIncrease);

          if (!changesMade) {
            setChangesMade(true);
          }
        } else if (name === "increaseDollars") {
          let newCompNumber = currentBaseComp + floatValue;
          let pctIncrease =
            ((newCompNumber - currentBaseComp) / currentBaseComp) * 100;

          setproposedBudgetContribution(newCompNumber);

          setIncreaseDollars(floatValue);
          setIncreasePercentage(pctIncrease);

          if (!changesMade) {
            setChangesMade(true);
          }
        } else if (name === "increasePercentage") {
          let percent = 1.0 + floatValue / 100;
          let newCompNumber = currentBaseComp * percent;
          let dollarIncrease = newCompNumber - currentBaseComp;

          setproposedBudgetContribution(newCompNumber);

          setIncreaseDollars(dollarIncrease);
          setIncreasePercentage(floatValue);

          if (!changesMade) {
            setChangesMade(true);
          }
        }
      }
    }
  };

  // when data is passed to the panel, set it and set defaults
  // as well as reseting change state
  useEffect(() => {
    if (focusedData) {
      console.log(focusedData);
      // setProposedBaseComp(focusedData.proposedBaseComp ?? 0.0);
      if (focusedData.curBaseComp) setCurrentBaseComp(focusedData.curBaseComp);
      if (focusedData.currentBonus) setCurrentBonus(focusedData.currentBonus);
      if (focusedData.currentCommission)
        setCurrentCommission(focusedData.currentCommission);

      const currentBase =
        focusedData &&
        focusedData.curCompGrade &&
        (focusedData.curCompGrade === "Salary"
          ? focusedData.curBaseComp
          : focusedData.curCompGrade === "Hourly"
          ? focusedData.curBaseComp *
            annualizedHours *
            (focusedData.ftePercentage ?? 1.0)
          : 0.0);
      setAnnualizedCurrentBase(currentBase);
      const currentBonus = focusedData.currentBonus ?? 0.0;
      const currentCommission = focusedData.currentCommission ?? 0.0;
      const currentTotal = currentBase + currentBonus + currentCommission;

      if (focusedData.lastCompChange) {
        // https://stackoverflow.com/questions/16080378/check-if-one-date-is-between-two-dates
        let dateFrom = "05/01/2023".split("/");
        let dateTo = "01/01/2024".split("/");
        let dateCheck = focusedData.lastCompChange.split("/");
        let dateCheckYear = dateCheck[2].split(" ")[0];
        let dateCheckMonth = dateCheck[0];

        let from = new Date(
          dateFrom[2],
          parseInt(dateFrom[0]) - 1,
          dateFrom[1]
        );
        let to = new Date(dateTo[2], parseInt(dateTo[0]) - 1, dateTo[1]);
        let check = new Date(
          dateCheckYear,
          parseInt(dateCheckMonth) - 1,
          dateCheck[1]
        );

        setShowProrate(check >= from && check < to);

        let prorationMultiplier = prorationKey[Number(dateCheckMonth)];
        setProrationMultiplier(prorationMultiplier);
      }

      setCurrentTotalComp(currentTotal);

      if (focusedData.proposedBudgetContribution) {
        setproposedBudgetContribution (focusedData.proposedBudgetContribution);
      }

      if (focusedData.proposedBaseComp) {
        setProposedBaseComp(focusedData.proposedBaseComp);

        let increase = (focusedData.curCompGrade === "Hourly" ? focusedData.proposedBudgetContribution /(annualizedHours * (focusedData.ftePercentage ?? 1.0)) 
          : focusedData.proposedBudgetContribution) - focusedData.curBaseComp;
        setIncreaseDollars(increase);
        setIncreasePercentage(focusedData.proposedBaseComp);
      }
      if (focusedData.proposedBonus)
        setProposedBonus(focusedData.proposedBonus);
      if (focusedData.proposedCommission)
        setProposedCommission(focusedData.proposedCommission);

      setChangesMade(false);
    }
  }, [
    focusedData,
    setCurrentBaseComp,
    setCurrentBonus,
    setCurrentCommission,
    setCurrentTotalComp,
    setProposedBaseComp,
    setProposedBonus,
    setProposedCommission,
  ]);

  // if either proposed base comp or proposed base are changed, update the total comp
  useEffect(() => {
    if (setProposedTotalComp) {
      let fltBase = 0.0;
      let fltBonus = 0.0;

      if (proposedBaseComp !== "" && focusedData && focusedData.curCompGrade) {
        if (focusedData.curCompGrade === "Salary")
          fltBase = parseFloat(proposedBaseComp);
        else if (focusedData.curCompGrade === "Hourly")
          fltBase =
            parseFloat(proposedBaseComp) *
            annualizedHours *
            (focusedData.ftePercentage ?? 1.0);

        // This felt like the right place to update the increase dollar, increase percentage and new base
        // states... but it isn't.. leads to small quirks in behavior.. so don't.
      }

      // TODO
      let proratedIncrease =
        (fltBase - annualizedCurrentBase) * prorationMultiplier;
      setProratedIncrease(proratedIncrease);

      let proratedBaseComp = annualizedCurrentBase + proratedIncrease;
      setProratedBase(proratedBaseComp);

      setProposedTotalComp(
        proratedBaseComp +
          focusedData.proposedBonus +
          focusedData.proposedCommission
      );
    }
  }, [proposedBaseComp, setProposedTotalComp]);

  // when the total proposed comp is changed, set the percent value
  // useEffect(() => {
  //     if (focusedData) {

  //         if (currentTotalComp > 0) {
  //             const compChange = proposedTotalComp - currentTotalComp;
  //             const pctCompChange = (compChange / currentTotalComp) * 100;
  //             setPctCompChange(pctCompChange);
  //         }
  //         else {
  //             setPctCompChange(null);
  //         }
  //     } else {
  //         setPctCompChange(null);
  //     }
  // }, [proposedTotalComp]);

  // on closing of the panel
  const closeClickedHandler = (changesMade) => {
    if (props.onClose) {
      props.onClose(changesMade);
    }
  };

  // on cancelling the change of the panel
  const cancelClickedHandler = (changesMade) => {
    if (props.onCancel) {
      props.onCancel(changesMade);
    }
  };

  // on save
  const saveClickedHandler = () => {
    if (props.onSave) {
      let fltBase = 0.0;

      if (proposedBudgetContribution !== "") fltBase = parseFloat(proposedBudgetContribution);

      props.onSave({
        taskId: focusedData.id,
        // "compChangeId": props.data.compChangeId,
        proposedBudgetContribution: fltBase,
        proposedBaseComp: Math.round(increasePercentage * 100 + Number.EPSILON) / 100,
        assignedTo: focusedData.assignedToUser,
        upn: focusedData.upn
        // "proposedBonus": fltBonus,
        // "effectiveDate": proposedEffectiveDate,
        // "isTenative": effectiveDateIsTentative,
        // "businessJustification": proposedBusinessJustification
      });
    }
  };

  return (
    <div
      className={`o-drawer ${hidden ? "o-drawer--hidden" : "o-drawer--shown"}`}
    >
      <div
        className="o-drawer__overlay"
        style={{ backdropFilter: "none" }}
        onClick={(e) => {
          if (props.clickOutsideToClose) {
            closeClickedHandler(changesMade);
            e.preventDefault();
          }
        }}
      ></div>
      <div className="o-drawer__wrapper">
        <div className="o-drawer__inner">
          <HeadingRow>
            <HeadingRowTitle>
              <Eyebrow>{focusedData && focusedData.jobTitle}</Eyebrow>
              <Heading size={3} className="l-mb-none">
                {focusedData && focusedData.employeeName}
              </Heading>
            </HeadingRowTitle>
            <HeadingRowActions>
              <div className="m-icon-row m-icon-row--right-align">
                <a
                  href="#"
                  id="drawerCloseBtn"
                  className="a-icon m-icon-row__icon"
                  title="Close employee Details"
                  onClick={(e) => {
                    closeClickedHandler(changesMade);
                    e.preventDefault();
                  }}
                >
                  <img src={icon_CloseSquare} className="a-icon__img" />
                </a>
              </div>
            </HeadingRowActions>
          </HeadingRow>
          <Form className="l-mt-sm">
            <div className="row l-mb-xs">
              <div className="col-12">
                <Heading size={5}>Employee Details</Heading>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="m-form-group">
                  <label htmlFor="" className="a-label">
                    Company
                  </label>
                  <p className="a-read-only-value">{focusedData.company}</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="m-form-group">
                  <label htmlFor="" className="a-label">
                    Enterprise Type
                  </label>
                  <p className="a-read-only-value">
                    {focusedData.enterpriseType}
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="m-form-group">
                  <label htmlFor="" className="a-label">
                    Division
                  </label>
                  <p className="a-read-only-value">{focusedData.division}</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="m-form-group">
                  <label htmlFor="" className="a-label">
                    Cost Center
                  </label>
                  <p className="a-read-only-value">
                    {focusedData &&
                      focusedData.costCenter &&
                      focusedData.costCenter.substring(0, 6)}
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="m-form-group">
                  <label htmlFor="" className="a-label">
                    Last Comp Change
                  </label>
                  <p className="a-read-only-value">
                    {`${focusedData.lastCompChangeMonth}/${focusedData.lastCompChangeYear}`}
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="m-form-group">
                  <label htmlFor="" className="a-label">
                    Last Performance Evaluation Score
                  </label>
                  <p className="a-read-only-value">
                    {focusedData.lastReviewLevel
                      ? focusedData.lastReviewLevel
                      : "No Performance Evaluation on Record"}
                  </p>
                </div>
              </div>
            </div>
            <div className="row l-mb-xs l-mt-sm">
              <div className="col-12">
                <h5 className="a-heading a-heading--heading-5">
                  {/* Current Compensation */}
                  Current Compensation
                </h5>
                {focusedData && focusedData.curCompGrade === "Hourly" && (
                  <p className="l-mt-xs a-subtle-text">
                    <strong>Hourly employee.</strong>{" "}
                    <span className="a-muted-text">
                      Current compensation based on an annualized{" "}
                      {annualizedHours} hours and{" "}
                      {focusedData.ftePercentage * 100}% FTE time.
                    </span>
                  </p>
                )}
              </div>
            </div>

            {/* <div className="row">
                            <div className="col-md-6">
                                <div className="m-form-group">
                                    <label htmlFor="" className="a-label">Bonus Target</label>
                                    <p className="a-read-only-value">{formatter.format(currentBonus)}</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="m-form-group">
                                    <label htmlFor="" className="a-label">Base</label>
                                    <p className="a-read-only-value a-read-only-value--truncated">{formatter.format(currentBaseComp)}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="m-form-group">
                                    <label htmlFor="" className="a-label">Commission Target</label>
                                    <p className="a-read-only-value">{formatter.format(currentCommission)}</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="m-form-group">
                                    <label htmlFor="" className="a-label">Total Compensation</label>
                                    <p className="a-read-only-value">{formatter.format(currentTotalComp)}</p>
                                </div>
                            </div>
                        </div> */}
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-8">
                    <label htmlFor="" className="a-label">
                      Base
                    </label>
                  </div>
                  <div className="col-md-4">
                    {/* <p className="a-read-only-value a-read-only-value--truncated"> */}
                    <p>{formatter.format(currentBaseComp)}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-8">
                    <label htmlFor="" className="a-label">
                      Total Compensation
                    </label>
                  </div>
                  <div className="col-md-4">
                    {/* <p className="a-read-only-value"> */}
                    <p>{formatter.format(currentTotalComp)}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  {focusedData.curBaseCurCode !== "USD" && (
                    <>
                      <div className="col-md-8">
                        <label htmlFor="" className="a-label">
                          Origin Currency Amount
                        </label>
                      </div>
                      <div className="col-md-4">
                        {/* <p className="a-read-only-value"> */}
                        <p>{`${
                          focusedData.curBaseCurCode === "INR"
                            ? inrFormatter.format(focusedData.curBaseCompOrigin)
                            : focusedData.curBaseCurCode === "PHP"
                            ? phpFormatter.format(focusedData.curBaseCompOrigin)
                            : focusedData.curBaseCurCode === "USD"
                            ? usdFormatter.format(focusedData.curBaseCompOrigin)
                            : focusedData.curBaseCompOrigin
                        }`}</p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-8">
                    <label htmlFor="" className="a-label">
                      Bonus Target
                    </label>
                  </div>
                  <div className="col-md-4">
                    {/* <p className="a-read-only-value"> * /}
                    <p>{formatter.format(currentBonus)}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-8">
                    <label htmlFor="" className="a-label">
                      Commission Target
                    </label>
                  </div>
                  <div className="col-md-4">
                    {/* <p className="a-read-only-value"> * /}
                    <p>{formatter.format(currentCommission)}</p>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="row l-mb-xs l-mt-sm">
              <div className="col-12">
                <h5 className="a-heading a-heading--heading-5">
                  Proposed Compensation
                </h5>
                {(increaseDollars === undefined ||
                  increaseDollars === 0 ||
                  increaseDollars === "0") && (
                  <div className="m-callout m-callout--shaded l-mt-xs">
                    <svg
                      className="m-callout__icon"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 358.38"
                    >
                      <g id="Layer_2">
                        <g id="Engine_Warning">
                          <path d="M38.4,179.19c-.01-47.52,15.54-93.74,44.29-131.58,4.24-5.33,3.52-13.06-1.64-17.51l-9.73-8.35c-5.39-4.66-13.54-4.06-18.2,1.33-.11,.13-.23,.27-.34,.41-70.38,91.86-70.38,219.52,0,311.38,4.41,5.56,12.49,6.5,18.05,2.1,.15-.12,.29-.23,.43-.36l9.78-8.35c5.12-4.45,5.84-12.14,1.64-17.46-28.74-37.86-44.29-84.09-44.28-131.63ZM255.99,0C157.03,0,76.8,80.23,76.8,179.19s80.23,179.19,179.19,179.19,179.19-80.23,179.19-179.19S354.95,0,255.99,0h0Zm0,281.58c-14.14,0-25.6-11.46-25.6-25.6s11.46-25.6,25.6-25.6,25.6,11.46,25.6,25.6-11.46,25.6-25.6,25.6Zm20.48-88.31c-.66,6.58-6.23,11.58-12.85,11.52h-15.36c-6.58,0-12.09-4.97-12.75-11.52l-10.24-102.39c-.68-7.06,4.5-13.34,11.56-14.02,.43-.04,.86-.06,1.29-.06h35.84c7.1,.02,12.83,5.8,12.81,12.89,0,.4-.02,.79-.06,1.18l-10.24,102.39ZM458.17,22.17c-4.45-5.57-12.57-6.47-18.13-2.02-.14,.11-.27,.22-.4,.33l-9.57,8.5c-5.11,4.5-5.76,12.22-1.48,17.51,60.21,78.24,60.21,187.21,0,265.46-4.28,5.29-3.62,13.01,1.48,17.51l9.57,8.45c5.36,4.7,13.51,4.16,18.21-1.2,.11-.13,.22-.26,.33-.39,71.78-92.39,71.78-221.7,0-314.09v-.05Z" />
                        </g>
                      </g>
                    </svg>
                    <div className="m-callout__content">
                      <h4 className="m-callout__title">
                        You've specified a 0% increase.
                      </h4>
                      {/* <p className="m-callout__text a-muted-text">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Accusamus odio pariatur rem, natus ullam voluptatibus.</p> */}
                    </div>
                  </div>
                )}
                {proposedTotalComp < currentTotalComp && (
                  <div className="m-callout m-callout--shaded l-mt-xs">
                    <svg
                      className="m-callout__icon"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 358.38"
                    >
                      <g id="Layer_2">
                        <g id="Engine_Warning">
                          <path d="M38.4,179.19c-.01-47.52,15.54-93.74,44.29-131.58,4.24-5.33,3.52-13.06-1.64-17.51l-9.73-8.35c-5.39-4.66-13.54-4.06-18.2,1.33-.11,.13-.23,.27-.34,.41-70.38,91.86-70.38,219.52,0,311.38,4.41,5.56,12.49,6.5,18.05,2.1,.15-.12,.29-.23,.43-.36l9.78-8.35c5.12-4.45,5.84-12.14,1.64-17.46-28.74-37.86-44.29-84.09-44.28-131.63ZM255.99,0C157.03,0,76.8,80.23,76.8,179.19s80.23,179.19,179.19,179.19,179.19-80.23,179.19-179.19S354.95,0,255.99,0h0Zm0,281.58c-14.14,0-25.6-11.46-25.6-25.6s11.46-25.6,25.6-25.6,25.6,11.46,25.6,25.6-11.46,25.6-25.6,25.6Zm20.48-88.31c-.66,6.58-6.23,11.58-12.85,11.52h-15.36c-6.58,0-12.09-4.97-12.75-11.52l-10.24-102.39c-.68-7.06,4.5-13.34,11.56-14.02,.43-.04,.86-.06,1.29-.06h35.84c7.1,.02,12.83,5.8,12.81,12.89,0,.4-.02,.79-.06,1.18l-10.24,102.39ZM458.17,22.17c-4.45-5.57-12.57-6.47-18.13-2.02-.14,.11-.27,.22-.4,.33l-9.57,8.5c-5.11,4.5-5.76,12.22-1.48,17.51,60.21,78.24,60.21,187.21,0,265.46-4.28,5.29-3.62,13.01,1.48,17.51l9.57,8.45c5.36,4.7,13.51,4.16,18.21-1.2,.11-.13,.22-.26,.33-.39,71.78-92.39,71.78-221.7,0-314.09v-.05Z" />
                        </g>
                      </g>
                    </svg>
                    <div className="m-callout__content">
                      <h4 className="m-callout__title">
                        You've specified a negative change in total target
                        compensation.
                      </h4>
                      {proposedBonus != undefined &&
                        currentBonus != undefined &&
                        proposedBonus < currentBonus && (
                          <p className="m-callout__text a-muted-text">
                            This individual's target bonus has decreased,
                            impacting the overall compensation calculation.
                          </p>
                        )}
                      {proposedCommission != undefined &&
                        currentCommission != undefined &&
                        proposedCommission < currentCommission && (
                          <p className="m-callout__text a-muted-text">
                            This individual's target commission has decreased,
                            impacting the overall compensation calculation.
                          </p>
                        )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="m-form-group">
                  <label htmlFor="" className="a-label a-label--required">
                    Increase (in $)
                  </label>
                  <input
                    type="number"
                    name="increaseDollars"
                    className="a-text-input"
                    readOnly={!focusedData.assignedToUser}
                    // value={increaseDollars}
                    value={
                      Math.round(increaseDollars * 100 + Number.EPSILON) / 100
                    }
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="m-form-group">
                  <label htmlFor="" className="a-label a-label--required">
                    Percentage Increase
                  </label>
                  <input
                    type="number"
                    name="increasePercentage"
                    readOnly={!focusedData.assignedToUser}
                    className="a-text-input"
                    // value={increasePercentage}
                    value={
                      Math.round(increasePercentage * 100 + Number.EPSILON) /
                      100
                    }
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="m-form-group">
                  <label htmlFor="" className="a-label a-label--required">
                    New Base Salary {console.log(focusedData.assignedToUser)}
                  </label>
                  <input
                    type="number"
                    name="newBase"
                    className="a-text-input"
                    readOnly={!focusedData.assignedToUser ? true : false}
                    // value={proposedBaseComp}
                    value={
                      Math.round(proposedBudgetContribution * 100 + Number.EPSILON) / 100
                    }
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
              {/* <div className="col-md-6">
                <div className="m-form-group">
                  <label htmlFor="" className="a-label">
                    Effective Prorated Base
                  </label>
                  <p className="a-read-only-value"></p>
                </div>
              </div> */}
            </div>
            {/* <div className="row">
              {showProrate ?
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-4">
                      <label htmlFor="" className="a-label">
                        Effective Prorated Base
                      </label>
                    </div>
                    <div className="col-md-2">
                      <p>{formatter.format(proratedBase)}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12" style={{ paddingLeft: "40px" }}>
                      <p className="l-mt-none a-subtle-text">
                        <strong>{prorationMultiplier * 100}% Proration.</strong>{" "}
                        <span className="a-muted-text">
                          Based on a last comp change of{" "}
                          {focusedData.lastCompChange}.
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                : <></>}
            </div> */}
            {/* <div className="row">
              <div className="col-md-12"></div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-8">
                    <label htmlFor="" className="a-label">
                      Bonus Target
                    </label>
                  </div>
                  <div className="col-md-4">
                    <p>{formatter.format(proposedBonus)}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-8">
                    <label htmlFor="" className="a-label">
                      Commission Target
                    </label>
                  </div>
                  <div className="col-md-4">
                    <p>{formatter.format(proposedCommission)}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-8">
                    <label htmlFor="" className="a-label">
                      Total Target Compensation
                    </label>
                  </div>
                  <div className="col-md-4">
                    <p>{formatter.format(proposedTotalComp)}</p>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="row">
              <div className="col-md-6">
                <div className="m-form-group">
                  <label htmlFor="" className="a-label">
                    Bonus Target
                  </label>
                  <p className="a-read-only-value">
                    {formatter.format(proposedBonus)}
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="m-form-group">
                  <label htmlFor="" className="a-label">
                    Commission Target
                  </label>
                  <p className="a-read-only-value">
                    {formatter.format(proposedCommission)}
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="m-form-group">
                  <label htmlFor="" className="a-label">
                    Total Target Compensation
                  </label>
                  <p className="a-read-only-value">
                    {formatter.format(proposedTotalComp)}
                  </p>
                </div>
              </div>
            </div> */}
            {focusedData.assignedToUser ? (
              <div className="row l-mb-xs l-mt-sm">
                <div className="col-md-12">
                  <div className="m-button-row">
                    <a
                      href="#"
                      id="modalTrigger"
                      className="a-button a-button--outline m-button-row__button"
                      onClick={(e) => {
                        cancelClickedHandler(changesMade);
                        e.preventDefault();
                      }}
                    >
                      Cancel
                    </a>
                    <a
                      href="#"
                      className="a-button m-button-row__button"
                      onClick={(e) => {
                        saveClickedHandler();
                        e.preventDefault();
                      }}
                    >
                      Save
                    </a>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};
