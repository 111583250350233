import { useAuthentication } from "../contexts/AuthContext";
import { NavbarAvatarMenu, NavbarDropdownItem } from "../components/fusion";
import { NavLink } from "react-router-dom";
import myCaiApiService from "../services/mycai-api-v1";
import useEnvironment from "./EnvContext";
import { useAppConfig } from "../contexts/AppConfigContext";

const CAIAvatarMenu = () => {
    const {
        graphData,
        imgUrl,
        impersonating,
        apiAccessToken,
        logOut,
        // setHideImpersonateModal, 
        setResetInProgress
    } = useAuthentication();

    // const { impersonateAvailable, resetDbAvailable } = useEnvironment();
    const { setHideImpersonateModal, impersonateAvailable, resetDbAvailable } = useAppConfig();

    return (
        <NavbarAvatarMenu
            imgUrl={imgUrl}
            displayName={
                impersonating
                    ? impersonating.displayName
                    : graphData == null
                        ? ""
                        : graphData.displayName
            }
        >
            {impersonateAvailable && (
                <NavbarDropdownItem
                    onClick={() => {
                        setHideImpersonateModal(false);
                    }}
                >
                    Impersonate User
                    <span className="o-navbar__dropdown-list-item-help o-navbar__dropdown-list-item-help--warning">
                        This is a UAT function only.
                    </span>
                </NavbarDropdownItem>
            )}
            {(
                <NavLink to={"/settings/display"} className="o-navbar__dropdown-list-item">
                    My Settings
                </NavLink>
            )}
            {resetDbAvailable && (
                <NavbarDropdownItem
                    onClick={() => {
                        myCaiApiService.Reset(apiAccessToken).then(() => {
                            setResetInProgress(false);
                        });
                        setResetInProgress(true);
                    }}
                >
                    Reset Payroll Database
                    <span className="o-navbar__dropdown-list-item-help o-navbar__dropdown-list-item-help--warning">
                        This is a UAT function only.
                    </span>
                </NavbarDropdownItem>
            )}
            {/* <NavbarDropdownItem>
                View Profile
            </NavbarDropdownItem> */}
            <a
                className="o-navbar__dropdown-list-item o-navbar__dropdown-list-item--top-hr"
                onClick={(e) => {
                    e.preventDefault();
                    logOut();
                }}
            >
                Sign Out
            </a>
        </NavbarAvatarMenu>
    );
};

export default CAIAvatarMenu;
