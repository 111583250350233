import { createContext, useState, useContext, useEffect } from "react";
import { useAuthentication } from "../../../contexts/AuthContext";
import { useSymphonyApiService } from "../../../hooks/useSymphonyApi";
import { useChat } from "./ChatContext";
import { useUserProfile } from "./UserProfileContext";

import { useSettings } from "./SettingsContext";

const AssistantContext = createContext();

function AssistantProvider({ children }) {
	const { apiAccessToken, isAuthenticated } = useAuthentication();

	const apiServiceClient = useSymphonyApiService("v2");

    // Context-dependent things
    const { chat, chatId, isChatInContext, fetchChatId } = useChat();
    const { defaultChatSettings, defaultModel, defaultAssistant, userProfile } = useUserProfile();
    const { modelEncoding } = useSettings();

    // Current live assistant
    const [assistant, setAssistant] = useState(null);
    // List of available assistants
    const [assistants, setAssistants] = useState([]);
    // List of default available assistants
    const [defaultAssistants, setDefaultAssistants] = useState([]);

    // The token count for the current assistant
    const [assistantTokenCount, setAssistantTokenCount] = useState(0);

    // Updates the assistant when the chat state changes.
    useEffect(() => {
        setAssistant(chat?.assistantInstruction);
        handleAssistantChange(chat?.assistantInstruction);
    }, [chat])
    
    // In the event that we change the text box for the prompt, this creates a custom assistant.
    const createCustomAssistant = async (systemText) => {
        // Grab either the current assistant or the custom assistant.
        console.log("[ASSISTANT] Updating to custom assistant with text", systemText);
        let newAssistant = assistant.displayName === "Custom" ? {...assistant} : {...assistants.find((opt) => opt.displayName === "Custom")} 
        newAssistant.systemMessage = systemText;
        setAssistant(newAssistant);
    }

    // assistantTokenCount calculation
    useEffect(() => {
        let total = 0;
        const assistantEncoding = assistant?.instructionEncodings?.find(
            (x) => x.encodingEngine === modelEncoding
        );
        if (assistantEncoding) {
            total += assistantEncoding?.tokenCount;
        }
        setAssistantTokenCount(total);
    }, [assistant])

    // Gets the list of shared assistants 
    useEffect(() => {
        const getSharedAssistants = async () => {
            const customAssistant = {
                assistantInstructionId: "00000000-0000-0000-0000-000000000000",
                displayName: "Custom",
                systemMessage: "",
            };

            console.log("[ASSISTANT] Grabbing list of assistants...");
            let respAssistants = apiAccessToken ? await apiServiceClient.Assistants.getAssistants() : [];
            if (!respAssistants) respAssistants = []
            console.log("[ASSISTANT] Assistants found:", respAssistants);

            // Update the list of assistants as well as the default list, to use as a fallback.
            setAssistants([...respAssistants, {...customAssistant}]);
            setDefaultAssistants([...respAssistants, {...customAssistant}]);
        }

        getSharedAssistants();
    }, [isAuthenticated, apiAccessToken, userProfile])

    // This handles the assistants state and fills it in with all the appropriate options. 
    const handleAssistantChange = (newAssistant) => {
        if (!isChatInContext()) return;
        if (!apiAccessToken) return;
        if (!isAuthenticated) return;

        if (!newAssistant) return;

        if (newAssistant.displayName === "Custom") {
            // If we have a custom assistant, remove it from the list of assistants and replace it with this one.
            setAssistants([
                ...defaultAssistants?.filter((x) => x.displayName != "Custom"),
                { ...newAssistant }
            ])
        }
        else {
            // If we have an assistant that isn't in the list of assistants, append it here.
            if (defaultAssistants.some((x) => x.assistantInstructionId != newAssistant.assistantInstructionId)) {
                setAssistants([...defaultAssistants], {...newAssistant});
            }
            else {
                setAssistants([...defaultAssistants]);
            }
        }
    }

    // This saves any assistant change to the API.
    const updateAssistant = async (newAssistant) => {
        if (!newAssistant) return;
        // If we're working with a custom assistant, we ALWAYS want to update. Otherwise, don't update if we don't have to.
        if (newAssistant.displayName !== "Custom" && newAssistant.assistantInstructionId === assistant?.assistantInstructionId) return;

        setAssistant(newAssistant);

        if (isChatInContext()) {
            console.log("[ASSISTANT] Saving assistant to current chat:", newAssistant);
            await apiServiceClient.Chats.updateAssistantInstruction(chatId, newAssistant.assistantInstructionId, newAssistant.systemMessage)
        }
        else {
            if (newAssistant.displayName === "Custom") {
                if (newAssistant.systemMessage === "") return;
                const chatId = await fetchChatId({ "assistantInstruction": newAssistant });
                //const assistant = await apiServiceClient.Chats.updateAssistantInstruction(chatId, newAssistant.assistantInstructionId, newAssistant.systemMessage);
                setAssistant(newAssistant);
            } else {
                console.log("[ASSISTANT] Saving assistant to user profile:", newAssistant);
                await apiServiceClient.UserProfiles.updateUserAssistantInstruction(newAssistant.assistantInstructionId, newAssistant.systemMessage)
            }
        }
    }

    // If we leave a chat, reset assistant
    useEffect(() => {
        if(isChatInContext()) return;

        setAssistant(defaultAssistant);
        setAssistants(defaultAssistants);
    }, [chatId, defaultAssistant, isChatInContext])


	return (
		<AssistantContext.Provider
			value={{
                // States
				assistant,
                setAssistant,
                assistants,
                setAssistants,
                assistantTokenCount,
                // Functions
                createCustomAssistant,
                updateAssistant,
			}}
		>
			{children}
		</AssistantContext.Provider>
	);
}

// Hook to use the AssistantContext in a component
function useAssistant() {
	const context = useContext(AssistantContext);
	if (context === undefined) {
		throw new Error(
			"useAssistant must be used within a AssistantProvider"
		);
	}
	return context;
}

export { AssistantProvider, useAssistant };
