// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wmde-markdown {
    /* -webkit-text-size-adjust: inherit; */
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    word-wrap: inherit;
    color: inherit;
    background-color: inherit;
}

.pretty-json-container {
    padding: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/fusion/Chat/ChatEntry.css"],"names":[],"mappings":"AAAA;IACI,uCAAuC;IACvC,oBAAoB;IACpB,kBAAkB;IAClB,oBAAoB;IACpB,kBAAkB;IAClB,cAAc;IACd,yBAAyB;AAC7B;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".wmde-markdown {\r\n    /* -webkit-text-size-adjust: inherit; */\r\n    font-family: inherit;\r\n    font-size: inherit;\r\n    line-height: inherit;\r\n    word-wrap: inherit;\r\n    color: inherit;\r\n    background-color: inherit;\r\n}\r\n\r\n.pretty-json-container {\r\n    padding: 20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
