import { useScroll } from "../contexts/ScrollContext";

const ScrollButton = ({ scrollMargin = 0, ...props }) => {
  const { doScroll, showScrollDown, showScrollUp, refs } = useScroll();

  const handleScrollUp = () => {
    const currentScrollPosition = window.scrollY;
    const endRefPosition = refs.end?.current?.getBoundingClientRect()?.top + window.scrollY - scrollMargin;

    if (currentScrollPosition > endRefPosition) {
      doScroll(scrollMargin, 'end');
    } else {
      doScroll(scrollMargin, 'start');
    }
  };

  if (showScrollUp) {
    return (
      <button onClick={handleScrollUp} {...props}>
        Scroll Up
      </button>
    );
  }

  if (showScrollDown) {
    return (
      <button onClick={() => doScroll(scrollMargin, 'end')} {...props}>
        Scroll Down
      </button>
    );
  }

  return null;
};

export default ScrollButton;