import { useChat } from "../contexts/ChatContext";
import { ReactComponent as IconCloseSquare } from "../../../images/cancel.svg";

import { Button, Modal } from "cai-fusion";

const ExportChatModal = ({ isHidden, onClose, clickOutsideToClose }) => {
	const { exportChat } = useChat();

	return (
		<Modal
			id="export-modal"
			isHidden={isHidden}
			clickOutsideToClose={clickOutsideToClose}
			title="Export Chat"
			onClose={(e) => {
				onClose();
				e.preventDefault();
			}}
			actions={
				<>
					<Button
						outline
						onClick={(e) => {
							e.preventDefault();
							onClose();
						}}
						children="Cancel"
					/>
					<Button
						onClick={(e) => {
							e.preventDefault();
							exportChat();
							onClose();
						}}
						children="Export Chat"
					/>
				</>
			}
		>
			<div className="l-row">
				<div class="l-one-col">
					<p>
						Download this chat as a PDF so that you can archive,
						share it, or even include it in other Symphony chats.
					</p>
					{/* <form action="" class="o-form l-mt-sm">
						<label class="a-radio-btn">
							<span class="a-radio-btn__label">
								Include the chat's audit trail
							</span>
							<input type="radio" checked name="share-type" />
							<div class="b-input"></div>
						</label>
						<label class="a-radio-btn l-mt-xs">
							<span class="a-radio-btn__label">
								Don't include audit trail
							</span>
							<input type="radio" name="share-type" />
							<div class="b-input"></div>
						</label>
					</form> */}
				</div>
			</div>
		</Modal>
	);
};

export default ExportChatModal;
