import React from "react";
import PropTypes from "prop-types";

interface TimeAgoProps {
    timestamp: string,
    limit?: number,
    limitUnit?: "second" | "minute" | "hour" | "day" | "month"
}

interface Interval {
    label: string;
    amt: number;
}

/**
 * A relative timestamp. When a given limit and limitUnit are specified, the timestamp will be rendered relative up until that limit, after which the time will be rendered as a locale string. Automatically updates every minute.
 * 
 * ## _
 * ### Arguments
 * @param {string} timestamp - An ISO6801 timestamp.
 * @param {number} limit - The number associated with the limitUnit.
 * @param {string} limitUnit - One of "second", "minute", "hour", "day", or "month".
 */
class TimeAgo extends React.Component<TimeAgoProps> {
    intervalId: NodeJS.Timeout | undefined;

    static propTypes = {
        timestamp: PropTypes.string,
        limit: PropTypes.number,
        limitUnit: PropTypes.oneOf(["second", "minute", "hour", "day", "month"]),
    };

    state = {
        now: new Date(Date.now()),
    };

    // A loop to update every minute. Setting the current time will update the state, rerendering everything.
    componentDidMount() {
        this.intervalId = setInterval(() => {
            this.setState({ now: new Date(Date.now()) });
        }, 60000);
    }

    // A catch so we can remove the interval when this component unloads.
    componentWillUnmount() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    render() {
        const { timestamp, limit, limitUnit, ...rest } = this.props;

        // How many seconds there are in each unit.
        const times: Interval[] = [
            { label: "year", amt: 31536000 },
            { label: "month", amt: 2592000 },
            { label: "day", amt: 86400 },
            { label: "hour", amt: 3600 },
            { label: "minute", amt: 60 },
            { label: "second", amt: 1 },
        ];

        const getTimeAgo = (timestamp: string) => {
            // Sometimes the returned timestamp is in UTC time, but it isn't represented as such. This alleviates that.
            if (!timestamp) return "";

            if (!timestamp.endsWith('Z')) {
                // Timestamps ending in "Z" are read as UTC time.
                timestamp += "Z"
            }

            const date = new Date(timestamp);
            const now = this.state.now;
            const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);

            // Limit check - if we've gone over the limit given, render the time as the locale string.
            const limitInSeconds = (times.find((value) => value.label === limitUnit)?.amt || 0) * (limit || 0);
            if (limitInSeconds != 0 && diffInSeconds > limitInSeconds) {
                //console.log("We found one! Limit hit.", date.toLocaleString());
                return date.toLocaleString();
            }

            // Go down the list and find the highest available unit for the remaining time.
            for (const time of times) {
                // Calculate the amount that should be shown for the unit (e.g. 5 hours for 432000 seconds)
                const unit = Math.floor(diffInSeconds / time.amt);
                if (unit > 0) {
                    // Format the string. e.g "5 seconds ago"
                    //console.log("We found one! Unit hit.", `${unit} ${time.label}${unit > 1 ? "s" : ""} ago`);
                    return `${unit} ${time.label}${unit > 1 ? "s" : ""} ago`
                }
            }

            return "just now"

        }
    
        return (
            <>{getTimeAgo(timestamp)}</>            
        )
    }
}

export default TimeAgo;