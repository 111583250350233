import { useState, useEffect } from 'react';
import { PROD_allowedDbReseters, PROD_allowedImpersonators, TEST_allowedDbReseters, TEST_allowedImpersonators } from '../constants/constants';
import { useAuthentication } from '../contexts/AuthContext';
const useEnvironment = () => {

    const [isProd, setIsProd] = useState(false);
    const [impersonateAvailable, setImpersonateAvailable] = useState(false);
    const [resetDbAvailable, setResetDbAvailable] = useState(false);
    const { graphData, impersonating } = useAuthentication();
    
    // Used to determine the platform the code is being run on
    useEffect(() => {
        if (graphData) {
            const currentHref = window.location.href;
            const isDev = currentHref.includes("mycai-dev") || currentHref.includes("localhost:");
            const isTest = currentHref.includes("testmy.cai.io") || currentHref.includes("mycai-test");
            const isProd = !isDev && !isTest;

            if (isDev || isTest) {
                console.log("[DEBUG] Hello from test environment! If you are seeing this on prod, then uh oh");
                console.log(`This is the ${isDev ? "local" : "development"} environment.`);
            }

            setIsProd(isProd);

            const checkPermissions = (allowedList) => 
                allowedList.map((x) => x.toLowerCase()).includes(graphData.displayName.toLowerCase());

            if (isDev || impersonating) {
                setImpersonateAvailable(true);
                setResetDbAvailable(true);
            } else if (isTest) {
                setImpersonateAvailable(checkPermissions(TEST_allowedImpersonators));
                setResetDbAvailable(checkPermissions(TEST_allowedDbReseters));
            } else if (isProd) {
                setImpersonateAvailable(checkPermissions(PROD_allowedImpersonators));
                setResetDbAvailable(checkPermissions(PROD_allowedDbReseters));
            } else {
                setImpersonateAvailable(false);
                setResetDbAvailable(false);
            }
        } else {
            setImpersonateAvailable(false);
            setResetDbAvailable(false);
        }
    }, [impersonating, graphData]);

    return { isProd, impersonateAvailable, resetDbAvailable };
};

export default useEnvironment;
