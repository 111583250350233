import React, { useEffect, useState, useRef, useCallback } from "react";
import { ReactDOM } from "react";
import PropTypes from "prop-types";
import { ReactComponent as IconCancel } from "../../../images/icon-cancel.svg";
import MarkdownRenderer from "../MarkdownRenderer";
import MDEditor from "@uiw/react-md-editor";
import "./ChatEntry.css";
import { getCodeString } from "rehype-rewrite";
import katex from "katex";
import mermaid from "mermaid";
import { SimpleToolTip } from "../ToolTips";
import { useMessages } from "../../symphony/contexts/MessageContext";
import ReactJson from "@microlink/react-json-view";

import { ReactComponent as IconCopy } from "../../../images/icon-copy (1).svg";

mermaid.initialize({
	startOnLoad: true,
});

class Mermaid extends React.Component {
	componentDidMount() {
		mermaid.contentLoaded();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.chart !== this.props.chart) {
			document
				.getElementById("mermaid-chart")
				.removeAttribute("data-processed");
			mermaid.contentLoaded();
		}
	}

	render() {
		return (
			<div id="mermaid-chart" className="mermaid">
				{this.props.chart}
			</div>
		);
	}
}

const MdCodeRenderer = ({ inline, children = [], className, ...props }) => {
	const [isMinimized, setIsMinimized] = useState(false);

	const txt = children[0] || "";
	const code =
		props.node && props.node.children
			? getCodeString(props.node.children)
			: txt;

	// Check if it's a KaTeX or Mermaid
	const isKaTeX =
		typeof code === "string" &&
		typeof className === "string" &&
		/^language-katex/.test(className.toLocaleLowerCase());
	const isMermaid =
		className && /^language-mermaid/.test(className.toLocaleLowerCase());
	const isJSON =
		className && /^language-json/.test(className.toLocaleLowerCase());

	// Function to handle downloading the code as a file
	const handleDownload = () => {
		const blob = new Blob([code], { type: "text/plain;charset=utf-8" });
		const fileType = className?.split("-")[1] || "txt"; // infer the file type from className
		const link = document.createElement("a");
		link.href = URL.createObjectURL(blob);
		link.download = `code.${fileType}`;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	// Function to copy code block to clipboard
	const handleCopy = () => {
		navigator.clipboard.writeText(code);
	};

	// If it's an inline KaTeX
	if (inline) {
		if (typeof txt === "string" && /^\$\$(.*)\$\$/.test(txt)) {
			const html = katex.renderToString(
				txt.replace(/^\$\$(.*)\$\$/, "$1"),
				{
					throwOnError: false,
				}
			);
			return <code dangerouslySetInnerHTML={{ __html: html }} />;
		}
		return <code>{txt}</code>;
	}

    let codeBlock = <code className={String(className)}>{children}</code>;

	// If it's a Mermaid
	if (isMermaid) {
		codeBlock = (
			<>
				<Mermaid chart={code} />
			</>
		);
	}

	// If it's a KaTeX
	if (isKaTeX) {
		const html = katex.renderToString(code, {
			throwOnError: false,
		});
		codeBlock = (
			<code
				style={{ fontSize: "150%" }}
				dangerouslySetInnerHTML={{ __html: html }}
			/>
		);
	}

	// If it's JSON, render with collapsible JSONTree
	if (isJSON) {
		try {
			const parsedJson = JSON.parse(code);
			codeBlock = (<ReactJson src={parsedJson} />);
		} catch (error) {}
	}

	// Default return
	// return <code className={String(className)}>{children}</code>;
    return (
        <>
            {/* <div className="m-icon-row m-icon-row--right-align m-icon-row--condensed">
                <ChatEntryAction icon={IconCopy} />
            </div> */}
            {codeBlock}
        </>
    );
};

const ChatEntryAction = ({
	index,
	alt,
	options,
	onClick,
	messageId,
	messagePairId,
	message,
	icon: ActionIcon,
}) => {
	const [menuShown, setMenuShown] = useState(false);
	const [active, setActive] = useState(false);
	const { regenerateMessage } = useMessages;

	return (
		// if has options instead, override the click function and do things differently
		<SimpleToolTip
			key={index}
			refElement={
				options && options.length > 0 ? (
					<div
						className={`o-button-context-menu${
							menuShown && " o-button-context-menu--shown"
						}`}
					>
						<button
							key={index}
							className="a-icon-link m-icon-row__icon"
							aria-label={alt}
							onClick={(e) => {
								e.preventDefault();
								setMenuShown(!menuShown);
							}}
						>
							<div className="a-icon-link__inner">
								<div className="a-icon-link__icon-wrapper">
									{menuShown ? (
										<IconCancel className="a-icon-link__icon" />
									) : (
										<ActionIcon className="a-icon-link__icon" />
									)}
								</div>
							</div>
						</button>
						<ul className="m-context-menu o-button-context-menu__menu">
							{options.map((option, index) => (
								<li className={option.className} key={index}>
									<button
										className={option.buttonClassName}
										disabled={option.isDisabled}
										data-modal-target={option.modalTarget}
										data-drawer-target={option.drawerTarget}
										onClick={(e) => {
											e.preventDefault();
											if (option.actionFunction) {
												option.actionFunction(
													messageId,
													messagePairId,
													message,
													regenerateMessage
												);
											}
										}}
									>
										<div className="a-icon-link__inner">
											<div className="a-icon-link__icon-wrapper">
												{option.IconElement}
											</div>
											<span className="a-icon-link__text">
												{option.text}
												{option.helpText && (
													<span className="a-icon-link__help-text">
														{option.helpText}
													</span>
												)}
											</span>
										</div>
									</button>
								</li>
							))}
						</ul>
					</div>
				) : (
					// otherwise, be a normal button
					<button
						key={index}
						className={`a-icon-link m-icon-row__icon${
							active ? " a-icon-link--active" : ""
						}`}
						aria-label={alt}
						onClick={(e) => {
							e.preventDefault();
							if (onClick) {
								onClick(
									messageId,
									messagePairId,
									message,
									regenerateMessage
								);
							}
						}}
					>
						<div className="a-icon-link__inner">
							<div className="a-icon-link__icon-wrapper">
								<ActionIcon className={`a-icon-link__icon`} />
							</div>
						</div>
					</button>
				)
			}
			text={alt}
		/>
	);
};

const MdLinkRenderer = ({ ...props }) => (
	<a {...props} target="_blank" rel="noopener noreferrer">
		{props.children}
	</a>
);

const ChatEntry = ({
	messageId,
	messagePairId,
	role,
	content,
	statusMessage,
	IconElement,
	isLoading,
	chatEntryActions,
	userProfileId,
	userProfileImages,
	isEditing,
	setEditingMessageId,
	resendMessage,
}) => {
	const lottieRef = useRef();
	const [userIcon, setUserIcon] = useState(null);

	const [editedMessage, setEditedMessage] = useState(
		content
			.filter((contentItem) => contentItem.type === "text")
			.sort((a, b) => a.contentOrder - b.contentOrder)
			.map((contentItem) => contentItem.text)
			.join("\n")
	);

	useEffect(() => {
		if (!lottieRef.current) {
			return;
		}
		if (typeof lottieRef.current.play() === "function") {
			return;
		}
		if (isLoading) {
			lottieRef?.current?.play();
		} else {
			lottieRef?.current?.stop();
		}
	}, [isLoading, IconElement]);

	const handleResubmit = () => {
		resendMessage(messageId, editedMessage);
		setEditingMessageId(null);
	};

	return (
		<div
			className={`m-chat-entry ${
				role === "assistant"
					? "m-chat-entry--reply"
					: role === "error"
					? "m-chat-entry--reply m-chat-entry--error"
					: role === "info"
					? "m-chat-entry--info"
					: ""
			}`}
		>
			<div className="m-chat-entry__avatar-wrapper">
				<div className="a-avatar">
					<div className="a-avatar__img a-avatar__img--lottie">
						{IconElement ? (
							IconElement(lottieRef, userProfileId)
						) : (
							<></>
						)}
					</div>
				</div>
			</div>
			{isEditing ? (
				<div className="m-chat-entry__message-wrapper">
					<div className="m-form-group">
						<label htmlFor="" className="a-label">
							Edited Message
						</label>
						<textarea
							value={editedMessage}
							rows={5}
							onChange={(e) => setEditedMessage(e.target.value)}
							className="a-textarea-input"
							style={{ width: "100%" }}
						/>
					</div>
					<div className="m-button-row">
						<button
							className="a-button a-button--link m-button-row__button"
							onClick={(e) => {
								e.preventDefault();
								setEditingMessageId(null);
							}}
						>
							Cancel
						</button>
						<button
							className="a-button m-button-row__button"
							onClick={(e) => {
								e.preventDefault();
								handleResubmit();
							}}
						>
							Send
						</button>
					</div>
				</div>
			) : (
				<div className="m-chat-entry__message-wrapper">
					{isLoading ? (
						<div className="m-chat-entry__message">
							<div className="a-skeleton a-skeleton--line l-mb-xs"></div>
							<div className="a-skeleton a-skeleton--line l-mb-xs"></div>
							<div className="a-skeleton a-skeleton--line l-mb-xs"></div>
						</div>
					) : (
						<div className="m-chat-entry__message">
							{
								// iterate over the content array and handle each item based on its type
								content
									.sort(
										(a, b) =>
											a.contentOrder - b.contentOrder
									)
									.map((contentItem, index) => {
										if (contentItem.type === "text") {
											return (
												<MDEditor.Markdown
													key={index}
													source={contentItem.text}
													components={{
														code: MdCodeRenderer,
														a: MdLinkRenderer, // Add this line to use the CustomLink for 'a' tags
													}}
												/>
											);
											// <p>{message}</p>
											// TODO  https://www.npmjs.com/package/@uiw/react-md-editor
											//   <MarkdownRenderer content={message} />
											/* <p>{message}</p> */
											/* <MDEditor
                      value={message}
                      preview="preview"
                      commands={[]}
                      previewOptions={{
                        components: {
                          code: MdCodeRenderer,
                        },
                      }}
                    /> */
										} else if (
											contentItem.type === "image_url"
										) {
											return (
												<div
													className="m-chat-entry__image-wrapper"
													key={index}
												>
													<img
														className="m-chat-entry__image"
														src={`${contentItem.imageUrl}`}
														alt={
															contentItem.imageTextDescription
														}
													/>
												</div>
											);
										} else if (
											contentItem.type === "image_bytes"
										) {
											return (
												<div
													className="m-chat-entry__image-wrapper"
													key={index}
												>
													<img
														className="m-chat-entry__image"
														src={`data:image/jpeg;base64,${contentItem.imageBytes}`}
														alt={
															contentItem.imageTextDescription
														}
													/>
												</div>
											);
										} else if (
											contentItem.type === "html"
										) {
											return (
												<div
													dangerouslySetInnerHTML={{
														__html: contentItem.html,
													}}
													key={index}
												/>
												// contentItem.html
											);
										} else {
											return <></>;
										}
									})
							}
						</div>
					)}
					<div className="m-chat-entry__actions">
						<div className="row">
							<div className="col-md-6 h-vert-center-col">
								{statusMessage && (
									<p className="m-chat-entry__status-msg">
										{statusMessage}
									</p>
								)}
							</div>
							<div className="col-md-6">
								{isLoading ? (
									<div className="m-icon-row m-icon-row--right-align m-icon-row--condensed">
										{/* one placeholder for each action mapped to the type of entry */}
										{chatEntryActions.map(
											(action, index) => (
												<div
													key={index}
													className="a-skeleton a-skeleton--link-icon m-icon-row__icon"
												></div>
											)
										)}
									</div>
								) : (
									<div className="m-icon-row m-icon-row--right-align m-icon-row--condensed">
										{chatEntryActions.map(
											(action, index) => (
												<ChatEntryAction
													key={index}
													index={index}
													{...action}
													messageId={messageId}
													messagePairId={
														messagePairId
													}
													// TODO, include image description?
													message={content
														.filter(
															(contentItem) =>
																contentItem.type ===
																"text"
														)
														.sort(
															(a, b) =>
																a.contentOrder -
																b.contentOrder
														)
														.map(
															(contentItem) =>
																contentItem.text
														)
														.join("\n")}
												/>
											)
										)}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default ChatEntry;
