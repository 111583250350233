import { useState } from "react";
import { Heading, Button } from "cai-fusion";
import FileDisplay from "../components/symphonyFileDisplay";
import DnDFileUpload from "../../fusion/FileUpload/DragAndDrop";

import { useTemplate } from "../contexts/TemplateContext";

const AnalyzeRFP = () => {
	const analyzeRFPprompt = [
		"Assume that the attached files are all part of an RFP.  Describe the proposal schedule or timeline.  What is the contract term?",
		"What are the primary objectives of the client?",
		"What is the intended scope of work?",
		"Is a COTS or pre-built solution or application required?",
        "What are the mandatory or minimum requirements for this project?",
		"What experience is the company expected to possess?",
        "What are the deliverables?",
        "Are we required to name the Key Personnel for this project?",
        "Is there an onsite requirement?",
        "What are the evaluation criteria for this project?",
        "How many references are required?",
        "What are the liability and indemnification requirements?",
        "Are we required to provide SOC, HITRUST, FedRAMP, StateRAMP, or International Standards Organization (ISO) certificates or audits in our proposal?"
	]

    const { isRunning, executeTemplate } = useTemplate();
	const [files, setFiles] = useState([]);

	const deleteFiles = (fileInd) => {
        let filesCopy = [...files];
        filesCopy = [...filesCopy.slice(0, fileInd), ...filesCopy.slice(fileInd+1)]
        setFiles([...filesCopy]);
    }

    const handleUploads = async () => {
		executeTemplate(files, [...analyzeRFPprompt]);
    }
	const handleFileUpload = async (newFiles) => {
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);
        console.log("[TEMPLATE] Added files. New file list:", ([...files], [...newFiles]));
    }

	return (
		<>
			<Heading size={3}>Let's get started!</Heading>
			<p className="a-muted-text">
				To begin analyzing this RFP, just upload your RFP document in
				the file drop and hit that Send Message button!
				<br />
				Do not be alarmed, this template asks multiple predefined
				questions in a row. Leave the chat open to complete the
				automation.
			</p>
			<form action="" className="o-nl-form l-mt-sm">
				<FileDisplay filesOverride={files} deleteOverride={deleteFiles}/>
				<div style={{ marginBottom: "20px" }}>
					<DnDFileUpload
						/*To-Do this doesn't allow for viewing the data uploaded*/
						notAllowedFileTypes={['.exe', '.bat', '.cmd', '.scr', '.com', '.ps1', 
							'.sh', '.vbs', '.msi', '.dll', '.apk', '.appx', '.crx', 
							'.deb', '.rpm', '.doc', '.mp3', '.wav', '.m4a', '.mp4', '.avi', '.mov', '.wmv', '.webm',
							'.zip', '.gif', '.7z', '.zip', '.rar', 'lnk']}
						overwriteHandleFiles={handleFileUpload}
					/>
				</div>
				<div className="m-button-row">
					<Button onClick={handleUploads} disabled={files.length === 0 || isRunning}>
						Send Message
					</Button>
				</div>
			</form>
		</>
	);
};

export default AnalyzeRFP;