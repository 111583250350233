import { createContext, useContext, useRef, useState } from 'react';

const ScrollContext = createContext();

const ScrollProvider = ({ children }) => {
  const refs = {
    start: useRef(null),
    end: useRef(null),
  };

  const [showScrollDown, setShowScrollDown] = useState(false);
  const [showScrollUp, setShowScrollUp] = useState(false);

  const doScroll = (scrollMargin = 0, position = 'end') => {
    const targetRef = refs[position]?.current;

    if (!targetRef) return;

    const { top } = targetRef.getBoundingClientRect();
    const newScrollSpot = window.scrollY + top - scrollMargin;
    
    window.scrollTo({
      top: newScrollSpot,
      behavior: 'smooth',
    });
  };

  return (
    <ScrollContext.Provider value={{ doScroll, refs, showScrollDown, setShowScrollDown, showScrollUp, setShowScrollUp }}>
      {children}
    </ScrollContext.Provider>
  );
};

function useScroll() {
  const context = useContext(ScrollContext);
  if (context === undefined) {
    throw new Error("useScroll must be used within a ScrollProvider");
  }
  return context;
}

export { ScrollProvider, useScroll };