import { useEffect, useState } from "react";
import { Button, Modal } from "cai-fusion";

const RenameDataStoreItemModal = ({ isHidden, onClose, clickOutsideToClose, fileItem, onSubmit }) => {

	const [name, setName] = useState("");

	useEffect(() => {
		if(!isHidden){
			setName(fileItem?.resourceName);
		}
	}, [isHidden])

	const handleKeyPress = (event) => {
		if (event.key === "Enter") {
			if (!event.shiftKey) {
				event.preventDefault();
				onSubmit(fileItem, name);
				onClose();
				setName("");
			}
		}
	};

	return (
		<Modal
			isHidden={isHidden}
			id="rename-modal"
			clickOutsideToClose={clickOutsideToClose}
			title="Rename Chat"
			onClose={(e) => {
				onClose();
				e.preventDefault();
			}}
			actions={
				<>
					<Button outline onClick={(e) => { e.preventDefault(); setName(""); onClose(); }} children="Cancel"/>
					<Button onClick={(e) => { e.preventDefault(); onSubmit(fileItem, name); setName(""); onClose(); }} children="Save"/>
				</>
			}
		>
			<div className="l-row">
				<div className="l-one-col">
					<p>You are currently renaming <strong>{fileItem?.resourceName ?? "a file."}</strong>.</p>
					<form action="" className="o-form">
						<div className="m-form-group l-mt-sm">
							<label htmlFor="" className="a-label">New File Name</label>
							<input type="text" value={name} onKeyDown={(e) => handleKeyPress(e)} onChange={(e) => { setName(e.target.value); e.preventDefault(); }} className="a-text-input"/>
						</div>
					</form>
				</div>
			</div>
		</Modal>
	);
};

export default RenameDataStoreItemModal;