import { createContext, useState, useContext, useEffect, useRef } from "react";
import { useSymphonyApiService } from "../../../hooks/useSymphonyApi";
import { useChat } from "./ChatContext";
import { useUserProfile } from "./UserProfileContext";
import { ToastContext } from "cai-fusion";

const AccessContext = createContext();

function AccessProvider({ children }) {
    
	const apiServiceClient = useSymphonyApiService("v2");

    // referenced context components
    const { chat, chatId } = useChat();
    const { userProfile } = useUserProfile();    
    const { createErrorToast } = useContext(ToastContext);

    // data
    const [chatMembers, setChatMembers] = useState([]);
    const [canMakeContributor, setCanMakeContributor] = useState((process.env.REACT_APP_USERS_W_ADD_CONTRIBUTOR ?? "").split(',').map(u => u.toLowerCase()).filter(x => x!== ""));
    const [membershipLevels, setMembershipLevels] = useState({
        3: "View Only"

    })
    const [limitSharing, setLimitSharing] = useState([]);

    const [chatMembershipLevels, setChatMembershipLevels] = useState({
        "Owner": 0,
        "Manager": 1,
        "Contribute": 2,
        "View Only": 3
    })

    // statuses
    const [userChatMembership, setUserChatMembership] = useState(0);
    const [canEditSettings, setCanEditSettings] = useState(true);
    const [canSendMessage, setCanSendMessage] = useState(true);
    const [canShareChat, setCanShareChat] = useState(true);

    // refs for signalR
    const chatMembersRef = useRef();

    

    // Get the chat members of the current chat.
    const getChatMembers = async () => {
        try {
            let members = chat?.chatUserAccesses;
            console.log("[ACCESS] Get chat members: ", members);
            if (!members){
                setChatMembers([]);
            }
            else {
                setChatMembers(members);
            }

            const membership = members.find(
                (x) => x.userProfileId === userProfile?.userProfileId
            );

            if(membership) {
                setUserChatMembership(membership.membership);
            }
            else {
                //It should never be possible to get here, since the backend won't return the chat if the user doesn't have access, but including for security sake
                console.error("User does not have access to this chat.");
            }
        }
        catch(error){
            console.error("Error getting chat members", error);
            // possible leaveChat
        }
        finally {
            return chatMembers;
        }
        
    }

    // Check if a user is a member of the current chat
    const isMember = async (user) => {
        console.log("[SHARECHAT] " + user.identifier + " being checked for membership");
        await getChatMembers();
        const membership = chatMembers.find(
            (x) => x.userProfileId === user?.userProfileId
        );
        return membership;
    }

    // Search available users to add to the chat.
    const searchUsers = async (query) => {
        return await apiServiceClient.UserProfiles.searchUsers(query);
    }

    // Updates the chat access to any added or removed users.
    const shareChat = async (user, membership) => {
        if (!chatId){
            console.error("[SHARECHAT] Unable to get chat id");
            return;
        }

        if (user) {
            try {
                console.log("[SHARECHAT] Sharing chat with a user.");
                let resp = await apiServiceClient.Chats.shareChat(chatId, undefined, user.identifier, membership);
                setChatMembers([...chatMembers, resp]);
            } catch (e) {
                if (e.response && e.response.status === 400 && e.response.data && e.response.data.error) {
                    createErrorToast(e.response.data.error);
                } else {
                    createErrorToast("Unable to Share Chat");
                }
            }
        }
    }

    const unshareChat = async (user) => {
        if(!chatId){
            console.error("[SHARECHAT] Unable to get chat id");
            return;
        }
        if(user){
            console.log("[SHARECHAT] Unsharing chat with a user.")
            let resp = await apiServiceClient.Chats.unshareChat(chatId, user.userProfileId);
            setChatMembers(chatMembers.filter(x => x.userProfile.identifier != user.userProfile.identifier));
        } 
    }

    // update access level for user in a chat
    const updateChatAccess = async (user, accessLevel) => {
        console.log("[SHARECHAT] updating chat access of " + user.identifier + " to " + accessLevel);
        if(user){
            let ind = chatMembers.findIndex(
                (x) => x.userProfileId === user?.userProfileId
            );
            const newChatMembers = [...chatMembers];
            newChatMembers[ind].membership = accessLevel;
            setChatMembers(newChatMembers);
            await apiServiceClient.Chats.updateChatAccess(chatId, accessLevel, user.userProfileId, user.identifier);       
        }
    }


    useEffect(() => {
        if(canMakeContributor.length === 0 || canMakeContributor.includes(userProfile?.identifier?.toLowerCase())){
            setMembershipLevels({
                3: "View Only",
                2: "Contribute"
            })
        }

    }, [userProfile, canMakeContributor])

    useEffect(() => {
        // everything readers can do/default
        if(userChatMembership <= 3){
            setCanEditSettings(false);
            setCanSendMessage(false);
        }
        // everything contributors can do
        if(userChatMembership <= 2){
            setCanSendMessage(true);
        }
        // everything managers can do
        if(userChatMembership <= 1){

        }
        // everything owners can do
        if(userChatMembership === 0){
            setCanEditSettings(true);
        }
    }, [userChatMembership])

    useEffect(() => {
        if (userChatMembership === 0 && (limitSharing.length === 0 || limitSharing.includes(userProfile?.identifier))) {
            setCanShareChat(true);
        } else if(canShareChat) {
            setCanShareChat(false);
        }
    }, [userProfile, userChatMembership, limitSharing])

    useEffect(() => {
        if (chat && userProfile) getChatMembers();
    }, [chat, userProfile])

    useEffect(() => {
        chatMembersRef.current = chatMembers;
    }, [chatMembers]);

	return (
		<AccessContext.Provider
			value={{
                // States
				chatMembers,
                setChatMembers,
                canMakeContributor,
                canEditSettings,
                canSendMessage,
                canShareChat,
                membershipLevels,

                // Functions
                isMember,
                getChatMembers,
                searchUsers,
                shareChat,
                unshareChat,
                updateChatAccess
			}}
		>
			{children}
		</AccessContext.Provider>
	);
}

// Hook to use the AccessContext in a component
function useAccess() {
	const context = useContext(AccessContext);
	if (context === undefined) {
		throw new Error(
			"useAccess must be used within a AccessProvider"
		);
	}
	return context;
}

export { AccessProvider, useAccess };
