import React, { createContext, useContext, useEffect, useState } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import {
	getGraphUserData,
	getMsGraphPhotoUrl,
	getMsGraphUserPhotoUrl,
	callMsGraphUsers,
	getMsGraphGroups,
} from "../services/graph.js";
import { apiRequest, loginRequest } from "../configs/authConfig";
import { InteractionRequiredAuthError, Logger } from "@azure/msal-browser";

// Create a context for authentication data to be consumed in the app
const AuthContext = createContext();

// Initialize a logger instance from MSAL
const logger = new Logger((logLevel, message, containsPii) => {
	if (containsPii) {
		// return;
		console.log(`[${new Date().toISOString()}][${logLevel}] ${message}`);
	}
	switch (logLevel) {
		case 0:
			console.error(message);
			return;
		case 1:
			console.warn(message);
			return;
		case 2:
			console.info(message);
			return;
		case 3:
			console.log(message);
			return;
		default:
			return;
	}
});

// AuthProvider component manages authentication state and provides it to the rest of the app
export const AuthProvider = ({ children }) => {
	// Hook provided by MSAL to check if the user is authenticated
	const isAuthenticated = useIsAuthenticated();

	// MSAL hook providing authentication instance and account information
	const { instance, accounts, inProgress } = useMsal();

	// State variables to store access tokens, user data, and other relevant info
	const [graphAccessToken, setGraphAccessToken] = useState(null);
	const [apiAccessToken, setApiAccessToken] = useState(null);
	const [graphData, setGraphData] = useState(null);
	const [imgUrl, setImgUrl] = useState(null);
	const [impersonating, setImpersonating] = useState(false);
	const [anonymousIdentifier, setAnonymousIdentifier] = useState("");
	const [error, setError] = useState(null);

	// Function to attempt to acquire a token silently or interactively if silent acquisition fails
	const acquireToken = async (requestConfig, account) => {
		try {
			// Attempt to acquire token silently
			const response = await instance.acquireTokenSilent({
				...requestConfig,
				account,
				forceRefresh: false,
			});
			return response.accessToken;
		} catch (error) {
			// Silent token acquisition failed, likely due to a required user interaction
			if (error instanceof InteractionRequiredAuthError) {
				try {
					// Fallback to an interactive method
					const response = await instance.acquireTokenRedirect(
						requestConfig
					);
					return response.accessToken;
				} catch (interactiveError) {
					// Handle errors during interactive token acquisition
					setError(interactiveError);
					logger.error(
						"Interactive token acquisition error",
						interactiveError
					);
					return null;
				}
			} else {
				// Handle other types of errors during token acquisition
				setError(error);
				logger.error("Silent token acquisition error", error);
				return null;
			}
		}
	};

	// Function that handles user login
	const login = async () => {
		if (accounts.length > 0) {
			// Attempt to get a token if there are accounts available
			const token = await acquireToken(loginRequest, accounts[0]);
			// Only update the graph access token state if we received a token
			token
				? setGraphAccessToken(token)
				: setError("Failed to acquire login token");
		} else {
			try {
				// Initiate login redirect if there are no accounts
				await instance.loginRedirect(loginRequest);
			} catch (loginError) {
				// Handle errors during login redirect
				setError(loginError);
				logger.error("Login redirect error", loginError);
			}
		}
	};

	// Effect hook to retrieve Graph API token and user data when the user is authenticated
	useEffect(() => {
		if (isAuthenticated && accounts.length > 0) {
			(async () => {
				const token = await acquireToken(loginRequest, accounts[0]);
				if (token) {
					setGraphAccessToken(token);
					try {
						// Attempt to fetch user data with the graph access token
						const userData = await getGraphUserData(token);
						setGraphData(userData);
					} catch (userDataError) {
						// Handle errors during user data retrieval
						setError(userDataError);
						logger.error(
							"User data retrieval error",
							userDataError
						);
					}
				}
			})();
		}
	}, [isAuthenticated, accounts]);

	// Effect hook to retrieve API token when the graph access token is available
	useEffect(() => {
		if (graphAccessToken && accounts.length > 0) {
			(async () => {
				// Attempt to fetch API token
				const token = await acquireToken(apiRequest, accounts[0]);
				// Only update the API access token state if we received a token
				token
					? setApiAccessToken(token)
					: setError("Failed to acquire API token");
			})();
		}
	}, [graphAccessToken, accounts]);

	// Effect hook to refresh tokens periodically to avoid expiration
	useEffect(() => {
		const interval = setInterval(() => {
			if (isAuthenticated && accounts.length > 0) {
				// Refresh tokens periodically
				acquireToken(loginRequest, accounts[0])
					.then(setGraphAccessToken)
					.catch((refreshError) => {
						// Handle errors during token refresh
						setError(refreshError);
						logger.error("Token refresh error", refreshError);
					});
				acquireToken(apiRequest, accounts[0])
					.then(setApiAccessToken)
					.catch((refreshError) => {
						// Handle errors during token refresh
						setError(refreshError);
						logger.error("Token refresh error", refreshError);
					});
			}
		}, 1000 * 60 * 60); // Refresh every hour as recommended for SPAs

		// Clear interval on component unmount
		return () => clearInterval(interval);
	}, [isAuthenticated, accounts]);

	// Function to handle user logout
	const logOut = () => {
		instance
			.logoutRedirect({ postLogoutRedirectUri: "/" })
			.catch((logoutError) => {
				// Handle errors during logout redirect
				setError(logoutError);
				logger.error("Logout redirect error", logoutError);
			});
	};

	// Effect hook to set the user's profile photo
	useEffect(() => {
		if (graphAccessToken) {
			(async () => {
				try {
					// Attempt to get the user photo URL
					const photoUrl = impersonating
						? await getMsGraphUserPhotoUrl(
								graphAccessToken,
								impersonating.userPrincipalName
						  )
						: await getMsGraphPhotoUrl(graphAccessToken);
					const url = window.URL || window.webkitURL;
					const blobUrl = url.createObjectURL(photoUrl);
					setImgUrl(blobUrl);
				} catch (photoError) {
					// Handle errors during photo retrieval
					logger.error("Error getting photo", photoError);
					// Fallback to a default avatar if user data is available
					if (impersonating) {
						setImgUrl(
							"https://ui-avatars.com/api/?name=" +
								impersonating.displayName
						);
					} else if (graphData) {
						setImgUrl(
							`https://ui-avatars.com/api/?name=${encodeURIComponent(
								graphData.displayName
							)}`
						);
					}
				}
			})();
		}
	}, [graphAccessToken, graphData, impersonating]);

	// Generate an anonymous identifier from the user's principal name and a salt
	const createAnonymousIdentifier = (input, salt) => {
		const saltedInput = input + salt;
		let hash = 0;

		for (let i = 0; i < saltedInput.length; i++) {
			const character = saltedInput.charCodeAt(i);
			hash = (hash << 5) - hash + character;
			hash |= 0; // Convert to 32bit integer
		}

		// Convert the integer to a hexadecimal string
		const hexString = Math.abs(hash).toString(16);
		return hexString;
	};

	// Effect hook to create an anonymous identifier after graph data is fetched
	useEffect(() => {
		if (graphData) {
			const salt =
				process.env.REACT_APP_AZURE_CLIENT_ID || "default_salt";
			const identifier = createAnonymousIdentifier(
				graphData.userPrincipalName,
				salt.replace("-", "")
			);
			logger.info(`Anonymous Identifier: ${identifier}`);
			setAnonymousIdentifier(identifier);
		}
	}, [graphData]);

	// Function to refresh the access token using the acquireToken logic
	const refreshAccessToken = async () => {
		// Ensure there is an account for which to refresh the token
		if (accounts.length === 0) {
			throw new Error("No accounts available for refreshing token.");
		}

		try {
			// Attempt to get a new token
			const newAccessToken = await acquireToken(apiRequest, accounts[0]);
			if (newAccessToken) {
				setApiAccessToken(newAccessToken); // Update the state with the new token
				return newAccessToken; // Return the new token
			} else {
				throw new Error("Failed to acquire new access token.");
			}
		} catch (error) {
			logger.error("Token refresh error:", error);
			throw error; // Rethrow the error for the caller to handle
		}
	};

	// Effect hook to initiate login when the component mounts
	useEffect(() => {
		login();
	}, []); // Empty dependency array ensures this runs once on component mount

	return (
		<AuthContext.Provider
			value={{
				isAuthenticated,
				login,
				logOut,
				graphData,
				impersonating,
				setImpersonating,
				imgUrl,
				graphAccessToken,
				apiAccessToken,
				refreshAccessToken,
				anonymousIdentifier,
				error,
			}}
		>
			{ apiAccessToken && children }
		</AuthContext.Provider>
	);
};

export const useAuthentication = () => {
	return useContext(AuthContext);
};
