import React, { useEffect, useState } from 'react';
import { useScroll } from '../contexts/ScrollContext';

const ScrollHandler = ({ children, scrollMargin = 0 }) => {
  const { refs, doScroll, setShowScrollDown, setShowScrollUp } = useScroll();
  const [previousChildrenLength, setPreviousChildrenLength] = useState(0);

  useEffect(() => {
    const currentChildrenLength = React.Children.count(children);

    if (currentChildrenLength > previousChildrenLength) {
      doScroll(scrollMargin, 'end'); 
    }

    setPreviousChildrenLength(currentChildrenLength);
  }, [children]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPosition = window.scrollY;
      const startRefPosition = refs.start?.current?.getBoundingClientRect()?.top + window.scrollY;
      const endRefPosition = refs.end?.current?.getBoundingClientRect()?.top + window.scrollY - window.innerHeight;

      if (currentScrollPosition >= endRefPosition) {
        setShowScrollUp(true);
        setShowScrollDown(false);
      } else if (currentScrollPosition <= startRefPosition + scrollMargin) {
        setShowScrollUp(false);
        setShowScrollDown(true);
      } else if (currentScrollPosition > endRefPosition - scrollMargin) {
        setShowScrollUp(true);
        setShowScrollDown(false);
      } else {
        setShowScrollDown(true);
        setShowScrollUp(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [refs, scrollMargin]);

  return (
    <div>
      {React.Children.map(children, (child, index) => {
        if (index === 0) return <div ref={refs.start}>{child}</div>;
        if (index === React.Children.count(children) - 1) return <div ref={refs.end}>{child}</div>;
        return child;
      })}
    </div>
  );
};

export default ScrollHandler;