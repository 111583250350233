import { useState, useRef, useEffect } from "react";
import { Heading, Button } from "cai-fusion";
import FileDisplay from "../components/symphonyFileDisplay";
import DnDFileUpload from "../../fusion/FileUpload/DragAndDrop";

import { useTemplate } from "../contexts/TemplateContext";
import { appendixFile } from "./resources/dehssresources";

const IncomeStatement = () => {
    const textWidthElement = useRef(null);
    const [files, setFiles] = useState([]);

    const { isRunning, executeTemplate } = useTemplate();

    // These two functions are what I needed to do to not immediately navigate when uploading a file.
    const deleteFiles = (fileInd) => {
        let filesCopy = [...files];
        filesCopy = [...filesCopy.slice(0, fileInd), ...filesCopy.slice(fileInd + 1)]
        setFiles([...filesCopy]);
    }

    const handleFileUpload = async (newFiles) => {
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);
        console.log("[TEMPLATE] Added files. New file list:", ([...files], [...newFiles]));
    }

    const appendTimestampToFileName = (fileName) => {
        const timestamp = new Date()
            .toISOString()
            .replace(/[:\-]/g, "")
            .replace(/\..*$/, "");
        const nameWithoutExt = fileName.replace(/\.[^/.]+$/, "");
        const ext = fileName.split(".").pop();
        return `${nameWithoutExt}_${timestamp}.${ext}`;
    };

    const handleFiles = (pastedFiles) => {
        const fileObjects = pastedFiles.map((file) => {
            return new Promise((resolve, reject) => {
                var reader = new FileReader();
                reader.onload = (e) => {
                    let fileName = file.name.toLowerCase();
                    if (
                        fileName === "image.png" ||
                        fileName === "image.jpg" ||
                        fileName === "image.jpeg"
                    ) {
                        fileName = appendTimestampToFileName(file.name);
                    }
                    resolve({
                        resourceName: fileName,
                        content: e.currentTarget.result,
                        uploading: true, 
                        type: file.type
                    });
                };
                reader.onerror = () => {
                    reject(new Error(`Failed to read file ${file.name}.`));
                };
                reader.readAsDataURL(file);
            });
        });

        Promise.all(fileObjects).then((objs) => handleFileUpload(objs));
    }

    const handlePaste = (event) => {
        const clipboardItems = event.clipboardData.items;
        const files = [];
        for (const item of clipboardItems) {
            if (item.kind === 'file') {
                const file = item.getAsFile();
                if (file) {
                    files.push(file);
                }
            }
        }
        if (files.length > 0) {
            handleFiles(files);
        }
    }

    useEffect(() => {
        document.addEventListener('paste', handlePaste);
        return () => {
            document.removeEventListener('paste', handlePaste);
        };
    }, []);

    // Function called when the "Send Message" button is hit.
    const handleSubmit = async () => {
        let overridePrompt = `
I have attached an Income Statement or Ledger Records that I need to extract specific details from.
Please extract and display the following information:
    - Name
    - Employer Name
    - Employer Address
    - Employment Type (if provided)
    - Number of months (if provided)
    - Monthly hours (if provided)
    - Gross Income Amount Total
    - Individual Expenses and Amounts
    - Total Expenses Amount`;
let prompt = `
${overridePrompt}

For Individual Expenses and Amounts, each expense category should be totaled for each month provided, but list out the source data for the summation as well.
For example, if there are 3 months of info provided as truck expenses for Sept are $50, truck expenses for Oct are $30, truck expenses for Nov are $20- that means total truck expenses = $100.

If there is more than one, please do it for each.
Please show your work, your thought process, and any assumptions made in the process. So that a human auditor could follow your work and verify the results. Thank you!`;
        executeTemplate([...files, appendixFile], [prompt], [overridePrompt]);
    }

    return (
        <>
            <Heading size={3}>Let's get started!</Heading>
            <p className="a-muted-text">
                To begin the Income Statement/Ledger Calculation, please drag and drop or upload an image of the income statement or ledger record below. We’ll walk you through the rest!
            </p>
            <form action="" className="o-nl-form l-mt-sm">
                {/* <p className="o-nl-form__text">
                    1. Login to ASSIST WW to access application information.
                </p>
                <p className="o-nl-form__text">
                    2. Login to Outlook.
                </p>
                <p className="o-nl-form__text">
                    3. Login to OnBase.
                </p>
                <p className="o-nl-form__text">
                    4. Upload or drag image here.
                </p> */}
                <FileDisplay filesOverride={files} deleteOverride={deleteFiles} />
                <div style={{ marginBottom: "20px" }}>
                    <DnDFileUpload
                        notAllowedFileTypes={['.exe', '.bat', '.cmd', '.scr', '.com', '.ps1',
                            '.sh', '.vbs', '.msi', '.dll', '.apk', '.appx', '.crx',
                            '.deb', '.rpm', '.doc', '.mp3', '.wav', '.m4a', '.mp4', '.avi', '.mov', '.wmv', '.webm',
                            '.zip', '.gif', '.7z', '.zip', '.rar', 'lnk']}
                        overwriteHandleFiles={handleFileUpload}
                        overwriteDisplayText={"Paste, Drag & Drop, or Click Here to Upload Files"}
                    />
                </div>
                <div className="m-button-row">
                    <Button onClick={handleSubmit} disabled={files.length === 0 || isRunning}>
                        Calculate
                    </Button>
                </div>
            </form>
            <span id="text-width" ref={textWidthElement}>
                Error Message or Unexpected Result And More Length
            </span>
        </>
    );
};

export default IncomeStatement;