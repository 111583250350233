
// Symphony-based contexts
import { AccessProvider } from "../components/symphony/contexts/AccessContext";
import { AssistantProvider } from "../components/symphony/contexts/AssistantContext";
import { ComplianceProvider } from "../components/symphony/contexts/ComplianceContext";
import { ChatProvider } from "../components/symphony/contexts/ChatContext";
import { ChatHistoryProvider } from "../components/symphony/contexts/ChatHistoryContext";
import { DataStoreProvider } from "../components/symphony/contexts/DataStoreContext";
import { FactsProvider } from "../components/symphony/contexts/FactsContext";
import { LicensingProvider } from "../components/symphony/contexts/LicenseContext";
import { MessagesProvider } from "../components/symphony/contexts/MessageContext";
import { ModelsProvider } from "../components/symphony/contexts/ModelsContext";
import { ProfilePictureProvider } from "../components/symphony/contexts/ProfilePictureContext";
import { ResourceProvider } from "../components/symphony/contexts/ResourceContext";
import { SettingsProvider } from "../components/symphony/contexts/SettingsContext";
import { SkillsProvider } from "../components/symphony/contexts/SkillsContext";
import { SignalRProvider } from "../components/symphony/contexts/SignalRContext";
import { TemplateProvider } from "../components/symphony/contexts/TemplateContext";
import { TypingProvider } from "../components/symphony/contexts/TypingContext";
import { UserProfileProvider } from "../components/symphony/contexts/UserProfileContext";
import { ScrollProvider } from "../components/symphony/contexts/ScrollContext";

// Global contexts
import { SettingContextWrapper } from "../contexts/SettingsContext";

// CAI-Fusion contexts
import { IconProvider } from "cai-fusion";
import { ToastProvider } from "cai-fusion";

// Configuration data
import { symphonySVGs } from "../configs/iconConfig"; // for IconProvider

export const symphonyAppProviders = [
  ScrollProvider,
  ChatProvider,
  AccessProvider,
  SettingsProvider,
  AssistantProvider,
  SkillsProvider,
  SignalRProvider,
  ResourceProvider,
  ProfilePictureProvider,
  MessagesProvider,
  TypingProvider,
  TemplateProvider,
];


//Create list of provider layers to be used in components by means of a single key
export const symphonyProviders = [
  [IconProvider, { svgs: symphonySVGs }],
  ToastProvider,
  UserProfileProvider,
  ChatHistoryProvider,
  DataStoreProvider,
  LicensingProvider,
  ComplianceProvider,
  ChatProvider
];

export const mainProviders = [
  UserProfileProvider,
  ChatHistoryProvider,
  LicensingProvider,
  ComplianceProvider,
  [IconProvider, { svgs: symphonySVGs }],
  ToastProvider,
];

export const adminSettingsProviders = [
  [IconProvider, { svgs: symphonySVGs }],
  ToastProvider,
  SettingContextWrapper,
  FactsProvider,
  ModelsProvider
];

export const settingsProviders = [
  UserProfileProvider,
  [IconProvider, { svgs: symphonySVGs }],
  ToastProvider,
  SettingContextWrapper
];

// export const govForms = [
//   UserProfileProvider,
//   [IconProvider, { svgs: symphonySVGs }],
//   ToastProvider,
// ];

export const formsProviders = [
  UserProfileProvider,
  [IconProvider, { svgs: symphonySVGs }],
  ToastProvider,
];


const ProviderFactory = ({ providers, children }) => {
  // Recursively nest each provider using reduceRight to wrap them in the correct order
  const providedChildren = providers.reduceRight((kids, Parent) => {
    // If the Parent is an array, it means it has the format [Provider, props]
    if (Array.isArray(Parent)) {
      const [Provider, props] = Parent;
      return <Provider {...props}>{kids}</Provider>;
    }
    // If it's not an array, it's a provider component without additional props
    return <Parent>{kids}</Parent>;
  }, children);

  return <>{providedChildren}</>;
};

export default ProviderFactory;