import { useContext, useEffect, useState } from "react";
import { ChatInput } from "../../fusion/Chat";
import { useParams } from "react-router-dom";
import { ReactComponent as IconTemplate } from "../../../images/icon-template.svg";
import { ReactComponent as IconExport } from "../../../images/icon-download.svg";
import { ReactComponent as IconShare } from "../../../images/icon-share.svg";
import { ReactComponent as IconPinned } from "../../../images/icon-pinned.svg";
import { ReactComponent as IconRename } from "../../../images/icon-rename.svg";
import { ReactComponent as IconTrash } from "../../../images/icon-trash.svg";
import { ReactComponent as IconSharedChat } from "../../../images/icon-shared-chat.svg";

import { useChat } from "../contexts/ChatContext";
import { useMessages } from "../contexts/MessageContext";
import { useAccess } from "../contexts/AccessContext";
import { useResource } from "../contexts/ResourceContext"; // Import the resource context

import { ToastContext } from "cai-fusion";

const SymphonyChatInput = ({
	onModifySettings,
	onManageDataSources,
	onDeleteChat,
	onAttach,
	disableInput,
	onRenameChat,
	onShareChat,
	onExportChat,
}) => {
	const params = useParams();
	const { createWarningToast } = useContext(ToastContext);
	const { isChatInContext, togglePinChat, isPinned, assistantThinking } =
		useChat();
	const { chatMembers, canShareChat } = useAccess();
	const { sendMessage } = useMessages();
	const { handleFilesUpload } = useResource(); // Use the resource context

	const [contextMenuShown, setContextMenuShown] = useState(false);

	//
	//const { userProfile } = useContext(UserProfileContext);
	//const [canShareChat, setCanShareChat] = useState(false);

	//useEffect(() => {
	//    setCanShareChat(whoCanShareChats.includes(userProfile?.identifier));
	//}, [userProfile])

	useEffect(() => {
		setContextMenuShown(false);
	}, [params.ChatId]);

	const handlePaste = (event) => {
		const items = event.clipboardData.items;
		const files = [];
		for (let i = 0; i < items.length; i++) {
			const item = items[i];
			if (item.kind === "file") {
				const file = item.getAsFile();
				files.push(file);
			}
		}
		if (files.length > 0) {
			handleFilePaste(files); // Handle the file upload
			event.preventDefault(); // Prevent the default paste behavior
		}
	};

	const notAllowedFileTypes = [
		".exe",
		".bat",
		".cmd",
		".scr",
		".com",
		".ps1",
		".sh",
		".vbs",
		".msi",
		".dll",
		".apk",
		".appx",
		".crx",
		".deb",
		".rpm",
		".doc",
		".mp3",
		".wav",
		".m4a",
		".mp4",
		".avi",
		".mov",
		".wmv",
		".webm",
		".zip",
		".gif",
		".7z",
		".zip",
		".rar",
		"lnk",
	];

	const handleFilePaste = (files) => {
		const notAllowedFiles = [];
		const allowedFiles = [...files].filter((file) => {
			const notAllowed = notAllowedFileTypes.some((ext) =>
				file.name.toLowerCase().endsWith(ext)
			);

			if (notAllowed) {
				notAllowedFiles.push(file.name);
				return false;
			}
			return true;
		});

		const appendTimestampToFileName = (fileName) => {
			const timestamp = new Date()
				.toISOString()
				.replace(/[:\-]/g, "")
				.replace(/\..*$/, "");
			const nameWithoutExt = fileName.replace(/\.[^/.]+$/, "");
			const ext = fileName.split(".").pop();
			return `${nameWithoutExt}_${timestamp}.${ext}`;
		};

		let fileObjects = allowedFiles.map((file) => {
			return new Promise((resolve, reject) => {
				var reader = new FileReader();
				reader.onload = (e) => {
					let fileName = file.name.toLowerCase();
					if (
						fileName === "image.png" ||
						fileName === "image.jpg" ||
						fileName === "image.jpeg"
					) {
						fileName = appendTimestampToFileName(file.name);
					}
					resolve({
						resourceName: fileName,
						content: e.currentTarget.result,
						//pendingUpload: true,
						uploading: true,
						type: file.type,
					});
				};
				reader.onerror = () => {
					reject(new Error(`Failed to read file ${file.name}.`));
				};
				reader.readAsDataURL(file);
			});
		});

		Promise.all(fileObjects).then((objs) => handleFilesUpload(objs));
		if (notAllowedFiles.length > 0) {
			//setToastMessage("Sorry, these file types aren't not allowed, refer to my accepted file types and try to save them as that!\n" + notAllowedFiles.join('\n'));
			createWarningToast(
				<div>
					<h4> Unsupported File Type </h4>
					<p>
						Sorry I can't read that, try saving it as a different
						extension!
					</p>
					<ul>
						{notAllowedFiles.map((badFile, index) => (
							<li key={index}>
								<strong>File Name: </strong> {badFile}
								<br />
							</li>
						))}
					</ul>
				</div>
			);
		}
	};

	let chatActionsData = [
		{
			className: "m-context-menu__item",
			buttonClassName: `a-icon-link a-icon-link--block${
				isPinned ? " a-icon-link--active" : ""
			}`,
			text: isPinned ? "Unpin Chat" : "Pin Chat",
			IconElement: <IconPinned className="a-icon-link__icon" />,
			actionFunction: (e) => {
				e.preventDefault();
				togglePinChat();
			},
		},
		{
			className: "m-context-menu__item",
			buttonClassName: "a-icon-link a-icon-link--block",
			modalTarget: "rename-modal",
			text: "Rename Chat",
			IconElement: <IconRename className="a-icon-link__icon" />,
			actionFunction: (e) => {
				e.preventDefault();
				if (onRenameChat) onRenameChat();
			},
		},
		{
			className: "m-context-menu__item",
			buttonClassName: `a-icon-link a-icon-link--block ${
				!canShareChat ? "a-icon-link--disabled" : ""
			}`,
			text: "Share Chat",
			IconElement: <IconShare className="a-icon-link__icon" />,
			actionFunction: (e) => {
				e.preventDefault();
				if (onShareChat && canShareChat) onShareChat();
			},
		},
		// {
		// 	className: "m-context-menu__item",
		// 	buttonClassName:
		// 		"a-icon-link a-icon-link--block a-icon-link--disabled",
		// 	text: "Create Template from Chat",
		// 	helpText: "Coming Soon",
		// 	IconElement: <IconTemplate className="a-icon-link__icon" />,
		// 	actionFunction: (e) => {
		// 		e.preventDefault();
		// 	},
		// },
		{
			className: "m-context-menu__item",
			buttonClassName: `a-icon-link a-icon-link--block  a-icon-link--destructive ${
				assistantThinking ? "a-icon-link--disabled" : ""
			}`,
			text: "Delete Chat",
			IconElement: <IconTrash className="a-icon-link__icon" />,
			actionFunction: (e) => {
				onDeleteChat();
				e.preventDefault();
			},
		},
	];
	if (
		window.location.href.indexOf("mycai-dev") > -1 ||
		window.location.href.indexOf("localhost") > -1 ||
        window.location.href.indexOf("cai-dhss") > -1
	) {
		let exportChatActionData = {
			className: "m-context-menu__item",
			buttonClassName: `a-icon-link a-icon-link--block ${
				!canShareChat ? "a-icon-link--disabled" : ""
			}`,
			// helpText: "Coming Soon",
			text: "Export Chat",
			IconElement: <IconExport className="a-icon-link__icon" />,
			actionFunction: (e) => {
				e.preventDefault();
				onExportChat();
			},
		};
		// add it to the second to last position so it's before delete chat
		chatActionsData.splice(
			chatActionsData.length - 1,
			0,
			exportChatActionData
		);
	}

	return (
		<ChatInput
			onAttach={onAttach}
			onModifySettings={onModifySettings}
			onManageDataSources={onManageDataSources}
			onSendMessage={(message) => {
				sendMessage(message);
			}}
			disableInput={disableInput}
			showContextButton={!(params.ChatId === undefined)}
			contextMenuShown={contextMenuShown}
			setContextMenuShown={setContextMenuShown}
			chatActions={chatActionsData}
			preContextMenuComponents={
				isChatInContext() && chatMembers.length > 1 ? (
					<button
						className="a-tag a-tag--interactive"
						data-drawer-target="sharing-drawer"
						onClick={(e) => {
							e.preventDefault();
							onShareChat();
						}}
					>
						<IconSharedChat />
						<p className="a-tag__label">
							{chatMembers.length} people in this chat
						</p>
					</button>
				) : (
					<></>
				)
			}
			onPaste={handlePaste} // Pass the onPaste event handler to ChatInput
		/>
	);
};

export default SymphonyChatInput;
