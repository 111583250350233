import { Button, Drawer, Heading, HeadingRow, Timeline, TimelineItem } from "cai-fusion";
import { ReactComponent as IconCloseSquare } from "../../../images/close-square.svg";
import MDEditor from "@uiw/react-md-editor";
import { useMessages } from "../contexts/MessageContext";
import { useEffect, useState } from "react";

const EditTextNoteDrawer = ({ selectedNote, isHidden, onClose, onSubmit }) => {

    const [name, setName] = useState("");
    const [text, setText] = useState("");
    const [isEditing, setIsEditing] = useState();

    useEffect(() => {
        if(!isHidden){
            setIsEditing(!(!(selectedNote)));
            setName(selectedNote?.resourceName ?? "");
            setText(selectedNote?.text ?? "");
        }
    }, [isHidden])

    return (
        <Drawer
            clickOutsideToClose
            isHidden={isHidden}
            placement='right' 
            onClose={onClose}
            header={
                <HeadingRow
                    size={3}
                    title="Text Note"
                    eyebrow="Data Collection"
                >
                    <div className="m-icon-row m-icon-row--right-align">
                        <a
                            href="#"
                            id="drawerCloseBtn"
                            className="a-icon m-icon-row__icon"
                            title="Close Associate Details"
                            onClick={onClose}
                        >
                            <IconCloseSquare className="a-icon-link__icon">
                                {" "}
                            </IconCloseSquare>
                        </a>
                    </div>
                </HeadingRow>
            }
            actions={
                <>
                    <Button
                        children="Cancel"
                        outline
                        onClick={onClose}
                    />
                    <Button
                        children={isEditing ? "Save" : "Create"}
                        onClick={() => onSubmit(name, text, isEditing)}
                    />
                </>
            }
            id="folder-drawer"
            className="col-3 o-drawer--light-gray"
            children={
                <form className="o-form l-mt-sm">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
								<div className="col-12">
									<div className="m-form-group">
										<label htmlFor="docSelector" className="a-label">Note Title</label>
										<input 
                                            type="text" 
                                            className="a-text-input"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)} 
                                        />
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<div className="m-form-group">
										<label htmlFor="docSelector" className="a-label">Note Content</label>
										<textarea 
                                            className="a-textarea-input" 
                                            rows={15}
                                            value={text}
                                            onChange={(e) => setText(e.target.value)}
                                        />
									  </div>
								</div>
							</div>
                        </div>
                    </div>
                </form>
            }
        />
    )
}

export default EditTextNoteDrawer;