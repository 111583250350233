import { useState } from "react";
import { Heading, Button } from "cai-fusion";

import { useTemplate } from "../contexts/TemplateContext";

const ProofreadNDS = () => {
	const [messageText, setMessageText] = useState("");

    const { executeTemplate } = useTemplate();

	const handleInputChange = (e) => {
		setMessageText(e.target.value);
	};

	const handleKeyPress = (event) => {
		if (event.key === "Enter") {
			if (!event.shiftKey && messageText.trim()) {
				event.preventDefault();
				handleSend();
			}
		}
	};

	const handleSend = () => {
		// setMessageText("");
		// let promptText =
		// 	"Leveraging the following Neurodiverse Solutions Branding Guidelines, please review the following text and call out any terminology that is not used correctly.\n\n" +
		// 	"### Begin Guidelines\n" +
		// 	"If you detect any of the following prohibited words in the copy being reviewed, please call them out in your response them in your response.\n" +
		// 	"- NDS\n- Autism2Work\n- A2W\n- Computer Aid, Inc\n- Computer Aid Inc\n- Computer Aid\n- CompAid\n- Autism\n- Neurodiverse solutions\n- Neurodiverse Solution \n- Neurodiverse in the workplace\n- Neurodiverse at work\n- Neurodiverse employment program\n- Neurodiverse hiring\n- Neurodiverse individual\n- Neurodiverse individuals\n- Neurodiverse employee \n- Neurodiverse employees\n- Neurodiverse worker\n- Neurodiverse workers\n- Neurodiverse people\n- Neurodivergent talent\n- Neurodivergent workforce\n- Neurodivergent community\n- Neurodivergent team\n- Gift\n- Gifts\n- Resource\n- Resources\n- Functionality\n- Job carving\n- Honored\n- Celebrated\n- Autism Awareness Month\n- Disability\n- Disabilities\n- Disorder\n- Disorders\n- Think differently\n- Think different\n- Differently abled\n" +
		// 	"The following are acceptable phrases to be used:\n" +
		// 	"- Neurodiversity employment program\n- Neurodiverse talent\n- Neurodivergent individuals\n- Neurodiverse community\n- Neurodiversity in the workplace\n" +
		// 	"### End Guidelines\n\n" +
		// 	`### Begin Content to Review\n${messageText}\n### End Content to Review`;
		// let overrideText = `Proofread the following text against the Neurodiverse Solutions Branding Guidelines and call out any terminology that is not used correctly:\n> ${messageText}`;
        let promptText = `
Leveraging the following Neurodiverse Solutions Branding Guidelines and Terminology Specifics, please review the following text and call out any terminology that is not used correctly.

## Neurodiverse Solutions Branding Guidelines and Terminology Specifics

### Neurodiversity (Noun)
Refers to the range of differences in individual brain function and behavioral traits that are part of the normal variation in the human population.

- **Correct Usage**:  
  - "Neurodiversity in the workplace"  
  - "Neurodiversity at work"  
  - "Neurodiversity hiring program"

- **Incorrect Usage**:  
  - "Neurodiverse hiring"

### Neurodivergent (Adjective)
Used to describe a person (singular or plural) who has a neurodiverse condition such as Autism Spectrum Disorder (ASD), Dyslexia, ADHD, OCD, etc.
- Examples: Person(s)/people, individual(s), employee(s), etc.
- If each individual is neurodivergent, use "neurodivergent individuals"

- **Correct Usage**:  
  - "Neurodivergent individual(s)"  
  - "Neurodivergent employee(s)"  
  - "Neurodivergent worker(s)"

- **Incorrect Usage**:  
  - "Neurodiverse individual(s)"  
  - "Neurodiverse employee(s)"

  ### Neurodiverse (Adjective)
Used to describe a group of people who are neurodivergent.

- **Correct Usage**:  
  - "Neurodiverse student body"
  - "Neurodiverse workforce"  
  - "Neurodiverse talent"
  - "Neurodiverse groups"
  - "Neurodiverse team"

- **Incorrect Usage**:  
  - "Neurodivergent workforce"  
  - "Neurodivergent talent"

## Additional Language Considerations

1. **Use People-First Language**  
   When referring to neurodivergent individuals, always use people-first messaging to avoid objectification. This emphasizes the individual, rather than defining them by their condition.

   - **Correct Usage**:  
     - "People who are neurodivergent"  
     - "Individuals with neurodiverse conditions"

   - **Incorrect Usage**:  
     - "The neurodivergent person"  
     - "The autistic"  
     - Avoid using "they" or "them" to describe neurodivergent people.

2. **Avoid Terms That Imply Pity or Heroism**  
   Do not suggest that neurodivergent people are to be pitied, feared, or ignored. Similarly, avoid portraying them as inherently heroic, courageous, patient, or "special" compared to neurotypical individuals.

3. **Do Not Use the Term "Normal"**  
   Never use the word "normal" to refer to people without neurodiverse conditions. Use "neurotypical" instead. 

   - **Correct Usage**:  
     - "Jane did well on her exam."
   
   - **Incorrect Usage**:  
     - "Jane did as well as the normal students."

## Event Terminology

- **Autism Acceptance Month**  
  April should be referred to as "Autism Acceptance Month" instead of "Autism Awareness Month."

- **Correct Usage**:  
  - "Autism Acceptance Month"  
  - "Recognized during Autism Acceptance Month"

- **Incorrect Usage**:  
  - "Autism Awareness Month"  
  - "Honored during Autism Awareness Month"

## Disability Terminology

- The term "disability" may be used in specific contexts such as official names of events or partnerships (e.g., Disability:IN, NDEAM - National Disability Employment Awareness Month).
  
- **Correct Usage**:  
  - "Disability or disabilities"
  
- **Incorrect Usage**:  
  - "Disorder(s)"  
  - "Differently abled"  
  - "Think differently"

## Summary of Correct vs. Incorrect Usage

| **Do Use**                                    | **Do Not Use**                               |
|-----------------------------------------------|----------------------------------------------|
| Neurodivergent individual(s)                  | Neurodiverse individual(s)                   |
| Neurodivergent employee(s)                    | Neurodiverse employee(s)                     |
| Neurodiverse talent/workforce                 | Neurodivergent talent/workforce              |
| People who are neurodivergent                 | The neurodivergent person                    |
| Autism Acceptance Month                       | Autism Awareness Month                      |
| Disability/disabilities                       | Differently abled                            |
| Neurotypical                                  | Normal                                       |
| Neurodiverse community                        | Autistic community                           |
| Neurodiverse teams                            | A2W teams                                    |
| Neurodiverse workforce                        | ASD diagnosed candidates                     |
| Neurodivergent people                         | Adults diagnosed with Autism                 |
| Neurodivergent individuals                    | Resources with Autism spectrum disorder      |
| Neurodivergent candidates                     | Traits of those with ASD                     |
| Neurodivergent employees                      | Autism in the workplace                      |
| Neurodivergent associates                     | A2W Team Lead                                |
| Neurodiversity in the workplace               | Traits of those with neurodiversity          |
| Neurodiversity-certified team lead            | CAI Team Lead [name]                         |
| Neurodiversity Awareness Training             | Autism Educational Training                  |
| Neurodiversity employment program             | NDS                                          |
| Neurodiverse talent                           | Autism2Work                                  |
| Neurodiverse Solutions (capitalized, plural)  | A2W                                          |
| Neurodivergent individuals                    | Computer Aid, Inc                            |
| Neurodiverse community                        | Computer Aid Inc                             |
| Neurodiversity in the workplace               | Computer Aid                                 |
|                                               | CompAid                                      |
|                                               | Autism                                       |
|                                               | Neurodiverse solutions (non-capitalized)      |
|                                               | Neurodiverse Solution (capitalized, not plural)|
|                                               | Neurodiverse in the workplace                |
|                                               | Neurodiverse at work                         |
|                                               | Neurodiverse employment program              |
|                                               | Neurodiverse hiring                          |
|                                               | Neurodiverse individual(s)                   |
|                                               | Neurodiverse employee(s)                     |
|                                               | Neurodiverse worker(s)                       |
|                                               | Neurodiverse people                          |
|                                               | Neurodivergent talent                        |
|                                               | Neurodivergent workforce                     |
|                                               | Neurodivergent community                     |
|                                               | Neurodivergent team                          |
|                                               | Gift(s)                                      |
|                                               | Resource(s)                                  |
|                                               | Functionality                                |
|                                               | Job carving                                  |
|                                               | Honored                                      |
|                                               | Celebrated                                   |
|                                               | Autism Awareness Month                       |
|                                               | Disability/disabilities                      |
|                                               | Disorder(s)                                  |
|                                               | Think differently/different                  |
|                                               | Differently abled                            |


### Content to Review
> ${messageText}
`
        executeTemplate(null, [promptText])
	};

	return (
		<>
			<Heading size={3}>Let's get started!</Heading>
			<p className="a-muted-text">
				To start the proofreading process, just type or paste your
				content in the box below and hit that Send Message button!
			</p>
			<form action="" className="o-nl-form l-mt-sm">
				<textarea
					className="a-textarea-input"
					name=""
					id=""
					rows="2"
					value={messageText}
					onChange={handleInputChange}
					onKeyDown={handleKeyPress}
				></textarea>
				<div className="m-button-row">
					<Button onClick={(e) => {
						e.preventDefault();
						handleSend();
					}} disabled={!messageText.trim()}>
						Send Message
					</Button>
				</div>
			</form>
		</>
	);
};

export default ProofreadNDS;