import React from 'react';
import { Navbar } from '../components/fusion';
import CAIAvatarMenu from './CAIAvatarMenu';

const NavbarFactory = ({ navbarKey, children }) => {
    const navbarSettings = navbarConfig[navbarKey];

    if (!navbarSettings) {
        console.error(`Invalid navbarKey (${navbarKey}) provided.`);
        return null;
    }

    return (
        <Navbar
            appTitle={navbarSettings.appTitle}
            appTitleSuffix={navbarSettings.appTitleSuffix}
            appSubtitle={navbarSettings.appSubtitle}
            navigations={navbarSettings.navigations}
        >
            <CAIAvatarMenu />
            {children}
        </Navbar>
    );
};

export default NavbarFactory;



export const navbarConfig = {
    // When in doubt, render no app title or navs.
    default: {
        appTitle: "",
        navigations: []
    },
    adminSettings: {
        appTitle: "Admin Settings",
        navigations: []
    },
    bonus: {
        appTitle: "Bonus Planning",
        navigations: [
            {
                path: "/bonus",
                title: "My Organizations",
            },
        ]
    },
    payroll: {
         appTitle: "Payroll Planning",
         navigations: [
             {
                 path: "/payroll",
                 title: "My Organizations",
             },
         ]
    },
    settings: {
        appTitle: "Settings",
        navigations: []
    },
    forms: {
        appTitle: "Forms",
        navigations: []
    },
    symphony: {
        appTitle: process.env.REACT_APP_BRANDING_SYMPHONY_TITLE ?? "Symphony",
        appTitleSuffix: process.env.REACT_APP_BRANDING_SYMPHONY_TITLE_SUFFIX ?? "",
        appSubtitle: process.env.REACT_APP_BRANDING_SYMPHONY_SUBTITLE ?? "",
        navigations: [
            {
                path: "/symphony",
                title: "Chat",
            },
            {
                path: "/symphony/data",
                title: "Data Collections",
            },
            {
                path: "/settings/symphony",
                title: "App Settings",
            },
        ]
    },
};