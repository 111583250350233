import { useEffect, useState } from "react";
import { Heading, Button } from "cai-fusion";
import FileDisplay from "../components/symphonyFileDisplay";
import DnDFileUpload from "../../fusion/FileUpload/DragAndDrop";

import { useTemplate } from "../contexts/TemplateContext";
import { appendixFile } from "./resources/dehssresources";

const OtherIncomeCalculation = () => {

    const OtherIncomeCalculationOverridePrompt = [
`I have attached a Lump Sum Income statement that I need to extract specific details from.
Please extract and display the following information:
    - Employee Name
    - Employer Name and Address (if employer name is not given, use 'No info found')
    - Employment Type
    - Pay Frequency (reference apendix A.1)
    - Payment Date
    - Hours (if available)
    - Gross Income Amount
    - Pre-tax deduction type (reference appendix A.2)
    - Pre-tax deduction amount (for each deduction as there could be multiple)`
];
        
    let OtherIncomeCalculationPrompt = [
`${OtherIncomeCalculationOverridePrompt}

Please list out each of the Pre-tax deduction types and amounts.

If there is more than one, please do it for each.
Please show your work, your thought process, and any assumptions made in the process. So that a human auditor could follow your work and verify the results. Thank you!`
];

	const { isRunning, executeTemplate } = useTemplate();
	const [files, setFiles] = useState([]);

	const deleteFiles = (fileInd) => {
		let filesCopy = [...files];
		filesCopy = [...filesCopy.slice(0, fileInd), ...filesCopy.slice(fileInd + 1)]
		setFiles([...filesCopy]);
	}

	const handleUploads = async () => {
        executeTemplate([...files, appendixFile], [...OtherIncomeCalculationPrompt], [...OtherIncomeCalculationOverridePrompt]);
	}
	const handleFileUpload = async (newFiles) => {
		setFiles((prevFiles) => [...prevFiles, ...newFiles]);
		console.log("[TEMPLATE] Added files. New file list:", ([...files], [...newFiles]));
	}

    const appendTimestampToFileName = (fileName) => {
        const timestamp = new Date()
            .toISOString()
            .replace(/[:\-]/g, "")
            .replace(/\..*$/, "");
        const nameWithoutExt = fileName.replace(/\.[^/.]+$/, "");
        const ext = fileName.split(".").pop();
        return `${nameWithoutExt}_${timestamp}.${ext}`;
    };

    const handleFiles = (pastedFiles) => {
        const fileObjects = pastedFiles.map((file) => {
            return new Promise((resolve, reject) => {
                var reader = new FileReader();
                reader.onload = (e) => {
                    let fileName = file.name.toLowerCase();
                    if (
                        fileName === "image.png" ||
                        fileName === "image.jpg" ||
                        fileName === "image.jpeg"
                    ) {
                        fileName = appendTimestampToFileName(file.name);
                    }
                    resolve({
                        resourceName: fileName,
                        content: e.currentTarget.result,
                        uploading: true, 
                        type: file.type
                    });
                };
                reader.onerror = () => {
                    reject(new Error(`Failed to read file ${file.name}.`));
                };
                reader.readAsDataURL(file);
            });
        });

        Promise.all(fileObjects).then((objs) => handleFileUpload(objs));
    }

    const handlePaste = (event) => {
        const clipboardItems = event.clipboardData.items;
        const files = [];
        for (const item of clipboardItems) {
            if (item.kind === 'file') {
                const file = item.getAsFile();
                if (file) {
                    files.push(file);
                }
            }
        }
        if (files.length > 0) {
            handleFiles(files);
        }
    }

    useEffect(() => {
        document.addEventListener('paste', handlePaste);
        return () => {
            document.removeEventListener('paste', handlePaste);
        };
    }, []);

	return (
		<>
			<Heading size={3}>Let's get started!</Heading>
			<p className="a-muted-text">
				To begin the  Other Income Calculation, please drag and drop or paste the image below. We�ll walk you through the rest!
			</p>
			<form action="" className="o-nl-form l-mt-sm">
				{/* <p className="o-nl-form__text">
					1. Login to ASSIST WW to input application info
				</p>
				<p className="o-nl-form__text">
					2. Login to Outlook
				</p>
				<p className="o-nl-form__text">
					3. Login to OnBase
				</p>
				<p className="o-nl-form__text">
					4. Upload or drag unearned income document here.
				</p> */}
				<FileDisplay filesOverride={files} deleteOverride={deleteFiles} />
				<div style={{ marginBottom: "20px" }}>
					<DnDFileUpload
						/*To-Do this doesn't allow for viewing the data uploaded*/
						notAllowedFileTypes={['.exe', '.bat', '.cmd', '.scr', '.com', '.ps1',
							'.sh', '.vbs', '.msi', '.dll', '.apk', '.appx', '.crx',
							'.deb', '.rpm', '.doc', '.mp3', '.wav', '.m4a', '.mp4', '.avi', '.mov', '.wmv', '.webm',
							'.zip', '.gif', '.7z', '.zip', '.rar', 'lnk']}
						overwriteHandleFiles={handleFileUpload}
                        overwriteDisplayText={"Paste, Drag & Drop, or Click Here to Upload Files"}
					/>
				</div>
				<div className="m-button-row">
					<Button onClick={handleUploads} disabled={files.length === 0 || isRunning}>
                        Calculate
					</Button>
				</div>
			</form>
		</>
	);
};

export default OtherIncomeCalculation;