
import { Method } from "axios";
import { ISymphonyApiConfig } from "../../configs/ISymphonyApiConfig";
import { MyCAIApiService } from "../MyCAIApiService";

export class SymphonyApiServiceV2 extends MyCAIApiService {
    public Assistants: AssistantsService;
    public Chats: ChatsService;
    public DataSources: DataSourcesService;
    public Facts: FactsService;
    public Licensing: LicensingService;
    public Messages: MessagesService;
    public Models: ModelsService;
    public UserProfiles: UserProfilesService;
    public DataStore: DataStoreService
    public paramBuilder(params: { [key: string]: any }): string {
        const keys = Object.keys(params).filter(key => params[key] !== undefined && params[key] !== null);
        var ret = "";

        if (keys.length === 0) {
            return ret;
        }

        ret = keys.map(key => {
            if (params[key] instanceof Array) {
                return params[key].map((val: any) => `${key}=${val}`).join("&");
            } else {
                return `${key}=${params[key]}`
            }
        }).join("&");


        return `?${ret}`;
    }

    constructor(config: ISymphonyApiConfig) {
        super(config);



        this.Assistants = new AssistantsService(this);
        this.Chats = new ChatsService(this, this.paramBuilder);
        this.DataSources = new DataSourcesService(this);
        this.Facts = new FactsService(this);
        this.Licensing = new LicensingService(this);
        this.Messages = new MessagesService(this);
        this.Models = new ModelsService(this);
        this.UserProfiles = new UserProfilesService(this, this.paramBuilder);
        this.DataStore = new DataStoreService(this, this.paramBuilder);
    }
}

class ChatsService {
    private apiService: SymphonyApiServiceV2;
    private paramBuilder: Function;
    constructor(apiService: SymphonyApiServiceV2, paramBuilder: Function) {
        this.apiService = apiService;
        this.paramBuilder = paramBuilder
    }

    public async getChats(expand: string[] | undefined, take: number | undefined, skip: number | undefined, takeAll: boolean | undefined): Promise<any> {
        const endpoint = `/symphony/v2/Chats${this.paramBuilder({
            expand: expand,
            take: take,
            skip: skip,
            takeAll: takeAll
        })}`;
        return this.apiService.sendRequest({
            method: "get",
            endpoint,
        });
    }

    public async createChat(opt: object | undefined): Promise<any> {
        const endpoint = `/Symphony/v2/Chats`;
        return this.apiService.sendRequest({
            method: "post",
            endpoint,
            data: opt ?? {}
        });
    }

    public async getChat(chatId: string, expand: string[] | undefined): Promise<any> {
        const endpoint = `/symphony/v2/Chats/${chatId}${this.paramBuilder({
            expand: expand
        })}`;
        return this.apiService.sendRequest({
            method: "get",
            endpoint,
        });
    }

    public async searchChats(query: string, expand: string[] | undefined, take: number | undefined, skip: number | undefined, takeAll: boolean | undefined): Promise<any> {
        const endpoint = `/symphony/v2/Chats${this.paramBuilder({
            search: query,
            expand: expand,
            take: take,
            skip: skip,
            takeAll: takeAll
        })}`;
        return this.apiService.sendRequest({
            method: "get",
            endpoint,
        });
    }

    // Chat Functions
    public async pinChat(chatId: string, pinPosition: number | undefined): Promise<any> {
        const endpoint = `/Symphony/v2/Chats/${chatId}`;
        return this.apiService.sendRequest({
            method: "patch",
            endpoint,
            data: {
                isPinned: true,
                pinPosition: pinPosition
            }
        });
    }

    public async unpinChat(chatId: string): Promise<any> {
        const endpoint = `/Symphony/v2/Chats/${chatId}`;
        return this.apiService.sendRequest({
            method: "patch",
            endpoint,
            data: {
                isPinned: false
            }
        });
    }

    public async renameChat(chatId: string, name: string): Promise<any> {
        const endpoint = `/Symphony/v2/Chats/${chatId}`;
        return this.apiService.sendRequest({
            method: "patch",
            endpoint,
            data: {
                name: name
            }
        });
    }

    public async deleteChat(chatId: string): Promise<any> {
        const endpoint = `/Symphony/v2/Chats/${chatId}`;
        return this.apiService.sendRequest({
            method: "delete",
            endpoint,
        });
    }

    public async getChatRecommendations(chatId: string): Promise<any> {
        const endpoint = `/Symphony/v2/Chats/${chatId}/recommendations`;
        return this.apiService.sendRequest({
            method: "get",
            endpoint,
        });
    }

    

    public async updateChatModel(
        chatId: string,
        model: object
    ): Promise<any> {
        const endpoint = `/Symphony/v2/Chats/${chatId}/model`;
        return this.apiService.sendRequest({
            method: "patch",
            endpoint,
            data: model
        });
    }

    public async updateAssistantInstruction(
        chatId: string,
        assistantId: string | undefined,
        assistantInstruction: string | undefined
    ): Promise<any> {
        const endpoint = `/Symphony/v2/Chats/${chatId}/assistant`;
        return this.apiService.sendRequest({
            method: "patch",
            endpoint,
            data: {
                assistantInstructionId: assistantId,
                systemMessage: assistantInstruction
            }
        });
    }

    public async updateChatModelParameter(
        chatId: string,
        modelId: string,
        modelParameterId: number,
        value: number
    ): Promise<any> {
        const endpoint = `/Symphony/v2/Chats/${chatId}/modelParameter`;
        return this.apiService.sendRequest({
            method: "post",
            endpoint,
            data: {
                chatId: chatId,
                modelId: modelId,
                modelParameterId: modelParameterId,
                value: value
            }
        });
    }

    // Chat Sharing
    public async shareChat(chatId: string, userId: string | undefined, userIdentifier: string | undefined, accessLevel : number = 3): Promise<any> {
        const endpoint = `/Symphony/v2/Chats/${chatId}/access`;
        return this.apiService.sendRequest({
            method: "post",
            endpoint,
            data: {
                chatId: chatId,
                userProfileId: userId,
                userProfile: {
                    identifier: userIdentifier
                },
                membership: accessLevel
            },
        });
    }

    public async updateChatAccess(chatId: string, accessLevel: number | undefined = 3, userId: string | undefined, userIdentifier: string | undefined): Promise<any> {
        const endpoint = `/Symphony/v2/Chats/${chatId}/access`;
        return this.apiService.sendRequest({
            method: "patch",
            endpoint,
            data: {
                chatId: chatId,
                userProfileId: userId,
                userProfile: {
                    identifier: userIdentifier
                },
                membership: accessLevel
            },
        });
    }

    public async unshareChat(chatId: string, userId: string): Promise<any> {
        const endpoint = `/Symphony/v2/Chats/${chatId}/access/${userId}`;
        return this.apiService.sendRequest({
            method: "delete",
            endpoint,
        });
    }

    // Chat Resources
    public async addChatResource(chatId: string, file: any): Promise<any> {
        const endpoint = `/Symphony/v2/Chats/${chatId}/Resources`;
        return this.apiService.sendPollingRequest({
            method: "post",
            endpoint,
            data: file,
            pollingInterval: 500
        });
    }

    public async getChatResources(chatId: string): Promise<any> {
        const endpoint = `/Symphony/v2/Chats/${chatId}/resources`;
        return this.apiService.sendRequest({
            method: "get",
            endpoint,
        });
    }

    public async deleteChatResource(
        chatId: string,
        resourceId: string
    ): Promise<any> {
        const endpoint = `/Symphony/v2/Chats/${chatId}/resources/${resourceId}`;
        return this.apiService.sendRequest({
            method: "delete",
            endpoint,
        });
    }

    //Chat Datastores
    public async EnableDatastore(
        chatId: string,
        dataStoreId: string
    ): Promise<any> {
        const endpoint = `/Symphony/v2/Chats/${chatId}/datastore`;
        return this.apiService.sendRequest({
            method: "post",
            endpoint,
            data: dataStoreId
        });
    }

    public async DisableDataStore(
        chatId: string,
        dataStoreId: string
    ): Promise<any> {
        const endpoint = `/Symphony/v2/Chats/${chatId}/datastore/${dataStoreId}`;
        return this.apiService.sendRequest({
            method: "delete",
            endpoint
        });
    }

    public async ExportChat(
        chatId: string
    ): Promise<any> {
        const endpoint = `/Symphony/v2/Chats/${chatId}/export`;
        return this.apiService.sendPollingRequest({
            method: "post",
            endpoint,
            pollingInterval: 500
        })
    }
}

class MessagesService {
    private apiService: SymphonyApiServiceV2;

    constructor(apiService: SymphonyApiServiceV2) {
        this.apiService = apiService;
    }

    public async sendMessage(
        chatId: string,
        message: string,
        assistantInstruction: object | undefined,
        model: object | undefined,
        messageParameters: object[] | undefined,
        dataSources: string[] | undefined,
        includedSkills: string[] | undefined,
        messageTextOverride: string | undefined,
        dataStores: string[] | undefined
    ): Promise<any> {
        const endpoint = `/Symphony/v2/Chats/${chatId}/Messages`;
        const data = {
            MessageText: message,
            AssistantInstruction: assistantInstruction,
            Model: model,
            MessageParameters: messageParameters,
            DataSources: dataSources,
            Skills: includedSkills,
            MessageTextOverride: messageTextOverride,
            EnabledDataStores: dataStores
        };

        return this.apiService.sendPollingRequest({
            method: "post",
            endpoint,
            data,
        });
    }

    public async deleteMessage(messageId: string): Promise<any> {
        const endpoint = `/Symphony/v2/messages/${messageId}`;
        return this.apiService.sendRequest({
            method: "delete",
            endpoint,
        });
    }

    public async rateMessage(
        messageId: string,
        rating: boolean
    ): Promise<any> {
        const endpoint = `/Symphony/v2/Messages/${messageId}/rating`;
        return this.apiService.sendRequest({
            method: "patch",
            endpoint,
            data: rating
        });
    }

    public async getMessageDetails(
        messageId: string
    ): Promise<any> {
        const endpoint = `/Symphony/v2/Messages/${messageId}/details`;
        return this.apiService.sendRequest({
            method: "get",
            endpoint,
        });
    }

    public async getMessageAuditTrail(
        messageId: string
    ): Promise<any> {
        const endpoint = `/Symphony/v2/Messages/${messageId}/audit`;
        return this.apiService.sendRequest({
            method: "get",
            endpoint,
        });
    }

    public async getMessageTransactions(
        messageId: string
    ): Promise<any> {
        const endpoint = `/Symphony/v2/Messages/${messageId}/transactions`;
        return this.apiService.sendRequest({
            method: "get",
            endpoint,
        });
    }
}

class UserProfilesService {
    private apiService: SymphonyApiServiceV2;
    private paramBuilder: Function;
    constructor(apiService: SymphonyApiServiceV2, paramBuilder: Function) {
        this.apiService = apiService;
        this.paramBuilder = paramBuilder
    }

    public async getLoggedInUserProfile(expand: string[]): Promise<any> {
        const endpoint = `/Symphony/v2/UserProfiles/me${this.paramBuilder({ expand: expand }) }`;
        return this.apiService.sendRequest({
            method: "get",
            endpoint,
        });
    }

    public async syncLoggedInUserProfile(): Promise<any> {
        const endpoint = `/Symphony/v2/UserProfiles/me`;
        return this.apiService.sendRequest({
            method: "post",
            endpoint,
        });
    }

    public async getPublicUserProfile(userProfileId: string): Promise<any> {
        const endpoint = `/Symphony/v2/UserProfiles/${userProfileId}`;
        return this.apiService.sendRequest({
            method: "get",
            endpoint,
        });
    }

    public async searchUsers(query: string, take: number | undefined, skip: number | undefined, takeAll: boolean | undefined): Promise<any> {
        const endpoint = `/Symphony/v2/UserProfiles${this.paramBuilder(
            {
                search: query,
                take: take,
                skip: skip,
                takeAll: takeAll
            }
        )}`;
        return this.apiService.sendRequest({
            method: "get",
            endpoint,
        });
    }

    public async createUserProfile(): Promise<any> {
        const endpoint = `/Symphony/v2/UserProfiles`;
        return this.apiService.sendRequest({
            method: "post",
            endpoint,
        });
    }

    public async updateUserProfile(
        userProfileId: string,
        updatedUserProfile: any
    ): Promise<any> {
        const endpoint = `/Symphony/v2/UserProfiles/${userProfileId}`;
        return this.apiService.sendRequest({
            method: "put",
            endpoint,
            data: updatedUserProfile
        });
    }

    public async saveUserProfileSettings(
        modelParams: any,
        model: string,
        assistantInstruction: string
    ): Promise<any> {
        const endpoint = `/Symphony/v2/UserProfiles/settings`;
        return this.apiService.sendRequest({
            method: "patch",
            endpoint,
            data: {
                model,
                assistantInstruction,
                modelParameters: modelParams,
            },
        });
    }

    public async updateUserAssistantInstruction(
        assistantId: string | undefined,
        assistantInstruction: string | undefined
    ): Promise<any> {
        const endpoint = `/Symphony/v2/UserProfiles/assistant`;
        return this.apiService.sendRequest({
            method: "post",
            endpoint,
            data: {
                assistantInstructionId: assistantId,
                systemMessage: assistantInstruction
            }
        });
    }

    public async updateUserDefaultModelParam(
        modelId: string,
        modelParameterId: number,
        value: number
    ): Promise<any> {
        const endpoint = `/Symphony/v2/UserProfiles/ModelParam`;
        return this.apiService.sendRequest({
            method: "post",
            endpoint,
            data: {
                modelId: modelId,
                modelParameterId: modelParameterId,
                value: value
            }
        });
    }

    // EULA
    public async getPendingEULA(): Promise<any> {
        const endpoint = `/Symphony/v2/UserProfiles/EULA`;
        return this.apiService.sendRequest({
            method: "get",
            endpoint,
        });
    }

    public async agreeToEULA(eulaId: string): Promise<any> {
        const endpoint = `/Symphony/v2/UserProfiles/EULA/${eulaId}`;
        return this.apiService.sendRequest({
            method: "post",
            endpoint,
        });
    }
}

class AssistantsService {
    private apiService: SymphonyApiServiceV2;

    constructor(apiService: SymphonyApiServiceV2) {
        this.apiService = apiService;
    }

    public async getAssistants(): Promise<any> {
        const endpoint = `/Symphony/v2/Assistants`;
        return this.apiService.sendRequest({
            method: "get",
            endpoint,
        });
    }
}

class DataSourcesService {
    private apiService: SymphonyApiServiceV2;

    constructor(apiService: SymphonyApiServiceV2) {
        this.apiService = apiService;
    }

    public async getExternalDataSources(): Promise<any> {
        const endpoint = `/Symphony/v2/DataSources`;
        return this.apiService.sendRequest({
            method: "get",
            endpoint,
        });
    }
}

class DataStoreService{
    private apiService: SymphonyApiServiceV2;
    private paramBuilder: Function;

    constructor(apiService: SymphonyApiServiceV2, paramBuilder: Function) {
        this.apiService = apiService;
        this.paramBuilder = paramBuilder;
    }

    public async getDataStores(search: string | undefined): Promise<any> {
        const endpoint = `/symphony/v2/DataStore${this.paramBuilder({ query: search })}`;
        return this.apiService.sendRequest({
            method: "get",
            endpoint,
        });
    }

    public async getDataStore(dataStoreId: string): Promise<any> {
        const endpoint = `/symphony/v2/DataStore/${dataStoreId}`;
        return this.apiService.sendRequest({
            method: "get",
            endpoint,
        });
    }

    public async createDataStore(dataStore: { name: string; description: string }): Promise<any> {
        const endpoint = `/symphony/v2/DataStore`;
        return this.apiService.sendRequest({
            method: "post",
            endpoint,
            data: dataStore
        });
    }

    public async getDataStoreContentItem(dataStoreId: string, contentItemId: string): Promise<any> {
        const endpoint = `/symphony/v2/datastore/${dataStoreId}/content/${contentItemId}`;
        return this.apiService.sendRequest({
            method: "get",
            endpoint,
        });
    }

    public async removeDataStoreContent(dataStoreId: string, contentItemId: string): Promise<any> {
        const endpoint = `/symphony/v2/datastore/${dataStoreId}/content/${contentItemId}`;
        return this.apiService.sendRequest({
            method: "delete",
            endpoint,
        });
    }

    public async removeUserDataStoreAccess(dataStoreId: string, userId: string): Promise<any> {
        const endpoint = `/symphony/v2/datastore/${dataStoreId}/users/${userId}/access`;
        return this.apiService.sendRequest({
            method: "delete",
            endpoint,
        });
    }

    public async updateDataStoreAccess(dataStoreId: string, userId: string, accessLevel: number): Promise<any> {
        const endpoint = `/symphony/v2/datastore/${dataStoreId}/users/${userId}/access`;
        return this.apiService.sendRequest({
            method: "patch",
            endpoint,
            data: {
                membership: accessLevel
            }
        });
    }

    public async addContentToDataStore(dataStoreId: string, contentItem: object): Promise<any> {
        const endpoint = `/symphony/v2/datastore/${dataStoreId}/content`;
        return this.apiService.sendPollingRequest({
            method: "post",
            endpoint,
            data: contentItem,
        });
    }

    public async getDataStoreContents(dataStoreId: string): Promise<any> {
        const endpoint = `/symphony/v2/datastore/${dataStoreId}/content`;
        return this.apiService.sendRequest({
            method: "get",
            endpoint,
        });
    }

    public async renameDataStore(dataStoreId: string, newName: string): Promise<any> {
        const endpoint = `/symphony/v2/datastore/${dataStoreId}/rename`;
        return this.apiService.sendRequest({
            method: "put",
            endpoint,
            data: newName,
        });
    }

    public async renameDataStoreContent(dataStoreId: string, contentId: string, newName: string): Promise<any> {
        const endpoint = `/symphony/v2/datastore/${dataStoreId}/content/${contentId}/name`;
        return this.apiService.sendRequest({
            method: "put",
            endpoint,
            data: newName,
        });
    }
    
    public async shareDataStore(dataStoreId: string, userId: string | undefined, userIdentifier: string | undefined, accessLevel : number = 3): Promise<any> {
        const endpoint = `/symphony/v2/datastore/${dataStoreId}/users`;
        return this.apiService.sendRequest({
            method: "post",
            endpoint,
            data: {
                dataStoreId: dataStoreId,
                userProfileId: userId,
                userProfile: {
                    identifier: userIdentifier
                },
                membership: accessLevel
            }
        });
    }

    public async getActiveUsers(dataStoreId: string): Promise<any> {
        const endpoint = `/symphony/v2/datastore/${dataStoreId}/activeUsers`;
        return this.apiService.sendRequest({
            method: "get",
            endpoint,
        });
    }

    public async updateDataStoreDescription(dataStoreId: string, newDescription: string): Promise<any> {
        const endpoint = `/symphony/v2/datastore/${dataStoreId}/description`;
        return this.apiService.sendRequest({
            method: "put",
            endpoint,
            data: newDescription,
        });
    }

    public async deleteDataStore(dataStoreId: string): Promise<any> {
        const endpoint = `/symphony/v2/datastore/${dataStoreId}`;
        return this.apiService.sendRequest({
            method: "delete",
            endpoint,
        });
    }

    public async searchDataStoresByName(dataStoreId: string, searchTerm: string, pageNumber: number = 1, pageSize: number = 10): Promise<any> {
        const endpoint = `/symphony/v2/datastore/${dataStoreId}/search${this.paramBuilder({ searchTerm, pageNumber, pageSize })}`;
        return this.apiService.sendRequest({
            method: "get",
            endpoint,
        });
    }

    public async exportDataStore(dataStoreId: string, path: string | null = null, contentItemId: string | null = null): Promise<any> {
        const endpoint = `/symphony/v2/datastore/${dataStoreId}/export${this.paramBuilder({ path, contentItemId })}`;
        return this.apiService.sendRequest({
            method: "get",
            endpoint,
        });
    }

    public async favoriteDataStore(dataStoreId: string): Promise<any> {
        const endpoint = `/symphony/v2/datastore/${dataStoreId}/favorite`;
        return this.apiService.sendRequest({
            method: "post",
            endpoint,
        });
    }

    public async unfavoriteDataStore(dataStoreId: string): Promise<any> {
        const endpoint = `/symphony/v2/datastore/${dataStoreId}/favorite`;
        return this.apiService.sendRequest({
            method: "delete",
            endpoint,
        });
    }

    public async getDataStoreAccesses(dataStoreId: string): Promise<any> {
        const endpoint = `/symphony/v2/datastore/${dataStoreId}/accesses`;
        return this.apiService.sendRequest({
            method: "get",
            endpoint,
        });
    }
}

class ModelsService {
    private apiService: SymphonyApiServiceV2;

    constructor(apiService: SymphonyApiServiceV2) {
        this.apiService = apiService;
    }

    public async getModels(): Promise<any> {
        const endpoint = `/Symphony/v2/Models`;
        return this.apiService.sendRequest({
            method: "get",
            endpoint,
        });
    }

    public async createModel(model: any): Promise<any> {
        const endpoint = `/Symphony/v2/models`;
        return this.apiService.sendRequest({
            method: 'post',
            endpoint,
            data: model,
        });
    }

    public async updateModel(id: string, model: any): Promise<any> {
        const endpoint = `/Symphony/v2/models/${id}`;
        return this.apiService.sendRequest({
            method: 'put',
            endpoint,
            data: model,
        });
    }

    public async deleteModel(id: string): Promise<any> {
        const endpoint = `/Symphony/v2/models/${id}`;
        return this.apiService.sendRequest({
            method: 'delete',
            endpoint,
        });
    }
}

class LicensingService {
    private apiService: SymphonyApiServiceV2;

    constructor(apiService: SymphonyApiServiceV2) {
        this.apiService = apiService;
    }

    public async isUserLicenseManager(): Promise<any> {
        const endpoint = `/v1/License/IsLicenseAdmin`;
        return this.apiService.sendRequest({
            method: "get",
            endpoint,
        });
    }

    public async isLicensedEnvironment(): Promise<any> {
        const endpoint = `/v1/License/IsLicensedEnvironment`;
        return this.apiService.sendRequest({
            method: "get",
            endpoint,
        });
    }

    public async getLicensedUsers(): Promise<any> {
        const endpoint = `/v1/License/Users`;
        return this.apiService.sendRequest({
            method: "get",
            endpoint,
        });
    }

    public async searchUsersToLicense(queryString: string): Promise<any> {
        const endpoint = `/v1/License/search?query=${queryString}`;
        return this.apiService.sendRequest({
            method: "get",
            endpoint,
        });
    }

    public async unlicenseUser(UPN: string): Promise<any> {
        const endpoint = `/v1/License/${UPN}`;
        return this.apiService.sendRequest({
            method: "delete",
            endpoint,
        });
    }

    public async licenseUser(UPN: string): Promise<any> {
        const endpoint = `/v1/License/${UPN}`;
        return this.apiService.sendRequest({
            method: "post",
            endpoint,
        });
    }

    public async getLicenseCount(): Promise<any> {
        const endpoint = `/v1/License/count`;
        return this.apiService.sendRequest({
            method: "get",
            endpoint,
        });
    }

    public async getUnusableUsers(): Promise<any> {
        const endpoint = `/v1/License/Unusable`;
        return this.apiService.sendRequest({
            method: "get",
            endpoint,
        });
    }

    public async amILicensed(): Promise<any> {
        const endpoint = `/v1/License`;
        return this.apiService.sendRequest({
            method: "get",
            endpoint,
        });
    }
}

class FactsService {
    private apiService: SymphonyApiServiceV2;

    constructor(apiService: SymphonyApiServiceV2) {
        this.apiService = apiService;
    }

    public async createFact(fact: any): Promise<any> {
        const endpoint = `/Symphony/v2/Facts`;
        return this.apiService.sendRequest({
            method: 'post',
            endpoint,
            data: fact,
        });
    }

    public async getAllFacts(): Promise<any> {
        const endpoint = `/Symphony/v2/Facts`;
        return this.apiService.sendRequest({
            method: 'get',
            endpoint,
        });
    }

    public async getFactById(factId: string): Promise<any> {
        const endpoint = `/Symphony/v2/Facts/${factId}`;
        return this.apiService.sendRequest({
            method: 'get',
            endpoint,
        });
    }

    public async updateFact(factId: string, fact: any): Promise<any> {
        const endpoint = `/Symphony/v2/Facts/${factId}`;
        return this.apiService.sendRequest({
            method: 'put',
            endpoint,
            data: fact,
        });
    }

    public async deleteFact(factId: string): Promise<any> {
        const endpoint = `/Symphony/v2/Facts/${factId}`;
        return this.apiService.sendRequest({
            method: 'delete',
            endpoint,
        });
    }
}

// override content type
/*
public async postFormData(endpoint: string, data: any): Promise<any> {
    const formHeaders = this.createHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    try {
      const response = await this.apiClient.post(endpoint, qs.stringify(data), formHeaders);
      return response.data;
    } catch (error) {
      throw error;
    }
  }


  public async fetchProtectedData(endpoint: string, params?: any): Promise<any> {
    try {
      const response = await this.apiClient.get(endpoint, { params });
      return response.data;
    } catch (error) {
      // The interceptor will handle retries for eligible errors.
      // Here you can handle errors that should not be retried, or perform additional error processing.
      throw error;
    }
  }


  // Example usage of apiService.sendRequest to send form data
const formData = new URLSearchParams();
formData.append('key1', 'value1');
formData.append('key2', 'value2');

apiService.sendRequest({
  method: 'post',
  endpoint: '/submit-form',
  data: formData.toString(), // Convert URLSearchParams to string for Axios
  extraHeaders: { 'Content-Type': 'application/x-www-form-urlencoded' },
})
.then(response => {
  console.log('Form submitted successfully', response);
})
.catch(error => {
  console.error('Error submitting form', error);
});

// Example usage of apiService.sendRequest to upload a file
const fileInput = document.querySelector('input[type="file"]'); // Assuming there's a file input element in your HTML
const file = fileInput.files[0];

const fileFormData = new FormData();
fileFormData.append('file', file);
fileFormData.append('description', 'This is a file upload.');

apiService.sendRequest({
  method: 'post',
  endpoint: '/upload-file',
  data: fileFormData,
  // Content-Type is automatically set to multipart/form-data by the browser
})
.then(response => {
  console.log('File uploaded successfully', response);
})
.catch(error => {
  console.error('Error uploading file', error);
});


  */
