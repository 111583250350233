import { useState } from "react";
import { Heading, Button } from "cai-fusion";
import FileDisplay from "../components/symphonyFileDisplay";
import DnDFileUpload from "../../fusion/FileUpload/DragAndDrop";

import { useTemplate } from "../contexts/TemplateContext";

const LumpSumIncomeCalculation = () => {
	const LumpSumIncomeCalculation = [
		`I have attached a unearned income document containing information that I need to extract specific details from. Please detirmine the following information from the document:\r\n
					Name,
					Lump sum type,
					Date received,
					Gross income amount
                    \r\n\r\nFormat the extracted information and display these components in list form in order:\r\n
                    Name,
					Lump sum type,
					Date received,
					Gross income amount
                    \r\n\r\nThank you!`
	]

	const { isRunning, executeTemplate } = useTemplate();
	const [files, setFiles] = useState([]);

	const deleteFiles = (fileInd) => {
		let filesCopy = [...files];
		filesCopy = [...filesCopy.slice(0, fileInd), ...filesCopy.slice(fileInd + 1)]
		setFiles([...filesCopy]);
	}

	const handleUploads = async () => {
		executeTemplate(files, [...LumpSumIncomeCalculation]);
	}
	const handleFileUpload = async (newFiles) => {
		setFiles([...files, ...newFiles]);
		console.log("[TEMPLATE] Added files. New file list:", ([...files], [...newFiles]));
	}

	return (
		<>
			<Heading size={3}>Let's get started!</Heading>
			<p className="a-muted-text">
				To begin the lump sum income calculation, please drag and drop or paste the image below. We'll walk you through the rest.
			</p>
			<form action="" className="o-nl-form l-mt-sm">
				{/* <p className="o-nl-form__text">
					1. Login to ASSIST WW to input application info
				</p>
				<p className="o-nl-form__text">
					2. Login to Outlook
				</p>
				<p className="o-nl-form__text">
					3. Login to OnBase
				</p>
				<p className="o-nl-form__text">
					4. Upload or drag image here.
				</p> */}
				<FileDisplay filesOverride={files} deleteOverride={deleteFiles} />
				<div style={{ marginBottom: "20px" }}>
					<DnDFileUpload
						notAllowedFileTypes={['.exe', '.bat', '.cmd', '.scr', '.com', '.ps1',
							'.sh', '.vbs', '.msi', '.dll', '.apk', '.appx', '.crx',
							'.deb', '.rpm', '.doc', '.mp3', '.wav', '.m4a', '.mp4', '.avi', '.mov', '.wmv', '.webm',
							'.zip', '.gif', '.7z', '.zip', '.rar', 'lnk']}
						overwriteHandleFiles={handleFileUpload}
					/>
				</div>
				<div className="m-button-row">
					<Button onClick={handleUploads} disabled={files.length === 0 || isRunning}>
                        Calculate
					</Button>
				</div>
			</form>
		</>
	);
};

export default LumpSumIncomeCalculation;