import React, { useState, Fragment, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  NavLink,
  useParams,
  Routes,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import {
  Block,
  Breadcrumb,
  Button,
  Checkbox,
  FilterManager,
  FilterManagerTerm,
  FilterManagerTerms,
  Heading,
  HeadingRow,
  HeadingRowActions,
  HeadingRowTitle,
  Modal,
  Progress,
  StatRow,
  StatRowItem,
} from "../fusion";
import { PayrollMeritChangeFilterPanel } from "./payrollMeritChangeFilterPanel";
import { PayrollMeritChangeDetailsPanel } from "./payrollMeritChangeDetailsPanel";
import { MeritChangeRow } from "./payrollMeritChangeRow";
import myCaiApiService from "../../services/mycai-api-v1";
import { MeritChangeTableHeaders } from "./payrollMeritChangeTableHeaders";
import { PayrollMeritChangeBulkActionModal } from "./payrollMeritChangeBulkAction";
import { useAuthentication } from "../../contexts/AuthContext";
import { getMsGraphPhotoUrl, getMsGraphUserPhotoUrl } from "../../services/graph";

const icon_Cancel = require("../../images/cancel-white.svg").default;

const annualizedHours = 1880;

// Create our number formatter.
var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const PayrollMeritChange = (props) => {
  // Navigation Hooks
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { impersonating, graphData, apiAccessToken, graphAccessToken } =
    useAuthentication();
  const [seeAll, setSeeAll] = useState(props.seeAll);
  const [isSub, setIsSub] = useState(props.isSub);

  // Loading States
  const [loadingChangeGroup, setLoadingChangeGroup] = useState(true);

  // Data States
  // source is all the available data
  const [sourceData, setSourceData] = useState([]);
  // grouped is all the data sorted into regions
  const [groupedData, setGroupedData] = useState({});
  // display is what is bound to the UI, with filters applied
  const [displayData, setDisplayData] = useState([]);
  // tracks current filters
  const [dataFilters, setDataFilters] = useState([]);

  // tracks whether or not to treat inline input as hourly
  const [inlineHourly, setInlineHourly] = useState(false);

  //tracks current region tab
  const [regionSet, setRegionSet] = useState("USA");
  const [displayRegion, setDisplayRegion] = useState([]);

  // basically just for the header data
  const [rootChangeGroup, setRootChangeGroup] = useState(null);

  const [imgUrl, setImgUrl] = useState("");
  const [managerName, setManagerName] = useState("");
  const [managerId, setManagerId] = useState("");

  const upn = impersonating
    ? impersonating.userPrincipalName
    : graphData?.userPrincipalName;

  // console.log("UPN: " + upn);
  // console.log("props.UPN: " + props.upn);
  // console.log("graphData.UPN: " + graphData?.userPrincipalName);
  // console.log("See All: " + seeAll);

  // Row selection states
  // track whether the actions control is disabled or not
  const [actionsAvailable, setActionsAvailable] = useState(
    displayData &&
    displayData.filter((x) => {
      return x.selected;
    }).length !== 0
  );
  // track data selected to apply action to
  const [selectedData, setSelectedData] = useState([]);
  // if we should check off the "selected" item in the header
  const [allSelected, setAllSelected] = useState(false);
  // track the state of the "parent" thinking about the rows as a selection group
  const [groupIsChecked, setGroupIsChecked] = useState(false);
  // track selected index for the actions drop down
  const [selectedActionIndex, setSelectedActionIndex] = useState(0);
  const [showActionsModal, setShowActionsModal] = useState(false);

  const [meritUpdate, setMeritUpdate] = useState(false);
  // useEffect(() => {
  //   if (allSelected) {
  //     setGroupIsChecked(true);
  //   } else {
  //     setGroupIsChecked(false);
  //   }
  // }, [allSelected])

  // WHEN THE GROUP IS CHANGED
  const checkboxGroupIsCheckedChangeHandler = () => {
    setGroupIsChecked(!groupIsChecked);
  };

  const onCheckboxGroupChange = (groupIsChecked) => {
    // do nothing, handle only in groupMemberChange Handler
    var newDisplayState = displayData.map((el) =>
      el["isEligible"] && el["assignedToUser"]
        ? {
          ...el,
          selected: groupIsChecked,
        }
        : el
    );
    setDisplayData(newDisplayState);
  };

  const onCheckboxGroupMemberChange = (key, checked) => {
    // console.log(`${key}: ${checked}`);

    var newDisplayState = displayData.map((el) =>
      el["employeeId"] === key && el["isEligible"] && el["assignedToUser"]
        ? {
          ...el,
          selected: checked,
        }
        : el
    );
    setDisplayData(newDisplayState);
  };

  useEffect(() => {
    let newSelectedState = displayData.filter(
      (x) => x.selected && x.isEligible && x.assignedToUser
    );
    setSelectedData(newSelectedState);
  }, [displayData]);

  useEffect(() => {
    console.log("The display Data Changed");
  }, [displayData])
  // WHEN REGION IS CHANGED
  const regionTabChangeHandler = (newRegionSet) => {
    setRegionSet(newRegionSet);

    if (newRegionSet == "IND") {
      setDisplayData(groupedData["IND"]);
    } else if (newRegionSet == "PHL") {
      setDisplayData(groupedData["PHL"]);
    } else {
      setDisplayData(groupedData["USA"]);
    }
  };

  // WHEN REGION IS UPDATED AFTER FIRST LOAD
  const regionUpdate = (newRegionSet) => {
    setRegionSet(newRegionSet);

    if (newRegionSet == "IND") {
      return groupedData["IND"];
    } else if (newRegionSet == "PHL") {
      return groupedData["PHL"];
    } else {
      return groupedData["USA"];
    }
  };


  // WHEN A MEMBER IS CHECKED
  const checkboxMemberIsCheckedChangeHandler = (key, isChecked) => {
    onCheckboxGroupMemberChange(key, isChecked);
  };

  useEffect(() => {
    onCheckboxGroupChange(groupIsChecked);
  }, [groupIsChecked]);

  // Details States
  // sets the details bound to the panel.. also drives panel visibility??
  const [detailsData, setDetailsData] = useState(null);
  // details modal is the confirmation modal for save/cancel
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [detailsModalData, setDetailsModalData] = useState(null);

  // Filter and Filter Panel
  const [showFilterPanel, setShowFilterPanel] = useState(false);

  // Page State
  const [isLoading, setIsLoading] = useState(false);

  const handleAction = () => {
    console.log(selectedData);
    switch (selectedActionIndex) {
      case 0:
        console.log("SET INCREASE %");
        break;
      case 1:
        console.log("SET INCREASE $");
        break;
      default:
        return;
    }
  };

  const removeFilter = (groupKey, filterKey) => {
    changeFilterState(groupKey, filterKey, false);
  };

  const turnOnFilter = (groupKey, filterKey) => {
    changeFilterState(groupKey, filterKey, true);
  };

  const changeFilterState = (groupKey, filterKey, newIsChecked) => {
    var newFilterState = dataFilters.map((el) =>
      el["groupKey"] === groupKey
        ? {
          ...el,
          options: el.options.map((el) =>
            el["filterKey"] === filterKey
              ? {
                ...el,
                isChecked: newIsChecked,
              }
              : el
          ),
        }
        : el
    );

    setDataFilters(newFilterState);
  };

  const resetFiltersClickedHandler = () => {
    console.log("Reset Filters");

    var newFilterState = dataFilters.map((el) =>
      el["groupKey"] === el["groupKey"]
        ? {
          ...el,
          options: el.options.map((el) =>
            el["filterKey"] === el["filterKey"]
              ? {
                ...el,
                isChecked: false,
              }
              : el
          ),
        }
        : el
    );

    setDataFilters(newFilterState);
  };

  useEffect(() => {
    if (props.isLoading) console.log("Is Loading");
    if (!props.isLoading) console.log("Is No Longer Loading");

    setIsLoading(props.isLoading);
  }, [props.isLoading, setIsLoading]);

  const searchHierarchy = (element, key) => {
    if (element.changeGroupId === key) {
      return element;
    } else if (element.childChangeGroups != null) {
      var i;
      var result = null;
      for (i = 0; result == null && i < element.childChangeGroups.length; i++) {
        result = searchHierarchy(element.childChangeGroups[i], key);
      }
      return result;
    }
    return null;
  };

  const searchRootGroup = (rootGroup, meritChangeList) => {
    if (rootGroup?.meritChanges) {
      meritChangeList = meritChangeList.concat(rootGroup.meritChanges);
    }
    if (rootGroup?.childChangeGroups != null) {
      for (let i = 0; i < rootGroup.childChangeGroups.length; i++) {
        meritChangeList = meritChangeList.concat(
          searchRootGroup(rootGroup.childChangeGroups[i], [])
        );
      }
    }
    return meritChangeList;
  };
  useEffect(() => {
    async function fetchData() {
      let arrNames = managerName.split(" ")
      let lastName = arrNames.pop()
      let firstName = arrNames.join("");
      let managerUpn = `${firstName}.${lastName}@cai.io`;
      let newImgUrl = `https://ui-avatars.com/api/?name=${managerName}}`
      if (managerName) {
        try {
          const photoUrl = await getMsGraphUserPhotoUrl(graphAccessToken, managerUpn);
          const url = window.URL || window.webkitURL;
          const blobUrl = url.createObjectURL(photoUrl);
          newImgUrl = blobUrl;
        }
        catch (error) {
          console.log("Error getting photo of user");
          newImgUrl = `https://ui-avatars.com/api/?name=${managerName}`
        }
      }
      setImgUrl(newImgUrl);
    }
    fetchData();
  }, [managerName]) // Added graphAccessToken to the dependency array

  useEffect(() => {
    if (props.data) {
      // flatten and filter here
      let targetChangeGroupId = params.changeGroupId;
      // this is dumb, there should only be one, since it's one change group Id.. it's too late though
      // all the code already accounts for this being an array and I can't go back now
      let rootChangeGroups = props.data.map((x) =>
        searchHierarchy(x, targetChangeGroupId)
      );

      let rootChangeGroup = rootChangeGroups.filter((x) => x != null)[0];

      let meritChangeList = [];
      for (let i = 0; i < rootChangeGroups.length; i++) {
        let rootGroup = rootChangeGroups[i];
        meritChangeList = meritChangeList.concat(
          searchRootGroup(rootGroup, [])
        );
      }

      for (let i = 0; i < meritChangeList.length; i++) {
        meritChangeList[i]["assignedTo"] =
          props.data
            .map((y) => searchHierarchy(y, meritChangeList[i]["changeGroupId"]))
            .filter((x) => x != null)[0]?.assignedUPN ?? "";
        meritChangeList[i]["assignedToUser"] =
          meritChangeList[i]["assignedTo"].localeCompare(upn, "en-us", {
            sensitivity: "accent",
          }) === 0;
      }

      setSourceData(meritChangeList);
      setRootChangeGroup(rootChangeGroup);
    }
  }, [props.data]);

  useEffect(() => {
    // extracts the manager id and name from the ChangeGroups name 
    if (rootChangeGroup) {
      let startOfName = rootChangeGroup.name.slice(rootChangeGroup.name.indexOf(" (") + 2);
      let splitted = startOfName.split("(");
      let manager = splitted[0].trim();
      let managerId = splitted[1].substring(0, splitted[1].indexOf(")"));
      // console.log(`ManagerName: ${manager}, ID: ${managerId}`);
      setManagerName(manager);
      setManagerId(managerId);
    }
  }, [rootChangeGroup]);

  useEffect(() => {
    const _filterOptions = [];
    _filterOptions.push({
      groupName: undefined,
      groupKey: "onlyAssignedToMe",
      options: [
        {
          filterKey: "onlyMe",
          filterPolarity: "restrict",
          filterFunction: (data) => data.assignedToUser === true,
          displayProperty: "",
          displayValue: "Only changes assigned to me",
          filterLabel: "Only show merit changes currently assigned to me",
          isChecked: true,
          filterGroup: "onlyAssignedToMe",
        },
      ],
    });
    _filterOptions.push({
      groupName: undefined,
      groupKey: "noIncrease",
      options: [
        {
          filterKey: "noIncreaseSpecified",
          filterPolarity: "restrict",
          filterFunction: (data) =>
            (data.proposedBaseComp === null ||
              data.proposedBaseComp === 0.0 ||
              data.reviewed === false) &&
            data.isEligible === true,
          displayProperty: "",
          displayValue: "Employees with no increase specified",
          filterLabel: "Only show employees with no increase specified",
          isChecked: false,
          filterGroup: "noIncrease",
        },
      ],
    });
    _filterOptions.push({
      groupName: undefined,
      groupKey: "ineligible",
      options: [
        {
          filterKey: "ineligible",
          filterPolarity: "restrict",
          filterFunction: (data) => data.isEligible === false,
          displayProperty: "",
          displayValue: "Only ineligible employees",
          filterLabel: "Only show associates ineligible for an increase",
          isChecked: false,
          filterGroup: "ineligible",
        },
        {
          filterKey: "eligible",
          filterPolarity: "restrict",
          filterFunction: (data) => data.isEligible === true,
          displayProperty: "",
          displayValue: "Only eligible employees",
          filterLabel: "Only show associates eligible for an increase",
          isChecked: false,
          filterGroup: "ineligible",
        },
      ],
    });
    _filterOptions.push({
      groupName: "Performance Score",
      groupKey: "performance",
      options: [1, 2, 3, 4, 5].map((performanceScoreValue) => {
        return {
          filterKey: `performanceScore_${performanceScoreValue}`,
          filterPolarity: "include",
          filterFunction: (data) =>
            data.lastReviewLevel === performanceScoreValue,
          displayProperty: "Perf Score:",
          displayValue: String(performanceScoreValue),
          filterLabel: String(performanceScoreValue),
          isChecked: false,
          filterGroup: "performanceScore",
        };
      }),
    });
    _filterOptions
      .filter((x) => x.groupKey === "performance")[0]
      .options.push({
        filterKey: `performanceScore_none`,
        filterPolarity: "include",
        filterFunction: (data) =>
          data.lastReviewLevel === null || data.lastReviewLevel === undefined,
        displayProperty: "Perf Score:",
        displayValue: "None",
        filterLabel: "None",
        isChecked: false,
        filterGroup: "performanceScore",
      });

    // add role based filters based on source
    _filterOptions.push({
      groupName: "Roles",
      groupKey: "role",
      options: sourceData
        .map((x) => x.jobTitle)
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort((a, b) => a.localeCompare(b))
        .map((role) => {
          return {
            filterKey: `role_${role}`,
            filterPolarity: "include",
            filterFunction: (data) => data.jobTitle === role,
            displayProperty: "Role:",
            displayValue: role,
            filterLabel: role,
            isChecked: false,
            filterGroup: "role",
          };
        }),
    });

    setDataFilters(_filterOptions);
  }, [setDataFilters, sourceData]);

  // When source data is changed, assume it' a brand new page
  useEffect(() => {
    // set display data from source, only when source changes
    var newGrouped = {
      USA: [],
      IND: [],
      PHL: [],
    };

    for (var i = 0; i < sourceData.length; i++) {
      var row = sourceData[i];
      if (row.company == "PHL CAI-STA Philippines, Inc.") {
        newGrouped["PHL"].push(row);
      } else if (row.company == "INDIA CAI Info India Private Ltd.") {
        newGrouped["IND"].push(row);
      } else {
        newGrouped["USA"].push(row);
      }

      if (newGrouped["USA"].length == 0) {
        if (newGrouped["IND"].length == 0) {
          setRegionSet("PHL");
        } else {
          setRegionSet("IND");
        }
      }
    }
    console.log(newGrouped);
    setGroupedData(newGrouped);

    //setDisplayData(newGrouped["USA"]);

    // checks if its a merit update or just a regular load
    if (!meritUpdate)
      setDisplayData(sourceData);

    console.log(`sourcedata`);
    console.log(sourceData);
    // don't reset data filters.. either it's empty or persist state
    // setDataFilters(dataFilters);

    setGroupIsChecked(false);
    // onCheckboxGroupChange(false);
  }, [sourceData]);

  // When selected rows changes, set if actions are available, and set if all are selected
  useEffect(() => {
    if (selectedData) {
      console.log(`Num Selected: ${selectedData.length}`);

      setActionsAvailable(selectedData.length !== 0);

      let eligbileDisplayData = displayData.filter(
        (x) => x.isEligible === true && x.assignedToUser === true
      );

      setAllSelected(
        selectedData.length > 0 &&
        selectedData.length === eligbileDisplayData.length
      );
    }
  }, [selectedData]);

  // Do filtering when data filter is changed
  useEffect(() => {
    console.log("UPDATE DISPLAY DATA");

    // assigns data if its merit update or just a page load or refresh
    var filteredData = meritUpdate ? regionUpdate(regionSet) : sourceData;
    setMeritUpdate(false);

    let appliedDataFilters = dataFilters
      .map((fg) => fg.options)
      .flat()
      .filter((f) => f.isChecked);

    // Each group is (AND) with eachother
    // AND (OR) for options within the group
    let groupsRepresented = appliedDataFilters
      .map((df) => df.filterGroup)
      .filter((value, index, array) => array.indexOf(value) === index);
    for (let k = 0; k < groupsRepresented.length; k++) {
      let group = groupsRepresented[k];
      let groupFilters = appliedDataFilters.filter(
        (x) => x.filterGroup === group
      );

      if (groupFilters.length > 0) {
        // Only include data from each GROUP.. (this is the AND between groups)
        filteredData = filteredData.filter((data) => {
          // if matches at least one group filter.. (this is the OR inside a group)
          for (let i = 0; i < groupFilters.length; i++) {
            let filter = groupFilters[i];
            // short circuit the loop and include in the data
            if (filter.filterFunction(data)) {
              return true;
            }
          }
          // didn't match any group filters
          return false;
        });
      }
    }

    // MORE COMPLEX THAN THIS NEEDS TO BE.. but good for filter control, combined with the above
    // let restrictFilters = appliedDataFilters.filter(
    //   (f) => f.filterPolarity === "restrict"
    // );
    // let includeFilters = appliedDataFilters.filter(
    //   (f) => f.filterPolarity === "include"
    // );
    // let excludeFilters = appliedDataFilters.filter(
    //   (f) => f.filterPolarity === "exclude"
    // );

    // for (let k = 0; k < restrictFilters.length; k++) {
    //   let dataFilter = restrictFilters[k];
    //   filteredData = filteredData.filter((data) => {
    //     return dataFilter.filterFunction(data);
    //   });
    // }

    // // have to do all the include filters together since we have to imply
    // // what is not allowed based on what is allowed

    // // if there are no include filters, include everything so no filtering
    // if (includeFilters.length > 0) {
    //   filteredData = filteredData.filter((data) => {
    //     for (let i = 0; i < includeFilters.length; i++) {
    //       let dataFilter = includeFilters[i];

    //       //// DELETE if below works
    //       // if (data[dataFilter.property] === dataFilter.value) {
    //       //   // short circuit the filter...
    //       //   return true;
    //       // }
    //       ////

    //       // short circuit the filter... if the data matches one include filter, it doesn't matter
    //       // if it matches any others since it's included
    //       // return dataFilter.filterFunction(data);
    //       if (dataFilter.filterFunction(data)) {
    //         return true;
    //       }
    //     }
    //     // end of loop, if this is reached the short circuit never closed, do not include
    //     return false;
    //   });
    // }

    // // then do all the excludes since matching any of the categories
    // // takes it out of the list
    // for (let j = 0; j < excludeFilters.length; j++) {
    //   let dataFilter = excludeFilters[j];
    //   filteredData = filteredData.filter((data) => {
    //     //// DELETE if below works
    //     // return data[dataFilter.property] != dataFilter.value;
    //     ////

    //     // returns where true, filters out where false
    //     return !dataFilter.filterFunction(data);
    //   });
    // }

    setDisplayData(filteredData);
  }, [dataFilters]);

  // Initital data load!
  useEffect(() => {
    if (!loadingChangeGroup) setLoadingChangeGroup(true);

    if (apiAccessToken) {
      // do api call if needed...
    }
  }, [apiAccessToken, impersonating]);

  useEffect(() => {
    if (impersonating) {
      console.log(`Impersonating: ${impersonating.displayName}`);
    } else {
      console.log("Impersonating: None");
    }
  }, [impersonating]);

  return (
    <Fragment>
      <div className="o-page-section o-page-section l-mt-md l-pt-none">
        <div className="o-page-section__content-container o-page-section__content-container--wide-fixed-width l-pt-none l-mt-neg-sm">
          <div className="l-row">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <Breadcrumb
                data={[
                  {
                    displayName: "Organizations",
                    onClick: () => {
                      navigate(`/payroll/`, { replace: true });
                    },
                  },
                  {
                    displayName:
                      rootChangeGroup &&
                      rootChangeGroup.name &&
                      rootChangeGroup.name.substring(
                        0,
                        rootChangeGroup.name.indexOf(" (")
                      ),
                    onClick: () => { },
                  },
                ]}
              />
              <HeadingRow>
                <HeadingRowTitle>
                  <Heading size={2}>
                    {rootChangeGroup &&
                      rootChangeGroup.name &&
                      rootChangeGroup.name.substring(
                        0,
                        rootChangeGroup.name.indexOf(" (")
                      )}
                  </Heading>
                </HeadingRowTitle>
                <HeadingRowActions>
                  <div className="m-heading-row__actions-inner">
                    <div className="m-button-row">
                      {/* <button className={`a-button ${sourceData.filter(
                        (data) =>
                          data.proposedBaseComp === null ||
                          data.proposedBaseComp == 0.0 ||
                          (data.reviewed === false && data.isEligible)
                      ).length > 0 ? "a-button--disabled" : ""}`} onClick={() => {
                        myCaiApiService.SubmitChangeGroups(props.accessToken, params.changeGroupId, impersonating?.employeeId).then(() => {
                          props.onMeritUpdate().then(() => {
                            navigate(`/payroll/`, {
                              replace: true,
                            });
                          })
                        })
                      }}>
                        Submit Increases
                      </button> */}
                    </div>
                  </div>
                </HeadingRowActions>
              </HeadingRow>
            </div>
          </div>
          <div classname="l-row">
            <div className="m-tab-row l-mt-sm">
              {/* US Tab */}
              {groupedData["USA"]?.length > 0 ? (
                <a
                  className={`m-tab-row__tab ${regionSet == "USA" ? " m-tab-row__tab--active" : ""
                    }`}
                  onClick={(e) => {
                    regionTabChangeHandler("USA");
                    e.preventDefault();
                  }}
                >
                  <span className="m-tab-row__tab-title">
                    US-Based Employees
                  </span>
                  <span className="m-tab-row__help">
                    View only employees from the US
                  </span>
                </a>
              ) : (
                ""
              )}
              {/* India Tab */}
              {groupedData["IND"]?.length > 0 ? (
                <a
                  className={`m-tab-row__tab ${regionSet == "IND" ? " m-tab-row__tab--active" : ""
                    }`}
                  onClick={(e) => {
                    regionTabChangeHandler("IND");
                    e.preventDefault();
                  }}
                >
                  <span className="m-tab-row__tab-title">
                    India-Based Employees
                  </span>
                  <span className="m-tab-row__help">
                    View only employees from India
                  </span>
                </a>
              ) : (
                ""
              )}
              {/* Philippines Tab */}
              {groupedData["PHL"]?.length > 0 ? (
                <a
                  className={`m-tab-row__tab ${regionSet == "PHL" ? " m-tab-row__tab--active" : ""
                    }`}
                  onClick={(e) => {
                    regionTabChangeHandler("PHL");
                    e.preventDefault();
                  }}
                >
                  <span className="m-tab-row__tab-title">
                    Philippines-Based Employees
                  </span>
                  <span className="m-tab-row__help">
                    View only employees from the Philippines
                  </span>
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="l-row">
            <div className="col-md-12">
              <Block className="l-mt-sm l-pt-md l-pb-sm">
                <Heading size={4}>
                  {isLoading
                    ? "Loading Updated Compensation Numbers"
                    : "Compensation Summary"}
                </Heading>
                <StatRow className="l-mt-sm">
                  {isLoading ? (
                    <div className={`o-stat-row__stat m-stat`}>
                      <Progress />
                    </div>
                  ) : (
                    <StatRowItem
                      value={
                        rootChangeGroup &&
                        rootChangeGroup.currentTargetComp &&
                        formatter.format(rootChangeGroup.currentTargetComp)
                      }
                      label={"Current Base Comp of Eligible Employees"}
                    />
                  )}
                  {isLoading ? (
                    <div className={`o-stat-row__stat m-stat`}>
                      <Progress />
                    </div>
                  ) : (
                    <StatRowItem
                      value={
                        rootChangeGroup &&
                        rootChangeGroup.currentTargetComp &&
                        rootChangeGroup.proposedTargetComp &&
                        String(
                          Math.round(
                            ((rootChangeGroup.proposedTargetComp -
                              rootChangeGroup.currentTargetComp) /
                              rootChangeGroup.currentTargetComp +
                              Number.EPSILON) *
                            10000
                          ) / 100
                        ) + "%"
                      }
                      label={"Proposed Increase %"}
                    />
                  )}
                  {isLoading ? (
                    <div className={`o-stat-row__stat m-stat`}>
                      <Progress />
                    </div>
                  ) : (
                    <StatRowItem
                      value={
                        rootChangeGroup &&
                        rootChangeGroup.currentTargetComp &&
                        rootChangeGroup.proposedTargetComp &&
                        formatter.format(
                          rootChangeGroup.proposedTargetComp -
                          rootChangeGroup.currentTargetComp
                        )
                      }
                      label={"Proposed Base Comp Increase"}
                    />
                  )}
                  {isLoading ? (
                    <div className={`o-stat-row__stat m-stat`}>
                      <Progress />
                    </div>
                  ) : (
                    <StatRowItem
                      value={
                        rootChangeGroup &&
                        rootChangeGroup.proposedTargetComp &&
                        formatter.format(rootChangeGroup.proposedTargetComp)
                      }
                      label={"Annualized Compensation"}
                    />
                  )}
                  {isLoading ? (
                    <div className={`o-stat-row__stat m-stat`}>
                      <Progress />
                    </div>
                  ) : (
                    <StatRowItem
                      className={`m-stat--bold ${rootChangeGroup &&
                        rootChangeGroup.currentTargetComp &&
                        rootChangeGroup.proposedTargetComp &&
                        rootChangeGroup.budget -
                        rootChangeGroup.proposedTargetComp >=
                        0
                        ? "m-stat--positive"
                        : "m-stat--negative"
                        }`}
                      value={
                        rootChangeGroup &&
                        rootChangeGroup.currentTargetComp &&
                        rootChangeGroup.proposedTargetComp &&
                        formatter.format(
                          rootChangeGroup.budget -
                          rootChangeGroup.proposedTargetComp
                        )
                      }
                      label={`Remaining Budgeted Allocation for ${rootChangeGroup &&
                        rootChangeGroup.name &&
                        rootChangeGroup.name.substring(
                          0,
                          rootChangeGroup.name.indexOf(" (")
                        )
                        }`}
                    />
                  )}
                </StatRow>
              </Block>

              <FilterManager className="l-mt-sm l-mb-md">
                <Button
                  className="o-filter-manager__btn"
                  id="filter-btn"
                  onClick={(e) => {
                    setShowFilterPanel(true);
                    e.preventDefault();
                  }}
                >
                  Filter (
                  {dataFilters
                    .map(
                      (filterGroup) =>
                        filterGroup.options.filter((filter) => filter.isChecked)
                          .length
                    )
                    .reduce((a, b) => a + b, 0)}
                  )
                </Button>
                <FilterManagerTerms>
                  {dataFilters.map((filterGroup) => {
                    return filterGroup.options.map((filter) => {
                      return (
                        filter.isChecked && (
                          <FilterManagerTerm key={filter.key}>
                            <p className="a-chip__label">
                              <span className="a-chip__category">
                                {filter.displayProperty}
                              </span>{" "}
                              {filter.displayValue}
                            </p>
                            <button
                              className="a-chip__remove-btn"
                              onClick={(e) => {
                                e.preventDefault();
                                removeFilter(
                                  filterGroup.groupKey,
                                  filter.filterKey
                                );
                              }}
                            >
                              <img src={icon_Cancel} className="a-chip__icon" />
                            </button>
                          </FilterManagerTerm>
                        )
                      );
                    });
                  })}
                  <button
                    className="a-button a-button--link a-button--small"
                    onClick={(e) => {
                      e.preventDefault();
                      resetFiltersClickedHandler();
                    }}
                  >
                    Clear all
                  </button>
                </FilterManagerTerms>
              </FilterManager>
              <Checkbox
                label="Edit Hourly Inline"
                onChange={(isChecked) =>
                  setInlineHourly(isChecked)
                }
              />

              <div className="l-row">
                <div className="l-one-half-col">
                  <form action="" className="m-bulk-action-menu">
                    <select
                      name="actionSelector"
                      id="actionSelector"
                      className="a-select-input m-bulk-action-menu__select"
                      disabled={!actionsAvailable}
                      onChange={(e) => {
                        setSelectedActionIndex(e.target.selectedIndex);
                      }}
                    >
                      <option value="" selected={true}>
                        Set increase % for selected employees
                      </option>
                      {/* <option value="">
                        Set dollar increase for selected employees
                      </option> */}
                    </select>
                    <Button
                      disabled={!actionsAvailable}
                      onClick={(e) => {
                        setShowActionsModal(true);
                        e.preventDefault();
                      }}
                    >
                      Go
                    </Button>
                  </form>
                </div>
                <div className="l-one-half-col">
                  {sourceData.filter(
                    (data) =>
                      data.proposedBaseComp === null ||
                      data.proposedBaseComp == 0.0 ||
                      (data.reviewed === false &&
                        data.isEligible &&
                        data.assignedToUser)
                  ).length > 0 && (
                      <div className="m-callout m-callout--right-aligned m-callout--condensed l-mt-xs">
                        <svg
                          className="m-callout__icon"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 358.38"
                        >
                          <g id="Layer_2">
                            <g id="Engine_Warning">
                              <path d="M38.4,179.19c-.01-47.52,15.54-93.74,44.29-131.58,4.24-5.33,3.52-13.06-1.64-17.51l-9.73-8.35c-5.39-4.66-13.54-4.06-18.2,1.33-.11,.13-.23,.27-.34,.41-70.38,91.86-70.38,219.52,0,311.38,4.41,5.56,12.49,6.5,18.05,2.1,.15-.12,.29-.23,.43-.36l9.78-8.35c5.12-4.45,5.84-12.14,1.64-17.46-28.74-37.86-44.29-84.09-44.28-131.63ZM255.99,0C157.03,0,76.8,80.23,76.8,179.19s80.23,179.19,179.19,179.19,179.19-80.23,179.19-179.19S354.95,0,255.99,0h0Zm0,281.58c-14.14,0-25.6-11.46-25.6-25.6s11.46-25.6,25.6-25.6,25.6,11.46,25.6,25.6-11.46,25.6-25.6,25.6Zm20.48-88.31c-.66,6.58-6.23,11.58-12.85,11.52h-15.36c-6.58,0-12.09-4.97-12.75-11.52l-10.24-102.39c-.68-7.06,4.5-13.34,11.56-14.02,.43-.04,.86-.06,1.29-.06h35.84c7.1,.02,12.83,5.8,12.81,12.89,0,.4-.02,.79-.06,1.18l-10.24,102.39ZM458.17,22.17c-4.45-5.57-12.57-6.47-18.13-2.02-.14,.11-.27,.22-.4,.33l-9.57,8.5c-5.11,4.5-5.76,12.22-1.48,17.51,60.21,78.24,60.21,187.21,0,265.46-4.28,5.29-3.62,13.01,1.48,17.51l9.57,8.45c5.36,4.7,13.51,4.16,18.21-1.2,.11-.13,.22-.26,.33-.39,71.78-92.39,71.78-221.7,0-314.09v-.05Z" />
                            </g>
                          </g>
                        </svg>
                        <div className="m-callout__content">
                          <h4 className="m-callout__title">
                            {
                              sourceData.filter(
                                (data) =>
                                  (data.proposedBaseComp === null ||
                                    data.proposedBaseComp == 0.0 ||
                                    (data.reviewed === false &&
                                      data.isEligible)) &&
                                  data.assignedToUser
                              ).length
                            }{" "}
                            assigned employees have no increase specified.
                          </h4>
                        </div>
                        <a
                          href="#"
                          className="a-button a-button--link m-callout__action"
                          onClick={(e) => {
                            turnOnFilter("noIncrease", "noIncreaseSpecified");
                            e.preventDefault();
                          }}
                        >
                          Show
                        </a>
                      </div>
                    )}
                </div>
              </div>

              <table className="o-treegrid l-mt-sm">
                <MeritChangeTableHeaders>
                  <label className="a-checkbox a-checkbox--hidden-label">
                    <span>Select all Eligible Displayed Employees</span>
                    <input
                      type="checkbox"
                      defaultChecked={false}
                      // checked={props.allSelected}
                      checked={groupIsChecked}
                      onChange={checkboxGroupIsCheckedChangeHandler}
                    />
                    <div className="b-input"></div>
                  </label>
                </MeritChangeTableHeaders>
                <tbody>
                  {displayData.length !== 0 ? (
                    <th className="o-treegrid__row o-treegrid__row--shown o-treegrid__row--striped-bg">
                      <td className="o-treegrid__cell o-treegrid__cell--centered">
                        <label className={`a-checkbox a-checkbox--hidden-label`}>
                          <span>Select sami almouzani</span>
                          <input
                            type="checkbox"
                            // checked={props.data.isEligible && props.data.assignedToUser && isChecked}
                            // onChange={checkboxOnChangedHandler}
                            disabled={true}
                          />
                          <div className="b-input"></div>
                        </label>
                      </td>
                      <td className="o-treegrid__cell">
                        <div className="m-subject-highlight">
                          <div className="a-avatar m-subject-highlight__avatar">
                            <img
                              src={imgUrl}
                              alt=""
                              className="a-avatar__img"
                            />
                            {/* <img src={`data:image/jpeg;base64,${imgUrl}`} className="a-avatar__img" /> */}
                          </div>
                          <div className="m-subject-highlight__content">
                            <p className="a-bold-text m-subject-highlight__title">
                              {managerName}
                            </p>
                            <p className="a-muted-text m-subject-highlight__subtitle">
                              {props.data.jobTitle}
                            </p>
                            <p className="a-accent-text m-subject-highlight__meta">{managerId}</p>
                          </div>
                        </div>
                      </td>
                      <td class="o-treegrid__cell" colSpan={5}>
                        {managerName} manages the organization "{rootChangeGroup?.name.split("(")[0]}"
                      </td>
                    </th>
                  ) : <></>
                  }
                  {displayData.map((x) => (
                    <MeritChangeRow
                      data={x}
                      accessToken={apiAccessToken}
                      upn={upn}
                      seeAll={seeAll}
                      isSub={isSub}
                      key={x.employeeId}
                      parentIsChecked={groupIsChecked}
                      parentAssignedUpn={x.assignedTo}
                      assignedTo={x.assignedTo}
                      assignedToUser={x.assignedToUser}
                      inlineHourlyChecked={inlineHourly}
                      onChange={(checked) => {
                        checkboxMemberIsCheckedChangeHandler(
                          x.employeeId,
                          checked
                        );
                      }}
                      onDetailsClicked={() => {
                        navigate(x.id);
                      }}
                      onSave={(proposedPctInc, taskId, updatedBy, updatedEmployee, newPropBase) => {
                        // setShowDetailsModal(false);
                        proposedPctInc != null
                          ? myCaiApiService
                            .UpdateMeritChangePercent(
                              apiAccessToken,
                              proposedPctInc,
                              taskId,
                              updatedBy,
                              updatedEmployee,
                              newPropBase,
                              impersonating
                            )
                            .then(() => {
                              if (props.onMeritUpdate) {
                                setMeritUpdate(true);
                                props.onMeritUpdate();
                              }
                              navigate(`/payroll/${params.changeGroupId}`, {
                                replace: true,
                              });
                              // location.reload();
                            })
                          : console.log("Unable to update Merit Change");
                      }}

                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <PayrollMeritChangeFilterPanel
        hidden={!showFilterPanel}
        clickOutsideToClose={true}
        dataFilterState={dataFilters}
        onFilterReset={() => {
          resetFiltersClickedHandler();
        }}
        onClose={() => {
          // todo, modal
          setShowFilterPanel(false);
        }}
        onCancel={() => {
          setShowFilterPanel(false);
        }}
        onApply={(panelDataFilterState) => {
          console.log("Apply Changes");
          setDataFilters(panelDataFilterState);
          setShowFilterPanel(false);
        }}
      />
      <Routes>
        <Route
          path=":employeeId"
          element={
            <PayrollMeritChangeDetailsPanel
              // source data.. then it'll filter by route
              data={displayData}
              hidden={false}
              clickOutsideToClose={true}
              assignedToUser={displayData.assignedToUser}
              onClose={(changesMade) => {
                if (changesMade) {
                  setDetailsModalData({
                    title:
                      "You haven't saved the changes you've made on this page.",
                    body: (
                      <div className="l-row">
                        <div className="l-one-col">
                          <p>
                            You haven't saved the changes you've made on this
                            page.
                          </p>
                          <p>Want to go back and do that before moving on?</p>
                        </div>
                      </div>
                    ),
                    cancel: "Discard my changes",
                    confirm: "Go back and save",
                    onConfirm: () => {
                      setShowDetailsModal(false);
                    },
                  });
                  setShowDetailsModal(true);
                } else {
                  navigate(`/payroll/${params.changeGroupId}`, {
                    replace: true,
                  });
                }
              }}
              onCancel={(changesMade) => {
                if (changesMade) {
                  setDetailsModalData({
                    title:
                      "You haven't saved the changes you've made on this page.",
                    body: (
                      <div className="l-row">
                        <div className="l-one-col">
                          <p>
                            You haven't saved the changes you've made on this
                            page.
                          </p>
                          <p>Want to go back and do that before moving on?</p>
                        </div>
                      </div>
                    ),
                    cancel: "Discard my changes",
                    confirm: "Go back and save",
                    onConfirm: () => {
                      setShowDetailsModal(false);
                    },
                  });
                  setShowDetailsModal(true);
                } else {
                  navigate(`/payroll/${params.changeGroupId}`, {
                    replace: true,
                  });
                }
              }}
              onSave={(updatedData) => {
                setShowDetailsModal(false);
                updatedData?.proposedBaseComp != null
                  ? myCaiApiService
                    .UpdateMeritChangePercent(
                      apiAccessToken, //Access Token
                      updatedData?.proposedBaseComp, //NewPercentInc
                      updatedData.taskId,//Guid
                      updatedData.assignedTo, //UpdatedBy
                      updatedData.upn, //UpdatedEmployee
                      updatedData?.proposedBudgetContribution, //newPropBase
                      impersonating
                    )
                    .then(() => {
                      if (props.onMeritUpdate) {
                        props.onMeritUpdate();
                      }
                      navigate(`/payroll/${params.changeGroupId}`, {
                        replace: true,
                      });
                      // location.reload();
                    })
                  : console.log("Unable to update Merit Change");
              }}
            />
          }
        />
      </Routes>
      {/* modals */}
      <Modal
        show={showDetailsModal}
        title={detailsModalData && detailsModalData.title}
        body={detailsModalData && detailsModalData.body}
        cancel={detailsModalData && detailsModalData.cancel}
        confirm={detailsModalData && detailsModalData.confirm}
        onClose={
          detailsModalData && detailsModalData.onClose
            ? detailsModalData.onClose
            : () => {
              setShowDetailsModal(false);
            }
        }
        onCancel={
          detailsModalData && detailsModalData.onCancel
            ? detailsModalData.onCancel
            : () => {
              setShowDetailsModal(false);
              navigate(`/payroll/${params.changeGroupId}`, { replace: true });
            }
        }
        onConfirm={
          detailsModalData && detailsModalData.onConfirm
            ? detailsModalData.onConfirm
            : () => {
              setShowDetailsModal(false);
            }
        }
      />
      <PayrollMeritChangeBulkActionModal
        data={displayData}
        remainingAllocation={
          rootChangeGroup?.budget -
          rootChangeGroup?.proposedTargetComp
        }
        hidden={!showActionsModal}
        selected={selectedData}
        clickOutsideToClose={true}
        onClose={(changesMade) => {
          setShowActionsModal(false);
        }}
        onCancel={(changesMade) => {
          setShowActionsModal(false);
        }}
        onSave={(updatedData) => {
          myCaiApiService
            .BulkUpdateMeritChangeAmounts(
              apiAccessToken,
              updatedData,
              impersonating?.employeeId
            )
            .then(() => {
              props.onMeritUpdate();
            });
          setShowActionsModal(false);
          setSelectedData([]);
        }}
      />
    </Fragment>
  );
};

export default PayrollMeritChange;
