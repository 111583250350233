import React, {useEffect, useState} from "react";
import { ActivityTracker } from "cai-fusion";

import { useChat } from "../contexts/ChatContext";

const ExportChatTracker = ({isHidden = false}) => {
    const [isCollapsed, setIsCollapsed] = useState(false);

    const { chat } = useChat();

    useEffect(() => {
        if (isHidden) {
            setIsCollapsed(false);
        }
    }, [isHidden])

    return (
        <ActivityTracker
            message={`Exporting ${chat?.name ? `"${chat?.name}"` : "this chat"} to a PDF...`}
            isHidden={isHidden}
            isCollapsed={isCollapsed}
            onCollapse={(collapseState) => setIsCollapsed(collapseState)}
            hideProgressBar
        />
    )
}

export default ExportChatTracker;