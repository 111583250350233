import { useState } from 'react';
import { ReactComponent as IconCopy } from '../../../images/icon-copy (1).svg';

const CopyToClipboardButton = ({ text, copyFunction, isUser }) => {
    //optional var could be useful in future
    const [copied, setCopied] = useState(false);

    const handleCopyToClipboard = () => { 
        copyFunction(text, isUser); 

        setCopied(true);

        setTimeout(() => setCopied(false), 3500);

        //condition here, because we dont need plagiriasm warning if user copies their own text
        if (isUser) {
            //show('PlagirismSnackbar') //to be unlocked after Visual Context PR
        }
    };

    return (
        <button
            onClick={handleCopyToClipboard}
            style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                background: 'none',
                border: 'none',
                padding: '0',
                cursor: 'pointer'
            }}
            aria-label="Copy to clipboard"
            title="Copy to clipboard"
        >
            <IconCopy width="17" height="17" />
        </button>
    );
};

export default CopyToClipboardButton;
