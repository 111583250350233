import React, {useEffect, useState} from "react";
import { ActivityTracker } from "cai-fusion";

const UploadActivityTracker = ({isHidden = false, onClose, numFiles = 1}) => {
    const [isCollapsed, setIsCollapsed] = useState(false);

    useEffect(() => {
        if (isHidden) {
            setIsCollapsed(false);
        }
    }, [isHidden])

    return (
        <ActivityTracker
            message={`Uploading ${numFiles} file${numFiles > 1 ? "s" : ""}...`}
            isHidden={isHidden}
            isCollapsed={isCollapsed}
            onCollapse={(collapseState) => setIsCollapsed(collapseState)}
            onClose={onClose}
            hideProgressBar
        />
    )
}

export default UploadActivityTracker;