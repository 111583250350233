import { useEffect, useState, useRef } from "react";
import "./ChatEntry.css";
import ChatEntryAction from "./ChatEntry/ChatEntryAction";
import ChatEntryEdit from "./ChatEntry/ChatEntryEdit";
import ChatEntryMsg from "./ChatEntry/ChatEntryMsg";

const ChatEntry = ({
	messageId,
	messagePairId,
	role,
	content,
	statusMessage,
	IconElement,
	isLoading,
	chatEntryActions,
	userProfileId,
	userProfileImages,
	isEditing,
	setEditingMessageId,
	resendMessage,
}) => {
	const lottieRef = useRef();
	const [userIcon, setUserIcon] = useState(null);

	const [editedMessage, setEditedMessage] = useState( getTextMessageFromContent(content) );
	
	const getTextMessageFromContent = (content) => {
		return content
		  .filter((contentItem) => contentItem.type === "text")
		  .map((contentItem) => contentItem.text)
		  .join("\n");
	};

	useEffect(() => {
		if (!lottieRef.current) {
			return;
		}
		if (typeof lottieRef.current.play() === "function") {
			return;
		}
		if (isLoading) {
			lottieRef?.current?.play();
		} else {
			lottieRef?.current?.stop();
		}
	}, [isLoading, IconElement]);

	const handleResubmit = () => {
		resendMessage(messageId, editedMessage);
		setEditingMessageId(null);
	};

	return (
		<div
			className={`m-chat-entry ${
				role === "assistant"
					? "m-chat-entry--reply"
					: role === "error"
					? "m-chat-entry--reply m-chat-entry--error"
					: role === "info"
					? "m-chat-entry--info"
					: ""
			}`}
		>
			<div className="m-chat-entry__avatar-wrapper">
				<div className="a-avatar">
					<div className="a-avatar__img a-avatar__img--lottie">
						{IconElement ? (
							IconElement(lottieRef, userProfileId)
						) : (
							<></>
						)}
					</div>
				</div>
			</div>

			{isEditing ? (
				<div className="m-chat-entry__message-wrapper">
					<ChatEntryEdit 
						editedMessage={editedMessage}
						setEditedMessage={setEditedMessage}
						handleResubmit={handleResubmit}
						setEditingMessageId={setEditingMessageId}
					/>
				</div>
			) : (
				<div className="m-chat-entry__message-wrapper">
					{isLoading ? (
						<div className="m-chat-entry__message">
							<div className="a-skeleton a-skeleton--line l-mb-xs"></div>
							<div className="a-skeleton a-skeleton--line l-mb-xs"></div>
							<div className="a-skeleton a-skeleton--line l-mb-xs"></div>
						</div>
					) : (
						<div className="m-chat-entry__message">
							<ChatEntryMsg content={content} />
						</div>
					)}
					<div className="m-chat-entry__actions">
						<div className="row">
							<div className="col-md-6 h-vert-center-col">
								{statusMessage && (
									<p className="m-chat-entry__status-msg">
										{statusMessage}
									</p>
								)}
							</div>
							<div className="col-md-6">
								{isLoading ? (
									<div className="m-icon-row m-icon-row--right-align m-icon-row--condensed">
										{/* one placeholder for each action mapped to the type of entry */}
										{chatEntryActions.map(
											(action, index) => (
												<div
													key={index}
													className="a-skeleton a-skeleton--link-icon m-icon-row__icon"
												></div>
											)
										)}
									</div>
								) : (
									<div className="m-icon-row m-icon-row--right-align m-icon-row--condensed">
										{chatEntryActions.map(
											(action, index) => (
												<ChatEntryAction
													key={index}
													index={index}
													{...action}
													messageId={messageId}
													messagePairId={
														messagePairId
													}
													// TODO, include image description?
													message={ getTextMessageFromContent(content) }
												/>
											)
										)}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default ChatEntry;

// <p>{message}</p>
// TODO  https://www.npmjs.com/package/@uiw/react-md-editor
//   <MarkdownRenderer content={message} />
/* <p>{message}</p> */
/* <MDEditor
value={message}
preview="preview"
commands={[]}
previewOptions={{
components: {
code: MdCodeRenderer,
},
}}
/> */